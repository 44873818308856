import type { ReactNode } from 'react'

import ChevronPrevIcon from '../../../assets/img/ico-chevron-prev.svg?react'

interface FormHeaderProps {
    onBack: () => void
    children?: ReactNode
}

const FormHeader = ({ onBack, children }: FormHeaderProps) => {
    return (
        <div className="form-header form-header-col">
            <div className="heading-block">
                <button
                    onClick={onBack}
                    className="btn btn--navigation btn--square btn--xl rounded-full mb-42"
                >
                    <ChevronPrevIcon />
                </button>
                {children}
            </div>
        </div>
    )
}

export default FormHeader
