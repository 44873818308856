import clsx from 'clsx'

import userImage from '../../../assets/img/user-1.png'
import { useTabs } from '../../../hooks/useTabs'

import Statistics from './tabs/Statistics'
import TaskList from './tabs/TaskList'
import WorkingSchedule from './tabs/WorkingSchedule'
const TechnicianPage = () => {
    const tabs = [
        {
            id: 'working_schedule',
            label: 'Working Schedule',
        },
        {
            id: 'task_list',
            label: 'Task List',
        },
        {
            id: 'statistics',
            label: 'Stastics',
        },
    ]
    const { activeTab, setActiveTab } = useTabs(tabs)

    return (
        <main className="content flex flex-col">
            <div className="px-container flex flex-col flex-auto">
                <div className="card-user-info mb-18 user-info-items">
                    <div className="card-user-info__item flex items-center gap-18">
                        <div className="user-photo-60x60">
                            <img src={userImage} alt="user-photo" />
                        </div>

                        <div className="flex flex-col">
                            <h5 className="mb-4px">Jacob Jones</h5>
                            <span className="text--xs text-color-1">
                                debra.holt@example.com
                            </span>
                            <span className="text--xs text-color-1">
                                (480) 555-0103
                            </span>
                        </div>
                    </div>
                    <div className="card-user-info__item flex flex-col items-center">
                        <h2 className="mb-4px color-primary">639</h2>
                        <span className="text--xs text-color-1 fw-500">
                            Rank #7
                        </span>
                    </div>
                    <div className="card-user-info__item flex flex-col items-center">
                        <h2 className="mb-4px">49</h2>
                        <span className="text--xs color-success-bold">
                            Completed
                        </span>
                    </div>
                    <div className="card-user-info__item flex flex-col items-center">
                        <h2 className="mb-4px">15</h2>
                        <span className="text--xs text-color-1">KIV</span>
                    </div>
                    <div className="card-user-info__item flex flex-col items-center">
                        <h2 className="mb-4px">3</h2>
                        <span className="text--xs color-danger">Canceled</span>
                    </div>
                    <div className="card-user-info__item flex flex-col items-center">
                        <h2 className="mb-4px">8</h2>
                        <span className="text--xs color-warning-bold">
                            Pending
                        </span>
                    </div>
                </div>
                <div className="table-group tabs flex flex-col flex-auto">
                    <div className="table-filter-actions tabs-links-list modal__tabs__body">
                        {tabs.map((tab) => (
                            <button
                                key={tab.id}
                                onClick={() => setActiveTab(tab.id)}
                                className={clsx(
                                    'btn full-radius btn--xs btn-tab-custom',
                                    { 'active-tab': activeTab === tab.id },
                                )}
                            >
                                <span>{tab.label}</span>
                            </button>
                        ))}
                    </div>
                    <div className="tabs-body flex-auto">
                        {activeTab === 'working_schedule' && (
                            <WorkingSchedule />
                        )}
                        {activeTab === 'task_list' && <TaskList />}
                        {activeTab === 'statistics' && <Statistics />}
                    </div>
                </div>
            </div>
        </main>
    )
}

export default TechnicianPage
