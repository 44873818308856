/* eslint-disable jsx-a11y/no-autofocus */
import type { FormEvent } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import type { StepsProps } from '..'
import Button from '../../../compoments/common/Button'
import {
    useForgotPasswordMutation,
    useTestForgotCodeMutation,
} from '../../../services/auth'

import FormHeader from './FormHeader'

const Step2 = ({ setStep, formData, setFormData }: StepsProps) => {
    const [testForgotCode, { isLoading }] = useTestForgotCodeMutation()
    const [resetPassword] = useForgotPasswordMutation()
    const [code, setCode] = useState(Array(6).fill(''))
    const [timer, setTimer] = useState(15)

    const inputRef = useCallback((codeContainer: HTMLDivElement | null) => {
        if (codeContainer !== null) {
            const inputElements =
                codeContainer.querySelectorAll<HTMLInputElement>(
                    "input[type='text']",
                )

            const disableNonAutofocusInputs = () => {
                const nonAutofocusInputs =
                    codeContainer.querySelectorAll<HTMLInputElement>(
                        'input:not([autofocus])',
                    )
                nonAutofocusInputs.forEach((input) => {
                    input.disabled = true
                })
            }

            if (inputElements.length > 0) {
                const firstInput = inputElements[0]
                const formElement = firstInput.closest('form')

                if (firstInput.getAttribute('autofocus') !== null) {
                    disableNonAutofocusInputs()
                } else {
                    firstInput.setAttribute('autofocus', 'true')
                    disableNonAutofocusInputs()
                }

                inputElements.forEach((input, index) => {
                    input.addEventListener('input', function (ev) {
                        const target = ev.target as HTMLInputElement
                        const value = target.value
                        if (parseInt(value, 10) >= 0) {
                            target.classList.add('active-value')
                        } else {
                            target.classList.remove('active-value')
                        }

                        if (value !== '') {
                            const nextInput = inputElements[index + 1]
                            if (nextInput) {
                                nextInput.disabled = false
                                nextInput.focus()
                            } else if (formElement) {
                                // const submitButton =
                                //     formElement.querySelector<HTMLButtonElement>(
                                //         "[type='submit']",
                                //     )
                                // submitButton?.click()
                            }
                        }

                        const clearInputOnEKey = (event: KeyboardEvent) => {
                            if (event.key === 'e' || event.key === 'E') {
                                input.value = ''
                            }
                        }

                        document.addEventListener('keydown', clearInputOnEKey)
                        document.addEventListener('keyup', clearInputOnEKey)
                    })
                })
            }
        }
    }, [])

    const handleChange = (value: string, index: number) => {
        setCode((prev) => {
            const newCode = [...prev]
            newCode[index] = value
            return newCode
        })
    }
    const handleResetPassword = async () => {
        if (!formData.email) return
        resetPassword({ email: formData.email })
        setTimer(15)
    }

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()
        const codeString = code.join('')
        if (!/^\d+$/.test(codeString)) {
            console.error('Code must be a number.')
            return
        }

        const numericCode = parseInt(codeString, 10)

        if (!formData.email) return
        const responce = await testForgotCode({
            email: formData.email,
            code: numericCode,
        }).unwrap()

        if (responce.success) {
            setFormData('code', numericCode)
            setStep(3)
        } else {
            toast.error(responce.message)
        }
    }

    useEffect(() => {
        let interval: NodeJS.Timeout | undefined

        if (timer > 0) {
            interval = setInterval(() => {
                setTimer((prev) => prev - 1)
            }, 1000)
        } else if (interval) {
            clearInterval(interval)
        }

        console.log(timer)

        return () => {
            if (interval) {
                clearInterval(interval)
            }
        }
    }, [timer])

    return (
        <form onSubmit={onSubmit} className="form">
            <FormHeader onBack={() => setStep(1)}>
                <h2 className="fs-28 text-left text-nowrap mb-22">
                    Please check your email
                </h2>
                <p className="text-description text-left text--md">
                    We have sent the code to
                </p>
                <a
                    href="mailto:alma.lawson@example.com"
                    className="btn-link flex fs-15 mb-42"
                >
                    {formData.email}
                </a>
            </FormHeader>
            <div className="form-body">
                <div className="row mb-20" ref={inputRef}>
                    {code.map((value, index) => (
                        <div className="col-2" key={index}>
                            <div className="form-group--row input--xxl input-md-sx">
                                <input
                                    className="input input--outline fs-20 w-full text-center"
                                    type="text"
                                    placeholder=""
                                    value={value}
                                    onChange={(e) =>
                                        handleChange(e.target.value, index)
                                    }
                                    maxLength={1}
                                    autoFocus={index === 0}
                                />
                            </div>
                        </div>
                    ))}
                </div>
                <div className="flex items-center justify-center fs-15 gap-8 mb-42">
                    {timer <= 0 ? (
                        <button
                            type="button"
                            onClick={handleResetPassword}
                            className="color-primary"
                        >
                            Send code again
                        </button>
                    ) : (
                        <>
                            <span className="text-color-1">
                                Send code again
                            </span>
                            <span className="color-primary">00:{timer}</span>
                        </>
                    )}
                </div>
            </div>
            <div className="form-footer form-footer-col">
                <div className="items-12 flex flex-col">
                    <Button
                        loading={isLoading}
                        type="submit"
                        variant="primary-gradient"
                        className="w-full"
                    >
                        <span>Verification</span>
                    </Button>
                </div>
            </div>
        </form>
    )
}

export default Step2
