/* eslint-disable jsx-a11y/label-has-associated-control */
import clsx from 'clsx'
import { Link, useLocation, useParams } from 'react-router-dom'

import ChatOne from '../../assets/img/chat-ava-0.png'
import ChatTwo from '../../assets/img/chat-ava-1.png'
import ChatThree from '../../assets/img/chat-ava-2.png'
import type { User } from '../../types'

interface Chat {
    id: number
    members: Pick<User, 'id' | 'photo' | 'fullName'>[]
    type: 'single' | 'group'
    message?: {
        content: string
        sender: Pick<User, 'id' | 'photo' | 'fullName'>
        createdAt: Date
    }
    name?: string
    createdAt: Date
}

const ChatPage = () => {
    const { id } = useParams()
    const { pathname } = useLocation()
    const currentUserId = 1
    const chats: Chat[] = [
        {
            id: 1,
            members: [
                {
                    id: 1,
                    fullName: 'John Doe',
                    photo: ChatOne,
                },
                {
                    id: 2,
                    fullName: 'Will Smith',
                    photo: ChatTwo,
                },
            ],
            message: {
                content:
                    'Some text here is for message from the user in this block',
                sender: {
                    id: 1,
                    fullName: 'John Doe',
                    photo: ChatOne,
                },
                createdAt: new Date(),
            },
            type: 'single',
            createdAt: new Date(),
        },
        {
            id: 2,
            members: [
                {
                    id: 1,
                    fullName: 'John Doe',
                    photo: ChatOne,
                },
                {
                    id: 2,
                    fullName: 'Will Smith',
                    photo: ChatTwo,
                },
                {
                    id: 3,
                    fullName: 'John Smith',
                    photo: ChatThree,
                },
            ],
            message: {
                content:
                    'Some text here is for message from the user in this block',
                sender: {
                    id: 1,
                    fullName: 'John Doe',
                    photo: ChatOne,
                },
                createdAt: new Date(),
            },
            type: 'group',
            name: 'Group Chat Name',
            createdAt: new Date(),
        },
    ]
    const chat = chats.find((chat) => chat.id === Number(id))
    const path = pathname.split('/')[1]

    const getChatRecipient = (
        chat: Chat,
    ): Pick<User, 'id' | 'photo' | 'fullName'> | null => {
        if (chat.type === 'single') {
            return (
                chat.members.find((member) => member.id !== currentUserId) ||
                null
            )
        }
        return null
    }

    return (
        <main className="content">
            <div className="px-container">
                <section className="chat-section section-fullscreen">
                    <aside className="chat-sidebar">
                        <div className="chat-sidebar--header">
                            <form method="post" name="chat-find">
                                <div className="form-group input--sm input--icon-left">
                                    <div className="input-wrapper">
                                        <span className="ico text-color-1">
                                            <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <g>
                                                    <path
                                                        d="M8.75 15C12.2018 15 15 12.2018 15 8.75C15 5.29822 12.2018 2.5 8.75 2.5C5.29822 2.5 2.5 5.29822 2.5 8.75C2.5 12.2018 5.29822 15 8.75 15Z"
                                                        stroke="currentColor"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    ></path>
                                                    <path
                                                        d="M13.1699 13.1694L17.5004 17.4999"
                                                        stroke="currentColor"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    ></path>
                                                </g>
                                                <defs>
                                                    <clipPath>
                                                        <rect
                                                            width="20"
                                                            height="20"
                                                            fill="white"
                                                        ></rect>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </span>
                                        <input
                                            className="input input--solid full-radius w-full"
                                            type="text"
                                            placeholder="Search jobs..."
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="chat-sidebar--body">
                            <ul className="list-chats">
                                {chats.map((item) => (
                                    <li
                                        key={item.id}
                                        className={clsx('chat-item', {
                                            '--active-chat':
                                                id === String(item.id),
                                        })}
                                    >
                                        <Link
                                            to={`/${path}/${item.id}`}
                                            className="chat-select"
                                        >
                                            <div className="ico-avatar ico-avatar-group btn btn--sm btn--square">
                                                {item.type === 'group' ? (
                                                    item.members.map(
                                                        (user, index) => (
                                                            <div
                                                                key={user.id}
                                                                className={clsx(
                                                                    'ico-avatar btn btn--sm btn--square',
                                                                    {
                                                                        '--pos-top':
                                                                            index ===
                                                                            0,
                                                                        '--pos-left':
                                                                            index ===
                                                                            1,
                                                                        '--pos-right':
                                                                            index ===
                                                                            2,
                                                                    },
                                                                )}
                                                            >
                                                                <img
                                                                    src={
                                                                        user.photo
                                                                    }
                                                                    alt={
                                                                        user.fullName
                                                                    }
                                                                />
                                                            </div>
                                                        ),
                                                    )
                                                ) : (
                                                    <img
                                                        src={
                                                            item.members.find(
                                                                ({ id }) =>
                                                                    id === 1,
                                                            )?.photo
                                                        }
                                                        alt=""
                                                    />
                                                )}
                                            </div>

                                            <div className="chat-select-user-info">
                                                <div className="user-info-row">
                                                    <p className="user-info-name">
                                                        {item.type === 'group'
                                                            ? item.name
                                                            : getChatRecipient(
                                                                  item,
                                                              )?.fullName}
                                                    </p>
                                                    <p className="user-info-time">
                                                        {item.message?.createdAt.toLocaleTimeString()}
                                                    </p>
                                                </div>
                                                <div className="user-info-row">
                                                    <span className="user-info-last-name">
                                                        {item.type === 'group'
                                                            ? item.message
                                                                  ?.sender
                                                                  .fullName
                                                            : null}
                                                    </span>
                                                    <p className="user-info-last-msg">
                                                        {item.message?.content}
                                                    </p>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </aside>
                    {id && chat ? (
                        <div className="chat-workflow">
                            <div className="chat-workflow--header">
                                <div className="chat-select">
                                    <button
                                        type="button"
                                        className="btn btn-shadow-iset btn--sm btn--square full-radius btn-chat-hide"
                                    >
                                        <span className="ico">
                                            <svg
                                                width="22"
                                                height="22"
                                                viewBox="0 0 22 22"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M13.75 17.875L6.875 11L13.75 4.125"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                ></path>
                                            </svg>
                                        </span>
                                    </button>
                                    <div className="ico-avatar ico-avatar-group btn btn--sm btn--square">
                                        {chat?.type === 'group' ? (
                                            chat.members.map((member) => (
                                                <div
                                                    key={member.id}
                                                    className="ico-avatar btn btn--sm btn--square"
                                                >
                                                    <img
                                                        src={member.photo}
                                                        alt={member.fullName}
                                                    />
                                                </div>
                                            ))
                                        ) : (
                                            <div className="ico-avatar btn btn--sm btn--square">
                                                <img
                                                    src={
                                                        getChatRecipient(chat)
                                                            ?.photo
                                                    }
                                                    alt={
                                                        getChatRecipient(chat)
                                                            ?.fullName
                                                    }
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className="chat-select-user-info">
                                        <div className="user-info-row">
                                            <p className="user-info-name">
                                                Jacob Jones
                                            </p>
                                        </div>
                                        <div className="user-info-row">
                                            <p className="user-info-status">
                                                Online
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="chat-workflow--header-tools">
                                    <button
                                        type="button"
                                        className="btn btn-tool"
                                    >
                                        <span className="ico">
                                            <svg
                                                width="22"
                                                height="22"
                                                viewBox="0 0 22 22"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M10.0833 1.83325C14.6373 1.83325 18.3333 5.52925 18.3333 10.0833C18.3333 14.6373 14.6373 18.3333 10.0833 18.3333C5.52931 18.3333 1.83331 14.6373 1.83331 10.0833C1.83331 5.52925 5.52931 1.83325 10.0833 1.83325ZM10.0833 16.4999C13.6281 16.4999 16.5 13.628 16.5 10.0833C16.5 6.5385 13.6281 3.66659 10.0833 3.66659C6.53856 3.66659 3.66665 6.5385 3.66665 10.0833C3.66665 13.628 6.53856 16.4999 10.0833 16.4999ZM17.8612 16.565L20.4545 19.1573L19.1574 20.4544L16.5651 17.8612L17.8612 16.565Z"
                                                    fill="currentColor"
                                                ></path>
                                            </svg>
                                        </span>
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-tool"
                                    >
                                        <span className="ico">
                                            <svg
                                                width="22"
                                                height="22"
                                                viewBox="0 0 22 22"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M4.125 9.625C3.36875 9.625 2.75 10.2438 2.75 11C2.75 11.7563 3.36875 12.375 4.125 12.375C4.88125 12.375 5.5 11.7563 5.5 11C5.5 10.2438 4.88125 9.625 4.125 9.625ZM17.875 9.625C17.1188 9.625 16.5 10.2438 16.5 11C16.5 11.7563 17.1188 12.375 17.875 12.375C18.6313 12.375 19.25 11.7563 19.25 11C19.25 10.2438 18.6313 9.625 17.875 9.625ZM11 9.625C10.2438 9.625 9.625 10.2438 9.625 11C9.625 11.7563 10.2438 12.375 11 12.375C11.7563 12.375 12.375 11.7563 12.375 11C12.375 10.2438 11.7563 9.625 11 9.625Z"
                                                    fill="currentColor"
                                                ></path>
                                            </svg>
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div className="chat-workflow--body">
                                <ul className="chat-message-list">
                                    <li className="chat-message-list-item">
                                        <div className="message-block me-auto">
                                            <div className="message-body">
                                                <div className="ico-avatar btn btn--sm btn--square">
                                                    <img
                                                        src={ChatTwo}
                                                        alt="ava"
                                                    />
                                                </div>
                                                <div className="message-block--content">
                                                    <div className="message-text">
                                                        <p>
                                                            Some text here is
                                                            for message from the
                                                            user in this block
                                                        </p>
                                                    </div>
                                                    <div className="message-foot justify-start">
                                                        <div className="message-foot--item">
                                                            <p className="message-name">
                                                                Jacob Jones
                                                            </p>
                                                            <p className="message-send-time">
                                                                12:00
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="chat-message-list-item --you-msg">
                                        <div className="message-block ms-auto">
                                            <div className="message-body">
                                                <div className="message-block--content">
                                                    <div className="message-text">
                                                        <p>
                                                            Some text here is
                                                            for message from the
                                                            user in this block
                                                        </p>
                                                    </div>
                                                    <div className="message-foot justify-end">
                                                        <div className="message-foot--item">
                                                            <p className="message-name">
                                                                Wade Warren
                                                            </p>
                                                            <p className="message-send-time">
                                                                12:00
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="chat-workflow--footer">
                                <form
                                    className="send-massage-form"
                                    name="form-message"
                                    method="POST"
                                >
                                    <div className="send-wrapp-control input--xxl flex-auto">
                                        <label className="file-btn">
                                            <input type="file" hidden />
                                            <div className="btn btn-sm w-full">
                                                <span className="ico">
                                                    <svg
                                                        width="20"
                                                        height="20"
                                                        viewBox="0 0 20 20"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M3.33301 0.833252V3.33325H0.833008V4.99992H3.33301V7.49992H4.99967V4.99992H7.49967V3.33325H4.99967V0.833252H3.33301ZM2.49967 16.6724V9.16658H4.16634V15.8333H10.833V11.6666C10.833 11.2083 11.208 10.8333 11.6663 10.8333L15.833 10.8324V4.16658H9.16634V2.49992H16.6722C17.1288 2.49992 17.4997 2.87992 17.4997 3.33492V12.4999L12.4997 17.4966L3.33467 17.4999C3.11425 17.5008 2.90248 17.4141 2.74591 17.259C2.58934 17.1038 2.50078 16.8928 2.49967 16.6724ZM15.1422 12.4991L12.4997 12.4999V15.1408L15.1422 12.4991Z"
                                                            fill="currentColor"
                                                        ></path>
                                                    </svg>
                                                </span>
                                            </div>
                                        </label>
                                        <input
                                            type="text"
                                            placeholder="Write your message here..."
                                            className="send-input-control input"
                                        />
                                    </div>
                                    <button
                                        type="submit"
                                        className="btn btn--square btn--xxl btn--primary-gradient full-radius"
                                    >
                                        <span className="ico">
                                            <svg
                                                width="22"
                                                height="22"
                                                viewBox="0 0 22 22"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <g>
                                                    <path
                                                        d="M1.83333 5.04167V3.66025C1.83501 3.41951 1.93133 3.1891 2.10147 3.01878C2.27162 2.84847 2.50193 2.75192 2.74267 2.75H19.2573C19.7597 2.75 20.1667 3.15792 20.1667 3.66025V18.3398C20.165 18.5805 20.0687 18.8109 19.8985 18.9812C19.7284 19.1515 19.4981 19.2481 19.2573 19.25H2.74267C2.50141 19.2498 2.27012 19.1537 2.09962 18.9831C1.92911 18.8124 1.83333 18.581 1.83333 18.3398V17.4167H18.3333V6.69167L11 13.2917L1.83333 5.04167ZM0 9.16667H4.58333V11H0V9.16667ZM0 13.75H7.33333V15.5833H0V13.75Z"
                                                        fill="currentColor"
                                                    ></path>
                                                </g>
                                                <defs>
                                                    <clipPath>
                                                        <rect
                                                            width="22"
                                                            height="22"
                                                            fill="currentColor"
                                                        ></rect>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </span>
                                    </button>
                                </form>
                            </div>
                        </div>
                    ) : (
                        <div className="flex flex-col justify-center items-center flex-auto">
                            <p className="color-text-color-1">Select chat</p>
                        </div>
                    )}
                </section>
            </div>
        </main>
    )
}
export default ChatPage
