import React from 'react'

import LineChart from '../../../compoments/charts/LineChart'

const ExecutivesRevenueAnalysisPage = () => {
    return (
        <main className="content flex flex-col">
            <div className="px-container flex flex-col flex-auto">
                <div className="row gutters-28">
                    <div className="col-12">
                        <div className="card card-shadow card-chart h-full">
                            <div className="card-header">
                                <div className="c-h-item">
                                    <div className="calendar-title">
                                        <div id="title">
                                            Mar 10, 2023 – Mar 10, 2024
                                        </div>
                                        <span className="ico">
                                            <svg
                                                width="18"
                                                height="18"
                                                viewBox="0 0 18 18"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M6.50008 0.833252V2.49992H11.5001V0.833252H13.1667V2.49992H16.5001C16.7211 2.49992 16.9331 2.58772 17.0893 2.744C17.2456 2.90028 17.3334 3.11224 17.3334 3.33325V16.6666C17.3334 16.8876 17.2456 17.0996 17.0893 17.2558C16.9331 17.4121 16.7211 17.4999 16.5001 17.4999H1.50008C1.27907 17.4999 1.06711 17.4121 0.910826 17.2558C0.754545 17.0996 0.666748 16.8876 0.666748 16.6666V3.33325C0.666748 3.11224 0.754545 2.90028 0.910826 2.744C1.06711 2.58772 1.27907 2.49992 1.50008 2.49992H4.83341V0.833252H6.50008ZM15.6667 9.16658H2.33341V15.8333H15.6667V9.16658ZM4.83341 4.16659H2.33341V7.49992H15.6667V4.16659H13.1667V5.83325H11.5001V4.16659H6.50008V5.83325H4.83341V4.16659Z"
                                                    fill="currentColor"
                                                ></path>
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                                <button className="btn">
                                    <span className="ico me-3">
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M9.1665 13.3334H10.8332V5.83335H13.3332L9.99984 1.66669L6.6665 5.83335H9.1665V13.3334Z"
                                                fill="#232ED1"
                                            ></path>
                                            <path
                                                d="M4.16667 18.3333H15.8333C16.7525 18.3333 17.5 17.5858 17.5 16.6667V9.16667C17.5 8.2475 16.7525 7.5 15.8333 7.5H12.5V9.16667H15.8333V16.6667H4.16667V9.16667H7.5V7.5H4.16667C3.2475 7.5 2.5 8.2475 2.5 9.16667V16.6667C2.5 17.5858 3.2475 18.3333 4.16667 18.3333Z"
                                                fill="#232ED1"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span className="color-primary">
                                        Export
                                    </span>
                                </button>
                            </div>
                            <div className="card-body">
                                <div
                                    className="canvas-wrapper flex-auto"
                                    style={{ height: 215 }}
                                >
                                    <LineChart
                                        values={Array.from({
                                            length: 60,
                                        }).map(
                                            (_) =>
                                                Math.random() *
                                                (6000 - 100 * Math.random()),
                                        )}
                                        labels={Array.from({
                                            length: 60,
                                        }).map((_, index) =>
                                            new Date(
                                                new Date().setDate(
                                                    new Date().getDay() + index,
                                                ),
                                            ).toLocaleDateString('en-EU', {
                                                day: 'numeric',
                                                month: 'short',
                                            }),
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="row gutters-18">
                            <div className="col-xl-3 col-md-6">
                                <div className="card card-shadow card-seo">
                                    <div className="card-body">
                                        <div className="card-seo-info">
                                            <div className="seo-title">
                                                <span className="name">
                                                    Q1 2024 Revenue
                                                </span>
                                            </div>
                                            <h1 className="seo-stat-title">
                                                <sup className="currency">
                                                    $
                                                </sup>
                                                500,000
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6">
                                <div className="card card-shadow card-seo">
                                    <div className="card-body">
                                        <div className="card-seo-info">
                                            <div className="seo-title">
                                                <span className="name">
                                                    Q2 2024 Revenue
                                                </span>
                                            </div>
                                            <h1 className="seo-stat-title">
                                                <sup className="currency">
                                                    $
                                                </sup>
                                                500,000
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6">
                                <div className="card card-shadow card-seo">
                                    <div className="card-body">
                                        <div className="card-seo-info">
                                            <div className="seo-title">
                                                <span className="name">
                                                    Q3 2024 Revenue
                                                </span>
                                            </div>
                                            <h1 className="seo-stat-title">
                                                <sup className="currency">
                                                    $
                                                </sup>
                                                500,000
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6">
                                <div className="card card-shadow card-seo">
                                    <div className="card-body">
                                        <div className="card-seo-info">
                                            <div className="seo-title">
                                                <span className="name">
                                                    Q4 2024 Revenue
                                                </span>
                                            </div>
                                            <h1 className="seo-stat-title">
                                                <sup className="currency">
                                                    $
                                                </sup>
                                                500,000
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="table-group">
                            <div className="table-body table-responsive p-0">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Period</th>
                                            <th>Selected Revenue</th>
                                            <th>Previous Revenue</th>
                                            <th>Selected Margin</th>
                                            <th>Previous Margin</th>
                                            <th>Revenue Discrepancy</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <span className="text-color-1">
                                                    1 May (March 31)
                                                </span>
                                            </td>
                                            <td>$500,000</td>
                                            <td>$500,000</td>
                                            <td>$500,000</td>
                                            <td>$500,000</td>
                                            <td>$500,000</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="text-color-1">
                                                    1 May (March 31)
                                                </span>
                                            </td>
                                            <td>$500,000</td>
                                            <td>$500,000</td>
                                            <td>$500,000</td>
                                            <td>$500,000</td>
                                            <td>$500,000</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="text-color-1">
                                                    1 May (March 31)
                                                </span>
                                            </td>
                                            <td>$500,000</td>
                                            <td>$500,000</td>
                                            <td>$500,000</td>
                                            <td>$500,000</td>
                                            <td>$500,000</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="text-color-1">
                                                    1 May (March 31)
                                                </span>
                                            </td>
                                            <td>$500,000</td>
                                            <td>$500,000</td>
                                            <td>$500,000</td>
                                            <td>$500,000</td>
                                            <td className="color-success fw-600">
                                                $500,000
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default ExecutivesRevenueAnalysisPage
