import clsx from 'clsx'
import type {
    DropdownIndicatorProps,
    GroupBase,
    OptionProps,
    PlaceholderProps,
    Props,
    SingleValueProps,
    StylesConfig,
    ValueContainerProps,
} from 'react-select'
import Select, { components } from 'react-select'

const DropdownIndicator = (
    props: DropdownIndicatorProps<any, false, GroupBase<any>>,
) =>
    components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
            <div className="ico">
                <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clip-path="url(#clip0_434_5354)">
                        <path
                            d="M11.375 5.25L7 9.625L2.625 5.25"
                            stroke="#7D8491"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_434_5354">
                            <rect width="14" height="14" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </div>
        </components.DropdownIndicator>
    )

const Placeholder = (props: PlaceholderProps<any>) => (
    <components.Placeholder {...props} />
)

const SingleValue = (props: SingleValueProps<any>) => (
    <components.SingleValue {...props} />
)
const Option = (props: OptionProps<any>) => (
    <span
        className="flex items-center justify-between"
        style={{ position: 'relative' }}
    >
        <components.Option {...props} />
        {props.isSelected && (
            <span
                className="ico"
                style={{
                    position: 'absolute',
                    right: 12,
                }}
            >
                <div className="ico">
                    <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clip-path="url(#clip0_449_14162)">
                            <path
                                d="M2.5 9L6 12.5L14 4.5"
                                stroke="#232ED1"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_449_14162">
                                <rect width="16" height="16" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
            </span>
        )}
    </span>
)

const ValueContainer = (props: ValueContainerProps<any>) => (
    <components.ValueContainer {...props} />
)

interface OptionCustomProps {
    label: string
    value: string | number
}

interface SelectProps extends Omit<Props<OptionCustomProps, false>, 'value'> {
    value?: OptionCustomProps | null
    options: OptionCustomProps[]
    onChange?: (option: OptionCustomProps | null) => void
    wrapperClassName?: string
    label?: string
}

const customStyles: StylesConfig<OptionCustomProps, false> = {
    control: (base, { menuIsOpen }) => ({
        ...base,
        background: 'var(--select-bg)',
        borderWidth: '1px ',
        borderStyle: 'solid',
        borderRadius: '10px',
        paddingLeft: 'var(--select-padding-left)',
        paddingRight: 8,
        fontSize: 'var(--select-font-size)',
        lineHeight: 'var(--select-line-height)',
        color: menuIsOpen ? 'var(--primary)' : 'var(--select-color)',
        height: 'var(--input-height)',
        boxShadow: 'none',
        borderColor: 'var(--select-border-color, transparent)',
    }),
    singleValue: (base) => ({
        ...base,
        color: 'var(--primary)',
        height: 'var(--input-height)',
    }),
    valueContainer: (base) => ({
        ...base,
        paddingTop: 0,
        paddingBottom: 0,
        height: 'var(--input-height)',
    }),
    input: (base) => ({
        ...base,
        margin: 0,
        paddingTop: 0,
        paddingBottom: 0,
        height: 'var(--input-height)',
    }),
    indicatorsContainer: () => ({ width: 32 }),
    indicatorSeparator: () => ({ display: 'none' }),
    dropdownIndicator: (base) => ({
        ...base,
        width: 'var(--select-arrow-size)',
        height: 'var(--input-height)',
    }),
    menu: (base) => ({
        ...base,
        borderRadius: 10,
        minWidth: 150,
        right: 0,
        overflow: 'hidden',
    }),
    menuPortal: (base) => ({
        ...base,
        zIndex: 9999,
    }),
    option: (base, props) => ({
        ...base,
        background: props.isFocused ? '#F4F5FF' : 'transparent',
        color: props.isSelected ? 'var(--primary)' : 'var(--select-color)',
        paddingRight: 40,
        cursor: 'pointer',
    }),
}

const SelectBox = ({
    value,
    onChange,
    options,
    wrapperClassName,
    label,
    ...rest
}: SelectProps) => {
    return (
        <div className={clsx('form-group', wrapperClassName)}>
            {label && <label className="label">{label}</label>}
            <Select
                value={value}
                onChange={onChange}
                options={options}
                styles={customStyles}
                menuPortalTarget={document.body}
                className="w-full"
                components={{
                    DropdownIndicator,
                    Placeholder,
                    SingleValue,
                    ValueContainer,
                    Option,
                }}
                {...rest}
            />
        </div>
    )
}

export default SelectBox
