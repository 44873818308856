import { Outlet } from 'react-router-dom'

import logo from '../assets/img/fsm.svg'
const AuthLayout = () => {
    return (
        <div className="wrapper">
            <main className="content flex flex-col">
                <section className="form-section">
                    <div className="container container-form">
                        <div className="flex justify-center mb-32">
                            <img src={logo} alt="fsm" />
                        </div>
                        <Outlet />
                    </div>
                </section>
            </main>
        </div>
    )
}

export default AuthLayout
