import React from 'react'

interface CircleProgressBarProps {
    progressColor: string
    percentageComplete: number
}

const CircleProgressBar: React.FC<CircleProgressBarProps> = ({
    progressColor,
    percentageComplete,
}) => {
    const progressBarStyle = {
        '--progressColor': progressColor,
        '--percentageComplete': percentageComplete,
    } as React.CSSProperties

    return (
        <div className="pie-charts-group__chart">
            <div className="progress-bar__wrapper" style={progressBarStyle}>
                <svg
                    className="progress-bar"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="-1 -1 34 34"
                >
                    <circle
                        cx="16"
                        cy="16"
                        r="14"
                        className="progress-bar__background"
                    ></circle>
                    <circle
                        cx="16"
                        cy="16"
                        r="14"
                        className="progress-bar__progress js-progress-bar"
                    ></circle>
                </svg>
                <div className="progress-bar__inner">
                    <div className="progress-bar__value">
                        {percentageComplete}%
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CircleProgressBar
