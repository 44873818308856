import type { ChartOptions, LegendItem, Plugin } from 'chart.js'
import {
    ArcElement,
    CategoryScale,
    Chart as ChartJS,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js'
import { useRef } from 'react'
import { Doughnut } from 'react-chartjs-2'
import type { ChartProps } from 'react-chartjs-2/dist/types'

import { formatNumberWithCommas } from '../../utils/charts'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ArcElement,
    Title,
    Tooltip,
    Filler,
    Legend,
)

interface DoughnutChartProps
    extends Omit<ChartProps<'doughnut'>, 'data' | 'type'> {
    labels: string[]
    values: number[]
    units?: string
}

const DoughnutChart = ({ labels, values, ...rest }: DoughnutChartProps) => {
    const ref = useRef<HTMLDivElement>(null)
    const htmlLegendPlugin: Plugin<'doughnut'> = {
        id: 'htmlLegend',
        afterUpdate(chart: ChartJS<'doughnut'>) {
            const container = ref.current
            if (!container) return

            let ul = container.querySelector('.legend ul')
            const legend = container.querySelector('.legend')

            if (!ul) {
                ul = document.createElement('ul')
                ul.classList.add('legend__list')
                if (legend) legend.appendChild(ul)
            }

            ul.innerHTML = ''

            const generateLabels =
                chart.options.plugins?.legend?.labels?.generateLabels
            if (generateLabels) {
                const items = generateLabels(chart as ChartJS)
                const meta = chart.getDatasetMeta(0) as any
                const total = meta.total
                const totalElement = container.querySelector('.legend__value')

                if (totalElement) {
                    totalElement.innerHTML = `${formatNumberWithCommas(total)}`
                }

                items.forEach((item: LegendItem) => {
                    if (typeof item?.index !== 'number') return
                    const value = chart.getDatasetMeta(0)._parsed[item.index]

                    const li = document.createElement('li')
                    li.classList.add('legend__item')
                    li.onclick = () => {
                        chart.toggleDataVisibility(item.index as number)
                        chart.update()
                    }
                    li.classList.add(item.hidden ? 'disabled' : 'active')
                    const textContainer = document.createElement('div')
                    textContainer.classList.add('legend__container')
                    textContainer.innerHTML = `
                        <p class="legend__label">
                            <span class="legend__icon" style="background-color: ${item.fillStyle}"></span>
                            <span class="info">${item.text}</span>
                        </p>
                        <p class="legend__value">${formatNumberWithCommas(value as number)}</p>
                    `
                    li.appendChild(textContainer)
                    ul.appendChild(li)
                })
            }
        },
    }
    const options: ChartOptions<'doughnut'> = {
        plugins: {
            legend: {
                display: false,
            },
        },
        layout: {},
        cutout: '70%',
        responsive: false,
    }

    const data = {
        labels,
        datasets: [
            {
                labels,
                data: values,
                backgroundColor: [
                    '#AD76F5',
                    '#F64CA7',
                    '#FF4635',
                    '#22B872',
                    '#FFAB07',
                ],
                borderRadius: 10,
                hoverOffset: 4,
                borderWidth: 6,
            },
        ],
    }
    return (
        <div className="canvas-row flex-auto" ref={ref}>
            <div className="canvas-wrapper px-20">
                <div className="chart__inner">
                    <p className="legend__label">Total</p>
                    <p className="legend__value">15100</p>
                </div>
                <Doughnut
                    options={options}
                    data={data}
                    width={160}
                    height={160}
                    {...rest}
                    plugins={[htmlLegendPlugin]}
                />{' '}
            </div>
            <div className="legend"></div>
        </div>
    )
}

export default DoughnutChart
