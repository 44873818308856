/* eslint-disable jsx-a11y/anchor-is-valid */

import DotsIcon from '../../../assets/img/dots.svg?react'
import avatar from '../../../assets/img/user-1.png'
import Pagination from '../../../compoments/common/Pagination'
import SelectBox from '../../../compoments/common/SelectBox'

const ExecutivesCustomerFeedbackPage = () => {
    return (
        <main className="content">
            <div className="px-container">
                <div className="table-group">
                    <div className="table-header flex items-center justify-between">
                        <div className="table-tools">
                            <SelectBox
                                wrapperClassName="select--outline input--sm"
                                placeholder="Rating"
                                options={[]}
                            />
                            <SelectBox
                                wrapperClassName="select--outline input--sm"
                                placeholder="Technician"
                                options={[]}
                            />
                        </div>
                    </div>

                    <div className="table-body table-responsive p-0">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Customer</th>
                                    <th>Order</th>
                                    <th style={{ width: '100%' }}>Feedback</th>
                                    <th>Rate</th>
                                    <th>Technician</th>
                                    <th style={{ width: 10 }}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="flex items-center gap-8">
                                            <div className="user-28x28">
                                                <img src={avatar} alt="user" />
                                            </div>
                                            <div className="flex flex-col">
                                                <span className="text--xs fw-500">
                                                    Floyd Miles
                                                </span>
                                                <span className="text--12 text-color-1">
                                                    tanya.hill@example.com
                                                </span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <span className="text--xs">
                                            #Order1234
                                        </span>
                                    </td>
                                    <td>
                                        <span className="text--xs color-text-color-1">
                                            Some text here will be for a
                                            feedback...
                                        </span>
                                    </td>
                                    <td>
                                        <span className="fw-600 text--xs color-primary">
                                            4.5
                                        </span>
                                    </td>
                                    <td>
                                        <div className="flex items-center gap-8">
                                            <div className="user-28x28">
                                                <img src={avatar} alt="user" />
                                            </div>
                                            <div className="flex flex-col">
                                                <span className="text--xs fw-500">
                                                    Floyd Miles
                                                </span>
                                                <span className="text--12 text-color-1">
                                                    tanya.hill@example.com
                                                </span>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="text-right sticky-end">
                                        <div
                                            className="dropdown flex flex-col"
                                            data-position="top-end"
                                            data-dropdown="dropdown"
                                        >
                                            <a
                                                href="#"
                                                className="btn dropdown-btn"
                                                data-role="button"
                                            >
                                                <span className="ico">
                                                    <DotsIcon />
                                                </span>
                                            </a>
                                            <div
                                                className="dropdown__body dropdown-md"
                                                data-role="dropdown"
                                            >
                                                <ul className="dropdown-list">
                                                    <li>
                                                        <a
                                                            href="#"
                                                            className="btn size--sm btn-link-drp"
                                                        >
                                                            <span>
                                                                Show Item
                                                            </span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <Pagination
                    currentPage={1}
                    totalPages={10}
                    onPageChange={() => {}}
                />
            </div>
        </main>
    )
}

export default ExecutivesCustomerFeedbackPage
