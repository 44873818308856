import clsx from 'clsx'
import React, { useState } from 'react'

import ChevroDownIcon from '../../assets/img/ico-chevro-down.svg?react'

interface AccordionProps {
    header: React.ReactNode
    children: React.ReactNode
}

const Accordion: React.FC<AccordionProps> = ({ header, children }) => {
    const [isOpen, setIsOpen] = useState(false)

    const toggleAccordion = () => {
        setIsOpen(!isOpen)
    }

    return (
        <div
            className={clsx('accordeon', { 'active-accordeon': isOpen })}
            data-accordeon
        >
            <div className="accordeon-header">
                <div className="a-h-item">
                    <div className="a-h-item--sm">
                        <button
                            type="button"
                            className="btn btn-tool"
                            data-accordeon-btn
                            onClick={toggleAccordion}
                        >
                            <span
                                className={clsx('ico ico-rotate-accordeon', {
                                    open: isOpen,
                                })}
                            >
                                <ChevroDownIcon />
                            </span>
                        </button>
                    </div>
                </div>
                <div className="flex-auto">{header}</div>
            </div>
            <div
                className="calendar-body"
                style={{ display: isOpen ? 'block' : 'none' }}
                data-accordeon-body
            >
                {isOpen ? children : null}
            </div>
        </div>
    )
}

export default Accordion
