import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

interface Tab {
    readonly id: string
    readonly label: string
}

export const useTabs = (tabs: readonly Tab[]) => {
    type TabNames = (typeof tabs)[number]['id']

    const [searchParams, setSearchParams] = useSearchParams()

    const defaultTab = tabs[0].id as TabNames
    const [activeTab, setActiveTab] = useState<TabNames>(
        (searchParams.get('view') as TabNames) || defaultTab,
    )

    useEffect(() => {
        setSearchParams((prev) => {
            prev.set('view', activeTab)
            return prev
        })
    }, [activeTab, setSearchParams])

    return { activeTab, setActiveTab }
}
