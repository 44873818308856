export const inRange = (min: number, max: number, value: number): number =>
    Math.max(Math.min(value, max), min)

export const RESPONSIVE_COEFFICIENT = window.innerWidth / 1440
export const COLORS: string[] = [
    '#AD76F5',
    '#F64CA7',
    '#FF4635',
    '#22B872',
    '#FFAB07',
]
export const CHART_PREFIX = ''
export const FONT = {
    size: inRange(8, 14, RESPONSIVE_COEFFICIENT * 14),
    color: '#494949',
    family: 'Noto Sans',
    weight: 500,
}

export const getGradient = (
    ctx: CanvasRenderingContext2D,
    chartArea: { top: number; bottom: number },
    colors: string[] = ['rgba(35, 46, 209, 0.2)', 'rgba(35, 46, 209, 0)'],
): CanvasGradient => {
    const { top, bottom } = chartArea
    const gradient = ctx.createLinearGradient(0, top, 0, bottom)
    colors.forEach((color, index) => {
        gradient.addColorStop(index, color)
    })
    return gradient
}

export const formatNumberWithCommas = (number: number): string =>
    number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

export const generateData = (
    max: number = 200,
    count: number = 100,
): number[] => {
    const data: number[] = []
    let currentValue = 0

    for (let i = 0; i < count; i++) {
        const step = (max - currentValue) / (count - i)
        const rand = (Math.random() - 0.5) * step * 10
        currentValue = Math.max(0, Math.min(currentValue + rand, max))
        data.push(currentValue)
    }

    return data
}
