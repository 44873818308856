/* eslint-disable jsx-a11y/label-has-associated-control */
import clsx from 'clsx'
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import {
    ReactNode,
    forwardRef,
    useState,
    type InputHTMLAttributes,
} from 'react'

import bxsShow from '../../assets/img/bxs-show.svg'

export enum InputStyles {
    solid = 'input--solid',
    outline = 'input--outline',
}
interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string
    error?: string
    inputStyle?: InputStyles
    afterLabel?: ReactNode
    togglePasswordShow?: boolean
}

const Input = forwardRef<HTMLInputElement, InputProps>(
    (
        {
            label,
            error,
            inputStyle = InputStyles.solid,
            afterLabel,
            togglePasswordShow,
            ...rest
        },
        ref,
    ) => {
        const [type, setType] = useState(rest.type)
        return (
            <div className="form-group input--lg w-full">
                {label && (
                    <div className="form-group-label">
                        <label className="label">{label}</label>
                        {afterLabel}
                    </div>
                )}
                <div className="w-full relative">
                    <input
                        className={clsx(
                            'input input--lg w-full',
                            rest.className,
                            inputStyle,
                        )}
                        ref={ref}
                        {...rest}
                        type={type}
                    />
                    {rest.type === 'password' && togglePasswordShow && (
                        <button
                            onClick={() =>
                                setType(
                                    type === 'password' ? 'text' : rest.type,
                                )
                            }
                            type="button"
                            className="input-password-show"
                        >
                            <img src={bxsShow} alt="bxs" />
                        </button>
                    )}
                </div>
                {error && (
                    <label className="form-label">
                        <div
                            className="label--text"
                            style={{ color: 'var(--danger)' }}
                        >
                            <span>{error}</span>
                        </div>
                    </label>
                )}
            </div>
        )
    },
)

export default Input
