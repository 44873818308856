import clsx from 'clsx'
import React from 'react'
import { Link } from 'react-router-dom'

import userImage from '../../../assets/img/user-1.png'
import Pagination from '../../../compoments/common/Pagination'
import SearchForm from '../../../compoments/common/SearchForm'
import SelectBox from '../../../compoments/common/SelectBox'

type UserRowProps = {
    user: {
        id: number
        photo: string
        name: string
        email: string
        phone: string
        position: string
        company: string
        skills: string
        certifications: string
        status: string
        tasksCompleted: number
        rank: number
        rankLabel: string
        tasks: number
    }
}
const UserRow: React.FC<UserRowProps> = ({ user }) => {
    return (
        <tr>
            <td>
                <Link to={user.id.toString()}>
                    <div className="flex items-center gap-8">
                        <div className="user-28x28">
                            <img src={user.photo} alt="user" />
                        </div>
                        <div className="flex flex-col">
                            <span className="text--xs fw-500">{user.name}</span>
                            <span className="text--12 text-color-1">
                                {user.email}
                            </span>
                        </div>
                    </div>
                </Link>
            </td>
            <td>
                <span className="text--xs">{user.phone}</span>
            </td>
            <td>
                <div className="flex flex-col">
                    <span className="text--xs fw-500">{user.position}</span>
                    <span className="text--12 text-color-1">
                        {user.company}
                    </span>
                </div>
            </td>
            <td>
                <span className="text--xs text-color-1 text-wrap">
                    {user.skills}
                </span>
            </td>
            <td>
                <span className="text--xs text-color-1 text-wrap">
                    {user.certifications}
                </span>
            </td>
            <td>
                <span
                    className={clsx('pill', {
                        'pill-success': user.status === 'active',
                        'pill-busy': user.status === 'busy',
                        'pill-on-leave': user.status === 'on-leave',
                    })}
                >
                    {user.status}
                </span>
            </td>
            <td>
                <div className="flex flex-col">
                    <span className="text--xs fw-500">
                        {user.tasksCompleted}
                    </span>
                    <span className="text--12 text-color-1">
                        {user.rankLabel}
                    </span>
                </div>
            </td>
            <td className="text-center">
                <span className="fw-600 text--xs color-primary">
                    {user.tasks}
                </span>
            </td>
        </tr>
    )
}

const TechnicianManagementPage = () => {
    const users = [
        {
            id: 1,
            photo: userImage,
            name: 'Floyd Miles',
            email: 'tanya.hill@example.com',
            phone: '(302) 555-0107',
            position: 'Field Service Technician',
            company: 'Tech Solutions Ltd.',
            skills: 'Plumbing, Electrical Repair',
            certifications: 'Certified Electrician, HVAC Technician',
            status: 'active',
            tasksCompleted: 639,
            rank: 7,
            rankLabel: 'Rank #7',
            tasks: 49,
        },
        {
            id: 2,
            photo: userImage,
            name: 'Floyd Miles',
            email: 'tanya.hill@example.com',
            phone: '(302) 555-0107',
            position: 'Field Service Technician',
            company: 'Tech Solutions Ltd.',
            skills: 'Plumbing, Electrical Repair',
            certifications: 'Certified Electrician, HVAC Technician',
            status: 'busy',
            tasksCompleted: 639,
            rank: 7,
            rankLabel: 'Rank #7',
            tasks: 49,
        },
        {
            id: 3,
            photo: userImage,
            name: 'Floyd Miles',
            email: 'tanya.hill@example.com',
            phone: '(302) 555-0107',
            position: 'Field Service Technician',
            company: 'Tech Solutions Ltd.',
            skills: 'Plumbing, Electrical Repair',
            certifications: 'Certified Electrician, HVAC Technician',
            status: 'on-leave',
            tasksCompleted: 639,
            rank: 7,
            rankLabel: 'Rank #7',
            tasks: 49,
        },
    ]
    const options = [
        {
            label: 'option 1',
            value: 'option 1',
        },
        {
            label: 'option 2',
            value: 'option 2',
        },
    ]
    return (
        <main className="content flex flex-col">
            <div className="px-container flex flex-col flex-auto">
                <div className="table-group">
                    <div className="table-header flex items-center justify-between flex-wrap">
                        <div className="table-tools">
                            <SelectBox
                                wrapperClassName="select--transparent"
                                options={options}
                                placeholder="Skills"
                            />
                            <SelectBox
                                wrapperClassName="select--transparent"
                                options={options}
                                placeholder="Certificates"
                            />
                            <SelectBox
                                wrapperClassName="select--transparent"
                                options={options}
                                placeholder="Status"
                            />
                            <SelectBox
                                wrapperClassName="select--transparent"
                                options={options}
                                placeholder="Jobs Completed"
                            />
                        </div>
                        <SearchForm />
                    </div>

                    <div className="table-body table-responsive p-0">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Full Name</th>
                                    <th>Phone</th>
                                    <th>Job & Company</th>
                                    <th
                                        style={{ minWidth: 125, maxWidth: 125 }}
                                    >
                                        Skills
                                    </th>
                                    <th
                                        style={{ minWidth: 145, maxWidth: 145 }}
                                    >
                                        Certificates
                                    </th>
                                    <th>Status</th>
                                    <th>Rating</th>
                                    <th>Jobs Completed</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.map((item, index) => (
                                    <UserRow user={item} key={index} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <Pagination
                    currentPage={1}
                    totalPages={10}
                    onPageChange={() => {}}
                />
            </div>
        </main>
    )
}

export default TechnicianManagementPage
