import FullCalendar from '@fullcalendar/react'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import { ReactNode, useEffect } from 'react'
import { useState } from 'react'
import DatePicker from 'react-datepicker'

import Calendar2Icon from '../../assets/img/ico-calendar-2.svg?react'

import 'react-datepicker/dist/react-datepicker.css'

type FullCalendarApi = {
    prev: () => void
    next: () => void
    changeView: (viewName: string) => void
    getDate: () => Date
    gotoDate: (date: Date) => void
}

interface CalendarProps {
    title?: string
    children?: ReactNode
    events?: any
    resources?: any
}

export default function CalendarWorkPage({
    children,
    events = [],
    resources = [],
}: CalendarProps) {
    const [view, setView] = useState('resourceTimelineDay')
    const [calendarApi, setCalendarApi] = useState<FullCalendarApi>()
    const [selectedDate, setSelectedDate] = useState<Date | null>(null)

    const handlePrev = () => {
        if (!calendarApi) return
        calendarApi?.prev()
        setSelectedDate(calendarApi.getDate())
    }

    const handleNext = () => {
        if (!calendarApi) return
        calendarApi?.next()
        setSelectedDate(calendarApi.getDate())
    }

    const handleViewChange = (newView: string) => {
        if (!calendarApi) return
        setView(newView)
        calendarApi?.changeView(newView)
        setSelectedDate(calendarApi.getDate())
    }

    useEffect(() => {
        const style = document.createElement('style')
        style.innerHTML = `
            .fc-timeline-body table td {
                width: 50px;
            }
            .fc-timeline-slot-frame {
                width: 100%;
            }
            .fc-timeline-slot.fc-timeline-slot-label.fc-slot.fc-slot-fri.fc-slot-today.fc-slot-past {
                width: 100px;
            }
            .fc-timeline-slot-cushion.fc-scrollgrid-sync-inner {
                text-transform: uppercase;
            }
        `
        document.head.appendChild(style)
        return () => {
            document.head.removeChild(style)
        }
    }, [])

    return (
        <div className="tab flex-auto active-tab" data-id="working-schedule">
            <div className="calendar calendar-working-schedule">
                <div className="calendar-header">
                    <div className="flex items-center gap-20">
                        <div className="c-h-item">
                            <div className="calendar-title">
                                <div className="flex items-center">
                                    <div style={{ width: 116, height: 24 }}>
                                        <DatePicker
                                            selected={
                                                selectedDate || new Date()
                                            }
                                            className="calendar-title"
                                            onChange={(date: Date | null) => {
                                                setSelectedDate(date)
                                                if (calendarApi && date) {
                                                    calendarApi.gotoDate(date)
                                                }
                                            }}
                                            dateFormat="MMM d, yyyy"
                                        />
                                    </div>
                                    <span className="ico absolute right-4">
                                        <Calendar2Icon />
                                    </span>
                                </div>
                            </div>
                        </div>
                        {calendarApi && (
                            <div className="flex items-center gap-8">
                                <button
                                    className="btn btn--navigation btn--square btn--xs rounded-full"
                                    id="prev"
                                    onClick={handlePrev}
                                >
                                    <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M4.16602 9.99992H15.8327"
                                            stroke="#7D8491"
                                            stroke-width="1.2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        ></path>
                                        <path
                                            d="M8.33268 5.83325L4.16602 9.99992"
                                            stroke="#7D8491"
                                            stroke-width="1.2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        ></path>
                                        <path
                                            d="M8.33268 14.1667L4.16602 10"
                                            stroke="#7D8491"
                                            stroke-width="1.2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        ></path>
                                    </svg>
                                </button>
                                <button
                                    className="btn btn--navigation btn--square btn--xs rounded-full"
                                    id="next"
                                    onClick={handleNext}
                                >
                                    <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M15.8327 9.99992H4.16602"
                                            stroke="#7D8491"
                                            stroke-width="1.2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        ></path>
                                        <path
                                            d="M11.666 14.1667L15.8327 10"
                                            stroke="#7D8491"
                                            stroke-width="1.2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        ></path>
                                        <path
                                            d="M11.666 5.83325L15.8327 9.99992"
                                            stroke="#7D8491"
                                            stroke-width="1.2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        ></path>
                                    </svg>
                                </button>
                            </div>
                        )}
                    </div>
                    {calendarApi && (
                        <div className="c-h-item">
                            <label className="calendar-mode" id="mode-days">
                                {[
                                    'resourceTimelineDay',
                                    'resourceTimelineWeek',
                                ].map((item, index) => (
                                    <label
                                        key={index}
                                        className="calendar-mode-item"
                                    >
                                        <input
                                            type="radio"
                                            checked={view === item}
                                            hidden
                                            onChange={() =>
                                                handleViewChange(item)
                                            }
                                        />
                                        <div className="calendar-mode-btn btn">
                                            {item.replace(
                                                'resourceTimeline',
                                                '',
                                            )}
                                        </div>
                                    </label>
                                ))}
                            </label>
                        </div>
                    )}
                </div>
            </div>
            <div className="calendar-body">
                <div className="full-calendar full-calendar__events fc fc-media-screen fc-direction-ltr fc-theme-standard">
                    <div className="fc-view-harness fc-view-harness-passive">
                        <div className="fc-resourceTimelineDay-view fc-view fc-resource-timeline fc-resource-timeline-flat fc-timeline fc-timeline-overlap-enabled">
                            <FullCalendar
                                plugins={[resourceTimelinePlugin]}
                                initialView={view}
                                resources={resources}
                                resourceAreaWidth={!resources?.length ? 0 : 200}
                                events={events}
                                resourceLabelContent={({ resource }) => {
                                    const { status, name, desc } =
                                        resource.extendedProps
                                    return {
                                        html: `<div class="event-calendar-resource event-calendar-resource--head ${status}">
                                            <div class="event-calendar-status"></div>
                                            <div class="event-calendar-content">
                                                <p class="event-calendar-title">${name}</p>
                                                <p class="event-calendar-desk">${desc}</p>
                                            </div>
                                        </div>`,
                                    }
                                }}
                                eventContent={({ event }) => {
                                    const { status, title, desk } =
                                        event.extendedProps
                                    return {
                                        html: `
                                    <div class="event-calendar-resource ${status}">
                                        <div class="event-calendar-status"></div>
                                        <div class="event-calendar-content">
                                            <p class="event-calendar-title">${title}</p>
                                            <p class="event-calendar-desk">${desk}</p>
                                        </div>
                                    </div>
                                `,
                                    }
                                }}
                                schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
                                ref={(calendarRef) =>
                                    setCalendarApi(
                                        calendarRef?.getApi() as FullCalendarApi,
                                    )
                                }
                                slotMinTime="08:00:00"
                                slotMaxTime="23:00:00"
                                slotLabelFormat={{
                                    hour: 'numeric',
                                    minute: '2-digit',
                                    meridiem: 'short',
                                }}
                                headerToolbar={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
