import clsx from 'clsx'
import type { ButtonHTMLAttributes } from 'react'
import React from 'react'

import Spinner from '../Spinner'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    variant?: 'default' | 'primary' | 'primary-gradient' | 'secondary'
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
    square?: boolean
    loading?: boolean
}

const Button: React.FC<ButtonProps> = ({
    variant = 'primary-gradient',
    size = 'lg',
    square = false,
    className,
    loading = false,
    ...props
}) => {
    const buttonClass = clsx(
        'btn fullRadius',
        `btn--${variant}`,
        `btn--${size}`,
        {
            'btn--square': square,
            disabled: props.disabled,
        },
        className,
    )

    return (
        <button className={buttonClass} {...props}>
            {!loading ? props.children : <Spinner />}
        </button>
    )
}

export default Button
