/* eslint-disable jsx-a11y/label-has-associated-control */

import clsx from 'clsx'

import CloseIcon from '../../assets/img/ico-close-popup.svg?react'
import { useTabs } from '../../hooks/useTabs'
import Modal from '../common/Modal'
import SelectBox from '../common/SelectBox'

interface AddNotificationModalProps {
    isOpen: boolean
    onClose: () => void
}

const AddNotificationModal = ({
    isOpen,
    onClose,
}: AddNotificationModalProps) => {
    const tabs = [
        {
            id: 'general_settings',
            label: 'General Settings',
        },
        {
            id: 'target',
            label: 'Target',
        },
        {
            id: 'email',
            label: 'Email',
        },
        {
            id: 'sms',
            label: 'SMS',
        },
        {
            id: 'notification',
            label: 'Notification',
        },
    ]
    const { activeTab, setActiveTab } = useTabs(tabs)
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <div className="modal__header">
                <p className="modal__header-title">Add Notification</p>
                <button
                    onClick={onClose}
                    className="btn btn-close"
                    data-close-modal=""
                >
                    <span className="ico">
                        <CloseIcon />
                    </span>
                </button>
            </div>
            <div className="modal__body p-0">
                <div className="tabs flex-auto">
                    <div className="tabs-links-list modal__tabs__body">
                        {tabs.map((tab) => (
                            <button
                                onClick={() => setActiveTab(tab.id)}
                                className={clsx(
                                    'btn full-radius btn--xs btn-tab-custom',
                                    { 'active-tab': activeTab === tab.id },
                                )}
                            >
                                <span>{tab.label}</span>
                            </button>
                        ))}
                    </div>
                    <div className="tabs-body flex-auto">
                        {activeTab === 'general_settings' && (
                            <div className="tab flex-auto active-tab">
                                <form className="custom-form" method="POST">
                                    <div className="custom-form-body">
                                        <div className="row gutters-16">
                                            <div className="col-12">
                                                <SelectBox
                                                    label="Select the event"
                                                    wrapperClassName="select-secondary form-group--row input--lg"
                                                    options={[
                                                        {
                                                            label: 'System Under Maintenance',
                                                            value: 'System Under Maintenance',
                                                        },
                                                    ]}
                                                    placeholder={
                                                        'Select the event'
                                                    }
                                                />
                                            </div>
                                            <div className="col-12">
                                                <SelectBox
                                                    label="Schedule"
                                                    wrapperClassName="select-secondary form-group--row input--lg"
                                                    options={[
                                                        {
                                                            label: 'At the time of the event',
                                                            value: 'At the time of the event',
                                                        },
                                                    ]}
                                                    placeholder={
                                                        'Select the event'
                                                    }
                                                />
                                            </div>
                                            <div className="col-12">
                                                <SelectBox
                                                    label="Code"
                                                    wrapperClassName="select-secondary form-group--row input--lg"
                                                    options={[
                                                        {
                                                            label: '#123',
                                                            value: '#123',
                                                        },
                                                    ]}
                                                    placeholder={
                                                        'Select the event'
                                                    }
                                                />
                                            </div>
                                            <div className="col-12 mt-5 mb-2">
                                                <div className="form-group--row input--xl items-start">
                                                    <ul className="labels-list">
                                                        {[
                                                            'Email',
                                                            'SMS',
                                                            'Notifications',
                                                        ].map((item, index) => (
                                                            <li
                                                                key={index}
                                                                className="label-item"
                                                            >
                                                                <label className="custom-checkbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        name="notification-method"
                                                                        checked
                                                                        className="custom-checkbox__input"
                                                                    />
                                                                    <span className="custom-checkbox__input-fake">
                                                                        <span className="icon-ico-check"></span>
                                                                    </span>
                                                                    <span className="custom-checkbox__label">
                                                                        {item}
                                                                    </span>
                                                                </label>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="custom-form-footer justify-end">
                                        <div className="flex-group flex-group--xs">
                                            <button
                                                type="submit"
                                                className="btn btn--lg 
                                        full-radius btn--primary-gradient"
                                            >
                                                <span>Add Notification</span>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        )}
                        {activeTab === 'target' && (
                            <div
                                className="tab flex-auto active-tab"
                                data-id="target"
                            >
                                <form className="custom-form" method="POST">
                                    <div className="custom-form-body">
                                        <div className="row gutters-16">
                                            <div className="col-12">
                                                <div className="form-group--row input--xl items-start">
                                                    <label className="label">
                                                        Select target users
                                                    </label>

                                                    <ul className="labels-list">
                                                        {[
                                                            'All',
                                                            'Dispatchers',
                                                            'Executives',
                                                            'Tenant Admin',
                                                            'Clients',
                                                        ].map((item, index) => (
                                                            <li
                                                                key={index}
                                                                className="label-item"
                                                            >
                                                                <label className="custom-checkbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        name="target_usres"
                                                                        checked
                                                                        className="custom-checkbox__input"
                                                                    />
                                                                    <span className="custom-checkbox__input-fake">
                                                                        <span className="icon-ico-check"></span>
                                                                    </span>
                                                                    <span className="custom-checkbox__label">
                                                                        {item}
                                                                    </span>
                                                                </label>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="custom-form-footer justify-end">
                                        <div className="flex-group flex-group--xs">
                                            <button
                                                type="submit"
                                                className="btn btn--lg 
                                        full-radius btn--primary-gradient"
                                            >
                                                <span>Add Notification</span>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        )}
                        {activeTab === 'email' && (
                            <div className="tab flex-auto active-tab">
                                <form className="custom-form" method="POST">
                                    <div className="custom-form-body">
                                        <div className="row gutters-16">
                                            <div className="col-md-6 col-sm-6 col-12">
                                                <div className="form-group--row input--lg">
                                                    <label className="label">
                                                        From (Name)
                                                    </label>
                                                    <input
                                                        className="input input--outline w-full"
                                                        type="text"
                                                        placeholder=""
                                                        value="Jane Cooper"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-6 col-12">
                                                <div className="form-group--row input--lg">
                                                    <label className="label">
                                                        From (Email)
                                                    </label>
                                                    <input
                                                        className="input input--outline w-full"
                                                        type="text"
                                                        placeholder=""
                                                        value="debra.holt@example.com"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group--row input--lg">
                                                    <label className="label">
                                                        Subject
                                                    </label>
                                                    <input
                                                        className="input input--outline w-full"
                                                        type="text"
                                                        placeholder="Some text..."
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group--row input--lg">
                                                    <label className="label">
                                                        Description
                                                    </label>
                                                    <textarea
                                                        className="textarea 
                                                --none-resize input--outline w-full"
                                                        placeholder="Some text..."
                                                        rows={5}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="custom-form-footer justify-end">
                                        <div className="flex-group flex-group--xs">
                                            <button
                                                type="submit"
                                                className="btn btn--lg 
                                        full-radius btn--primary-gradient"
                                            >
                                                <span>Add Notification</span>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        )}
                        {activeTab === 'sms' && (
                            <div className="tab flex-auto active-tab">
                                <form className="custom-form" method="POST">
                                    <div className="custom-form-body">
                                        <div className="row gutters-16">
                                            <div className="col-md-6 col-sm-6 col-12">
                                                <div className="form-group--row input--lg">
                                                    <label className="label">
                                                        From (Name)
                                                    </label>
                                                    <input
                                                        className="input input--outline w-full"
                                                        type="text"
                                                        placeholder=""
                                                        value="Jane Cooper"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-6 col-12">
                                                <div className="form-group--row input--lg">
                                                    <label className="label">
                                                        From (Phone)
                                                    </label>
                                                    <input
                                                        className="input input--outline w-full"
                                                        type="text"
                                                        placeholder=""
                                                        value="(217) 555-0113"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group--row input--lg">
                                                    <label className="label">
                                                        Subject
                                                    </label>
                                                    <input
                                                        className="input input--outline w-full"
                                                        type="text"
                                                        placeholder="Some text..."
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group--row input--lg">
                                                    <label className="label">
                                                        Description
                                                    </label>
                                                    <textarea
                                                        className="textarea 
                                                --none-resize input--outline w-full"
                                                        placeholder="Some text..."
                                                        rows={5}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="custom-form-footer justify-end">
                                        <div className="flex-group flex-group--xs">
                                            <button
                                                type="submit"
                                                className="btn btn--lg 
                                        full-radius btn--primary-gradient"
                                            >
                                                <span>Add Notification</span>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        )}
                        {activeTab === 'notification' && (
                            <div className="tab flex-auto active-tab">
                                <form className="custom-form" method="POST">
                                    <div className="custom-form-body">
                                        <div className="row gutters-16">
                                            <div className="col-12">
                                                <div className="form-group--row input--lg">
                                                    <label className="label">
                                                        Subject
                                                    </label>
                                                    <input
                                                        className="input input--outline w-full"
                                                        type="text"
                                                        placeholder="Some text..."
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group--row input--lg">
                                                    <label className="label">
                                                        Description
                                                    </label>
                                                    <textarea
                                                        className="textarea 
                                                --none-resize input--outline w-full"
                                                        placeholder="Some text..."
                                                        rows={5}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="custom-form-footer justify-end">
                                        <div className="flex-group flex-group--xs">
                                            <button
                                                type="submit"
                                                className="btn btn--lg 
                                        full-radius btn--primary-gradient"
                                            >
                                                <span>Add Notification</span>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default AddNotificationModal
