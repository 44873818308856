/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import BellIcon from '../../assets/img/ico-bell-2.svg?react'
import BellSvg from '../../assets/img/ico-bell.svg?react'
import ChevronDownIcon from '../../assets/img/ico-chevro-down.svg?react'
import ChevronPrevIcon from '../../assets/img/ico-chevron-prev.svg?react'
import Close2Svg from '../../assets/img/ico-close-2.svg?react'
import ExportIcon from '../../assets/img/ico-export.svg?react'
import InfoIcon from '../../assets/img/ico-info.svg?react'
import LunaThemeSvg from '../../assets/img/ico-luna-theme.svg?react'
import SunThemeSvg from '../../assets/img/ico-sun-theme.svg?react'
import WarnIcon from '../../assets/img/ico-warn.svg?react'
import { useGetMeQuery, useLazyLogoutQuery } from '../../services/auth'
import type { User } from '../../types'
import { getMediaUrl } from '../../utils/helpers'
import Dropdown from '../common/Dropdown'
import SearchForm from '../common/SearchForm'

const NotificationDropdownButton = () => (
    <span className="ico">
        <BellSvg />
    </span>
)
const NotificationDropdownMenu = () => {
    const notifications = [
        {
            icon: InfoIcon,
            title: 'Informative Notification',
            description:
                'Some text for informative notification will be visible here and you can read it.',
            time: '5h',
        },
        {
            icon: BellIcon,
            title: 'Positive Notification',
            description:
                'Some text for positive notification will be visible here and you can read it.',
            time: '3h',
        },
        {
            icon: WarnIcon,
            title: 'Negative Notification',
            description:
                'Some text for negative notification will be visible here and you can read it.',
            time: '1h',
        },
    ]

    return (
        <div className="modal-dropdown">
            <div className="modal-dropdown--header">
                <p className="modal-dropdown--title text--sm font-500">
                    Notifications (123)
                </p>
                <div className="modal-dropdown--actions">
                    <a href="#" className="btn color-primary text--sm font-500">
                        <span>Clear All</span>
                    </a>
                    <button
                        type="button"
                        className="btn btn--xxs btn--square btn--secondary-3 full-radius fix-dropdown-btn"
                    >
                        <span className="ico">
                            <Close2Svg />
                        </span>
                    </button>
                </div>
            </div>
            <div className="modal-dropdown--body">
                <ul className="list-notifications">
                    {notifications.map((notification, index) => (
                        <li key={index} className="item-notification">
                            <a href="#" className="btn-notification">
                                <div className="notification-header">
                                    <span className="noti-ico ico">
                                        <notification.icon />
                                    </span>
                                    <p className="noti-title">
                                        {notification.title}
                                    </p>
                                    <span className="noti-time">
                                        {notification.time}
                                    </span>
                                </div>
                                <div className="notification-body">
                                    <p className="noti-description">
                                        {notification.description}
                                    </p>
                                </div>
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

const ProfileDropdownButton = ({ user }: { user: User }) => (
    <>
        <div className="profile-info">
            <div className="ico-avatar btn btn--xl btn--square">
                <img src={getMediaUrl(user.photo)} alt={user.fullName} />
            </div>
            <div className="profile-initials">
                <p className="profile-name">{user.fullName}</p>
                <p className="profile-role">Dispatcher</p>
            </div>
        </div>
        <div className="ico ico-profile-arrow-drp">
            <ChevronDownIcon />
        </div>
    </>
)
const ProfileDropdownMenu = ({ onLogout }: { onLogout: () => void }) => {
    const links = [
        {
            name: 'Account Settings',
            href: '/settings',
        },
        {
            name: 'Application Preferences',
            href: '/settings',
        },
        {
            name: 'Notification Settings',
            href: '/settings',
        },
        {
            name: 'Security Settings',
            href: '/settings',
        },
    ]
    return (
        <ul className="dropdown-list-links">
            {links.map((link, index) => (
                <li key={index} className="dropdown-list-link">
                    <Link
                        to={link.href}
                        className="btn btn--md text-left btn-drp-link justify-start"
                    >
                        <span>{link.name}</span>
                    </Link>
                </li>
            ))}
            <li className="dropdown-list-link px-3">
                <hr />
            </li>
            <li className="dropdown-list-link">
                <button
                    onClick={onLogout}
                    className="btn btn--md text-left btn-drp-link justify-start"
                >
                    <span>Log Out</span>
                </button>
            </li>
        </ul>
    )
}

const Header = () => {
    const { pathname } = useLocation()
    const { data, isLoading, isUninitialized } = useGetMeQuery()
    const navigate = useNavigate()
    const [logout] = useLazyLogoutQuery()
    const user = data?.data

    const handleToggleTheme = () => {
        document.querySelector('body')?.classList.toggle('dark-theme')
    }
    const handleLogout = () => {
        logout()
        navigate('/login')
    }

    const map: { [key: string]: string } = {
        '/': 'Dashboard',
        '/dispatcher-resource-sheduling': 'Resource Scheduling',
        '/dispatcher-technician-management': 'Technician Management',
        '/dispatcher-customer-management': 'Customer Management',
        '/dispatcher-reports': 'Reports',
        '/dispatcher-notification-management': 'Notification Management',
        '/dispatcher-chat': 'Chat',
        '/settings': 'Settings',
    }
    useEffect(() => {
        if (!user && !isLoading && !isUninitialized) {
            navigate('/login')
        }
    }, [user, isLoading, isUninitialized])
    if (isLoading || !user) return null

    return (
        <header className="header sidebar-indentation">
            <div className="header-container px-container">
                <div className="header-option">
                    {pathname.split('/').length > 2 && (
                        <Link
                            to={'/' + pathname.split('/')[1]}
                            className="btn btn--xl btn--square full-radius btn-shadow-iset"
                        >
                            <ChevronPrevIcon />
                        </Link>
                    )}
                    <h4 className="heading page-title">
                        {map[pathname] || 'Dashboard'}
                    </h4>
                    {pathname === '/' && (
                        <a
                            href="#"
                            download
                            className="btn btn--primary-gradient btn--sm full-radius"
                        >
                            <span className="ico">
                                <ExportIcon />
                            </span>
                            <span>Export</span>
                        </a>
                    )}
                </div>
                <div className="header-tools-list">
                    <div className="header-tool-item flex-auto justify-end">
                        <div className="header-tool-item--sm flex-auto justify-end">
                            <SearchForm />
                            <Dropdown
                                buttonClassName={'btn btn-tool'}
                                button={<NotificationDropdownButton />}
                                menuClassName={
                                    'dropdown__body dropdown-notifications'
                                }
                                menu={<NotificationDropdownMenu />}
                            />
                        </div>
                        <label className="switch-control">
                            <input
                                type="checkbox"
                                hidden
                                className="theme-switch"
                                name="theme-color"
                                onChange={handleToggleTheme}
                            />
                            <div className="switch-btn">
                                <div className="switch-point-wrapp">
                                    <span className="switch-point"></span>
                                </div>
                                <div className="bg-switch-wrapper">
                                    <span className="ico">
                                        <LunaThemeSvg />
                                    </span>
                                    <span className="ico">
                                        <SunThemeSvg />
                                    </span>
                                </div>
                            </div>
                        </label>
                    </div>
                    <div className="header-tool-item">
                        <Dropdown
                            buttonClassName={
                                'btn dropdown-profile-btn btn btn--xl'
                            }
                            button={<ProfileDropdownButton user={user} />}
                            menuClassName={
                                'dropdown__body dropdown-profile-body'
                            }
                            menu={
                                <ProfileDropdownMenu onLogout={handleLogout} />
                            }
                        />
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header
