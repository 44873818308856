/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'

import cityJobsIcon from '../../../assets/img/ico-city-job.svg'
import closedJobsIcon from '../../../assets/img/ico-closed-job.svg'
import jobsIcon from '../../../assets/img/ico-jobs.svg'
import openJobsIcon from '../../../assets/img/ico-open-jobs.svg'
import RatingDownIcon from '../../../assets/img/ico-rate-down.svg?react'
import RatingUpIcon from '../../../assets/img/ico-rate-up.svg?react'
import TrendChart from '../../../compoments/charts/TrendChart'

interface JobStatistics {
    label: string
    icon: string
    trend: 'up' | 'down'
    value: number
    percent: number
}

const ReportsPage = () => {
    const jobStatistics: JobStatistics[] = [
        {
            label: 'Jobs',
            icon: jobsIcon,
            trend: 'down',
            value: 112,
            percent: 12,
        },
        {
            label: 'Open Jobs',
            icon: openJobsIcon,
            trend: 'up',
            value: 112,
            percent: 12,
        },
        {
            label: 'KIV Jobs',
            icon: cityJobsIcon,
            trend: 'down',
            value: 112,
            percent: 12,
        },
        {
            label: 'Closed Jobs',
            icon: closedJobsIcon,
            trend: 'up',
            value: 112,
            percent: 12,
        },
    ]
    return (
        <main className="content flex flex-col">
            <div className="px-container flex flex-col flex-auto">
                <div className="row gutters-28">
                    <div className="col-12">
                        <div className="row gutters-18">
                            {jobStatistics.map((item, index) => (
                                <div
                                    key={index}
                                    className="col-xl-3 col-md-6 col-12"
                                >
                                    <div className="card card-shadow card-seo">
                                        <div className="card-body">
                                            <div className="card-seo-info flex-shrink-0">
                                                <div className="seo-title">
                                                    <span className="ico">
                                                        <img
                                                            src={item.icon}
                                                            alt={item.label}
                                                        />
                                                    </span>
                                                    <span className="name">
                                                        {item.label}
                                                    </span>
                                                </div>
                                                <h1 className="seo-stat-title">
                                                    {item.value}
                                                </h1>
                                            </div>
                                            <div
                                                className="card-seo-chart"
                                                style={{
                                                    height: 60,
                                                    width: 100,
                                                }}
                                            >
                                                <TrendChart
                                                    trend={item.trend}
                                                />
                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            <div className="seo-rating-row">
                                                <div
                                                    className={clsx(
                                                        'seo-rating',
                                                        `--${item.trend}`,
                                                    )}
                                                >
                                                    <span className="ico">
                                                        {item.trend === 'up' ? (
                                                            <RatingUpIcon />
                                                        ) : (
                                                            <RatingDownIcon />
                                                        )}
                                                    </span>{' '}
                                                    <span>
                                                        {item.trend === 'up'
                                                            ? '+'
                                                            : '-'}
                                                        {item.percent}%
                                                    </span>
                                                </div>
                                                <p className="seo-rating-desk">
                                                    <span>vs</span> yesterday
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="table-group flex flex-col">
                            <div className="calendar-header">
                                <div className="flex items-center gap-20">
                                    <div className="c-h-item">
                                        <div className="calendar-title">
                                            <div id="title">Mar, 2024</div>
                                            <span className="ico">
                                                <svg
                                                    width="18"
                                                    height="18"
                                                    viewBox="0 0 18 18"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M6.50008 0.833252V2.49992H11.5001V0.833252H13.1667V2.49992H16.5001C16.7211 2.49992 16.9331 2.58772 17.0893 2.744C17.2456 2.90028 17.3334 3.11224 17.3334 3.33325V16.6666C17.3334 16.8876 17.2456 17.0996 17.0893 17.2558C16.9331 17.4121 16.7211 17.4999 16.5001 17.4999H1.50008C1.27907 17.4999 1.06711 17.4121 0.910826 17.2558C0.754545 17.0996 0.666748 16.8876 0.666748 16.6666V3.33325C0.666748 3.11224 0.754545 2.90028 0.910826 2.744C1.06711 2.58772 1.27907 2.49992 1.50008 2.49992H4.83341V0.833252H6.50008ZM15.6667 9.16658H2.33341V15.8333H15.6667V9.16658ZM4.83341 4.16659H2.33341V7.49992H15.6667V4.16659H13.1667V5.83325H11.5001V4.16659H6.50008V5.83325H4.83341V4.16659Z"
                                                        fill="currentColor"
                                                    ></path>
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-8">
                                        <a
                                            className="btn btn--navigation btn--square btn--xs rounded-full"
                                            href="#"
                                        >
                                            <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M4.16602 9.99992H15.8327"
                                                    stroke="#7D8491"
                                                    stroke-width="1.2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                ></path>
                                                <path
                                                    d="M8.33268 5.83325L4.16602 9.99992"
                                                    stroke="#7D8491"
                                                    stroke-width="1.2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                ></path>
                                                <path
                                                    d="M8.33268 14.1667L4.16602 10"
                                                    stroke="#7D8491"
                                                    stroke-width="1.2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                ></path>
                                            </svg>
                                        </a>
                                        <a
                                            className="btn btn--navigation btn--square btn--xs rounded-full"
                                            href="#"
                                        >
                                            <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M15.8327 9.99992H4.16602"
                                                    stroke="#7D8491"
                                                    stroke-width="1.2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                ></path>
                                                <path
                                                    d="M11.666 14.1667L15.8327 10"
                                                    stroke="#7D8491"
                                                    stroke-width="1.2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                ></path>
                                                <path
                                                    d="M11.666 5.83325L15.8327 9.99992"
                                                    stroke="#7D8491"
                                                    stroke-width="1.2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                ></path>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                                <div className="c-h-item">
                                    <a
                                        href="#"
                                        download=""
                                        className="btn btn--primary-gradient btn--sm full-radius"
                                    >
                                        <span className="ico">
                                            <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M9.16699 13.3333H10.8337V5.83329H13.3337L10.0003 1.66663L6.66699 5.83329H9.16699V13.3333Z"
                                                    fill="currentColor"
                                                ></path>
                                                <path
                                                    d="M4.16667 18.3333H15.8333C16.7525 18.3333 17.5 17.5858 17.5 16.6667V9.16667C17.5 8.2475 16.7525 7.5 15.8333 7.5H12.5V9.16667H15.8333V16.6667H4.16667V9.16667H7.5V7.5H4.16667C3.2475 7.5 2.5 8.2475 2.5 9.16667V16.6667C2.5 17.5858 3.2475 18.3333 4.16667 18.3333Z"
                                                    fill="currentColor"
                                                ></path>
                                            </svg>
                                        </span>
                                        <span>Export All</span>
                                    </a>
                                </div>
                            </div>

                            <div className="table-body table-responsive p-0">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Report Name</th>
                                            <th>Status</th>
                                            <th style={{ width: 10 }}>
                                                Actions
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <span className="text-color-1">
                                                    1 Mar, 2024
                                                </span>{' '}
                                            </td>
                                            <td>Some text for report name</td>
                                            <td>
                                                <span className="pill pill-success">
                                                    Active
                                                </span>
                                            </td>

                                            <td className="text-right sticky-end">
                                                <a href="" className="btn">
                                                    <span className="ico me-3">
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 20 20"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M9.1665 13.3334H10.8332V5.83335H13.3332L9.99984 1.66669L6.6665 5.83335H9.1665V13.3334Z"
                                                                fill="#232ED1"
                                                            />
                                                            <path
                                                                d="M4.16667 18.3333H15.8333C16.7525 18.3333 17.5 17.5858 17.5 16.6667V9.16667C17.5 8.2475 16.7525 7.5 15.8333 7.5H12.5V9.16667H15.8333V16.6667H4.16667V9.16667H7.5V7.5H4.16667C3.2475 7.5 2.5 8.2475 2.5 9.16667V16.6667C2.5 17.5858 3.2475 18.3333 4.16667 18.3333Z"
                                                                fill="#232ED1"
                                                            />
                                                        </svg>
                                                    </span>
                                                    <span className="color-primary">
                                                        Export
                                                    </span>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default ReportsPage
