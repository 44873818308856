import UsersSvg from '../../../assets/img/card-decor-1.svg'
import TeamsSvg from '../../../assets/img/card-decor-2.svg'
import WorkGroupsSvg from '../../../assets/img/card-decor-3.svg'
import ActiveOrdersSvg from '../../../assets/img/card-decor-4.svg'
import CompletedOrdersSvg from '../../../assets/img/card-decor-5.svg'
import DotsSvg from '../../../assets/img/dots.svg'
import Avatar from '../../../assets/img/user-1.png'

export const AdminDashboard = () => {
    return (
        <main className="content flex flex-col">
            <div className="px-container flex flex-col flex-auto">
                <div className="info-card-list mb-13">
                    <div className="info-card-item">
                        <div className="info-card">
                            <div className="info-card__decor-group">
                                <div className="info-card__img-decor">
                                    <img src={UsersSvg} alt="" />
                                </div>
                                <span>Users</span>
                            </div>
                            <span className="info-cunter">150</span>
                        </div>
                    </div>
                    <div className="info-card-item">
                        <div className="info-card">
                            <div className="info-card__decor-group">
                                <div className="info-card__img-decor">
                                    <img src={TeamsSvg} alt="" />
                                </div>
                                <span>Teams</span>
                            </div>
                            <span className="info-cunter">25</span>
                        </div>
                    </div>
                    <div className="info-card-item">
                        <div className="info-card">
                            <div className="info-card__decor-group">
                                <div className="info-card__img-decor">
                                    <img src={WorkGroupsSvg} alt="" />
                                </div>
                                <span>WorkGroups</span>
                            </div>
                            <span className="info-cunter">10</span>
                        </div>
                    </div>
                    <div className="info-card-item">
                        <div className="info-card">
                            <div className="info-card__decor-group">
                                <div className="info-card__img-decor">
                                    <img src={ActiveOrdersSvg} alt="" />
                                </div>
                                <span>Active Orders</span>
                            </div>
                            <span className="info-cunter">200</span>
                        </div>
                    </div>
                    <div className="info-card-item">
                        <div className="info-card">
                            <div className="info-card__decor-group">
                                <div className="info-card__img-decor">
                                    <img src={CompletedOrdersSvg} alt="" />
                                </div>
                                <span>Completed Orders</span>
                            </div>
                            <span className="info-cunter">1,500</span>
                        </div>
                    </div>
                </div>
                <div className="row gx-16 gy-28 flex-auto">
                    <div className="col-lg-6">
                        <div className="table-group">
                            <div className="table-header flex items-center justify-between flex-wrap p-20">
                                <h5>User Management</h5>

                                <div className="flex items-center gap-32">
                                    <div
                                        className="dropdown"
                                        data-dropdown="dropdown"
                                    >
                                        <a
                                            href="#"
                                            data-role="button"
                                            className="btn gap-6"
                                        >
                                            <span className="text--xs">
                                                All Roles
                                            </span>
                                            <div className="ico ico-profile-arrow-drp">
                                                <svg
                                                    width="14"
                                                    height="14"
                                                    viewBox="0 0 14 14"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <g>
                                                        <path
                                                            d="M11.375 5.25L7 9.625L2.625 5.25"
                                                            stroke="#7D8491"
                                                            stroke-width="1.5"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        ></path>
                                                    </g>
                                                    <defs>
                                                        <clipPath>
                                                            <rect
                                                                width="14"
                                                                height="14"
                                                                fill="white"
                                                            ></rect>
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </a>

                                        <div
                                            className="dropdown__body"
                                            data-role="dropdown"
                                        ></div>
                                    </div>
                                    <a
                                        href="#"
                                        className="btn color-primary gap-6 text--xs fw-500"
                                    >
                                        <span className="text--xs">
                                            Show All
                                        </span>
                                        <svg
                                            width="14"
                                            height="14"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M5.25 2.625L9.625 7L5.25 11.375"
                                                stroke="#232ED1"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            ></path>
                                        </svg>
                                    </a>
                                </div>
                            </div>

                            <div className="table-body table-responsive p-0">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Full Name</th>
                                            <th>Role</th>
                                            <th style={{ width: '10px' }}>
                                                Status
                                            </th>
                                            <th style={{ width: '10px' }}>
                                                Actions
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className="flex items-center gap-8">
                                                    <div className="user-28x28">
                                                        <img
                                                            src={Avatar}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <span className="text--xs fw-500">
                                                        Jane Cooper
                                                    </span>
                                                </div>
                                            </td>

                                            <td>Executive</td>
                                            <td>
                                                <span className="pill pill-success">
                                                    Active
                                                </span>
                                            </td>
                                            <td className="text-right sticky-end">
                                                <div
                                                    className="dropdown flex flex-col"
                                                    data-position="top-end"
                                                    data-dropdown="dropdown"
                                                >
                                                    <a
                                                        href="#"
                                                        className="btn dropdown-btn"
                                                        data-role="button"
                                                    >
                                                        <span className="ico">
                                                            <img
                                                                src={DotsSvg}
                                                                alt=""
                                                            />
                                                        </span>
                                                    </a>
                                                    <div
                                                        className="dropdown__body dropdown-md"
                                                        data-role="dropdown"
                                                    >
                                                        <ul className="dropdown-list">
                                                            <li>
                                                                <a
                                                                    href="#"
                                                                    className="btn size--sm btn-link-drp"
                                                                >
                                                                    <span>
                                                                        Show
                                                                        Item
                                                                    </span>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="table-group">
                            <div className="table-header flex items-center justify-between flex-wrap p-20">
                                <h5>Teams</h5>

                                <a
                                    href="#"
                                    className="btn color-primary gap-6 text--xs fw-500"
                                >
                                    <span className="text--xs">Show All</span>
                                    <svg
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M5.25 2.625L9.625 7L5.25 11.375"
                                            stroke="#232ED1"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        ></path>
                                    </svg>
                                </a>
                            </div>

                            <div className="table-body table-responsive p-0">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Team</th>
                                            <th>Team Lead</th>
                                            <th>Users</th>
                                            <th style={{ width: '10px' }}>
                                                Actions
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Team Name 1</td>
                                            <td>
                                                <div className="flex items-center gap-8">
                                                    <div className="user-28x28">
                                                        <img
                                                            src={Avatar}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <span className="text--xs fw-500">
                                                        Eleanor Pena
                                                    </span>
                                                </div>
                                            </td>

                                            <td>
                                                <div className="user-list-group">
                                                    <ul className="user-list">
                                                        <li>
                                                            <div className="user-28x28">
                                                                <img
                                                                    src={Avatar}
                                                                    alt=""
                                                                />
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="user-28x28">
                                                                <img
                                                                    src={Avatar}
                                                                    alt=""
                                                                />
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="user-28x28">
                                                                <img
                                                                    src={Avatar}
                                                                    alt=""
                                                                />
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    <span className="user-cunter">
                                                        +3
                                                    </span>
                                                </div>
                                            </td>

                                            <td className="text-right sticky-end">
                                                <div
                                                    className="dropdown flex flex-col"
                                                    data-position="top-end"
                                                    data-dropdown="dropdown"
                                                >
                                                    <a
                                                        href="#"
                                                        className="btn dropdown-btn"
                                                        data-role="button"
                                                    >
                                                        <span className="ico">
                                                            <img
                                                                src={DotsSvg}
                                                                alt=""
                                                            />
                                                        </span>
                                                    </a>
                                                    <div
                                                        className="dropdown__body dropdown-md"
                                                        data-role="dropdown"
                                                    >
                                                        <ul className="dropdown-list">
                                                            <li>
                                                                <a
                                                                    href="#"
                                                                    className="btn size--sm btn-link-drp"
                                                                >
                                                                    <span>
                                                                        Show
                                                                        Item
                                                                    </span>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}
