import { useState } from 'react'

import { AccountSettings } from './AccountSettings'
import { SecuritySettings } from './SecuritySettings'

export const Settings = () => {
    const tabs = [
        { name: 'Account Settings', id: 1 },
        { name: 'Application Preferences', id: 2 },
        { name: 'Notification Settings', id: 3 },
        { name: 'Security Settings', id: 4 },
    ]

    const [activeTab, setActiveTab] = useState(tabs[0].id)

    return (
        <main className="content flex flex-col">
            <div className="px-container flex flex-col flex-auto">
                <div className="settings-row">
                    <aside className="sidebar-settings">
                        <div className="sidebar-settings__sidebar-body">
                            <ul className="menu-list">
                                {tabs.map((el) => (
                                    <li key={el.id} className="menu-list--item">
                                        <button
                                            className={`btn setting-link ${activeTab === el.id ? '--active' : ''}`}
                                            onClick={() => setActiveTab(el.id)}
                                        >
                                            <span className="setting-link-text">
                                                {el.name}
                                            </span>
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </aside>

                    {activeTab === 1 && <AccountSettings />}
                    {activeTab === 4 && <SecuritySettings />}
                </div>
            </div>
        </main>
    )
}
