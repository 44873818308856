import type { ChartOptions, TooltipItem } from 'chart.js'
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js'
import type { ChartProps } from 'react-chartjs-2'
import { Bar } from 'react-chartjs-2'

import { COLORS, FONT } from '../../utils/charts'

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
)
interface StackedBarChartProps
    extends Omit<ChartProps<'bar'>, 'data' | 'type'> {
    labels: string[]
    values: number[][]
    units?: string
}

const StackedBarChart = ({ labels, values, ...rest }: StackedBarChartProps) => {
    const options: ChartOptions<'bar'> = {
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context: TooltipItem<'bar'>) {
                        let label = context.dataset.label || ''
                        if (label) {
                            label += ': '
                        }
                        label += context.raw?.toLocaleString()
                        return label
                    },
                },
            },
            legend: {
                position: 'top',
                align: 'start',
                labels: {
                    usePointStyle: true,
                    boxWidth: 6,
                    boxHeight: 6,
                    font: FONT,
                    color: '#100B00',
                },
            },
        },
        scales: {
            x: {
                stacked: true,
                title: {
                    display: false,
                    text: 'Days',
                },
                // barThickness: 20,
                // categoryPercentage: 0.8,
                // barPercentage: 0.9,
                min: 0,
                max: 1500,
                grid: {
                    display: false,
                    drawOnChartArea: false,
                    drawTicks: false,
                },
                border: {
                    display: false,
                    color: 'A6A6A6',
                },
                ticks: {
                    stepSize: 300,
                    font: FONT,
                    color: '#A6A6A6',
                    padding: 10,
                },
            },
            y: {
                stacked: true,
                title: {
                    display: false,
                    text: 'Values',
                },
                grid: {
                    display: false,
                },
                border: {
                    display: false,
                },
                beginAtZero: true,
                ticks: {
                    display: false,
                    font: FONT,
                    color: '#494949',

                    callback: function (value) {
                        return value.toLocaleString()
                    },
                },
            },
        },
        elements: {
            bar: {
                borderRadius: 10,
            },
        },
        // tension: 0,
        maintainAspectRatio: false,
    }
    const data = {
        labels,
        datasets: values.map((value, index) => ({
            label: 'This Week',
            data: value,
            backgroundColor: COLORS[index],
            borderColor: '#ffffff',
            borderWidth: 3,
            borderRadius: 10,
        })),
        //     [
        //     {
        //         label: 'This Week',
        //         data: [10, 20, 30, 40, 50, 60, 70],
        //         backgroundColor: COLORS[0],
        //         borderColor: '#ffffff',
        //         borderWidth: 3,
        //         borderRadius: 10,
        //     },
        //     {
        //         label: 'Last Week',
        //         data: [15, 25, 35, 45, 55, 65, 75],
        //         backgroundColor: COLORS[1],
        //         borderColor: '#ffffff',
        //         borderWidth: 3,
        //         borderRadius: 10,
        //     },
        //     {
        //         label: 'Cost',
        //         data: [20, 30, 40, 50, 60, 70, 80],
        //         backgroundColor: COLORS[2],
        //         borderColor: '#ffffff',
        //         borderWidth: 3,
        //         borderRadius: 10,
        //     },
        // ],
    }
    return <Bar options={options} data={data} {...rest} />
}

export default StackedBarChart
