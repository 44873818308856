import type { Dispatch, FC, SetStateAction } from 'react'
import React, { useState } from 'react'

import Step1 from './components/Step1'
import Step2 from './components/Step2'
import Step3 from './components/Step3'

export enum SignUpSteps {
    ONE = 1,
    TWO = 2,
    THREE = 3,
}

export interface StepsProps {
    setStep: Dispatch<SetStateAction<SignUpSteps>>
    formData: {
        code?: number
        email?: string
        password?: string
    }
    setFormData: (name: keyof StepsProps['formData'], value: any) => void
}

const StepsComponentMap: Record<SignUpSteps, FC<StepsProps>> = {
    [SignUpSteps.ONE]: Step1,
    [SignUpSteps.TWO]: Step2,
    [SignUpSteps.THREE]: Step3,
}

const ForgotPasswordPage: FC = () => {
    const [step, setStep] = useState<SignUpSteps>(SignUpSteps.ONE)
    const [formData, setFormData] = useState({
        code: undefined,
        email: undefined,
        password: undefined,
    })

    const handleFormDataChange = (name: keyof typeof formData, value: any) => {
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }))
    }
    const ComponentToRender = StepsComponentMap[step]
    return (
        <ComponentToRender
            setStep={setStep}
            formData={formData}
            setFormData={handleFormDataChange}
        />
    )
}
export default ForgotPasswordPage
