/* eslint-disable jsx-a11y/anchor-is-valid */
import userImage from '../../../assets/img/user-1.png'

const CustomerPage = () => {
    return (
        <main className="content flex flex-col">
            <div className="px-container flex flex-col flex-auto">
                <div className="card-user-info mb-18">
                    <div className="card-user-info__item flex items-center gap-18">
                        <div className="user-photo-60x60">
                            <img src={userImage} alt="user" />
                        </div>

                        <div className="flex flex-col">
                            <h5 className="mb-4px">Annette Black</h5>
                            <span className="text--xs text-color-1">
                                debra.holt@example.com
                            </span>
                            <span className="text--xs text-color-1">
                                (480) 555-0103
                            </span>
                        </div>
                    </div>
                    <div className="card-user-info__item flex flex-col items-center">
                        <h2 className="mb-10">5</h2>
                        <span className="text--xs text-color-1">Services</span>
                    </div>
                    <div className="card-user-info__item flex flex-col items-center">
                        <h2 className="mb-10">2024-06-07</h2>
                        <span className="text--xs text-color-1">
                            Last Service
                        </span>
                    </div>
                    <div className="card-user-info__item flex flex-col items-center">
                        <h2 className="mb-10">Positive</h2>
                        <span className="text--xs text-color-1">Feedback</span>
                    </div>

                    <div className="dropdown ml-auto" data-dropdown="dropdown">
                        <a
                            href="#"
                            data-role="button"
                            className="btn btn--sm btn--outline-primary fullRadius gap-6"
                        >
                            <span className="text--xs">Actions</span>
                            <div className="ico ico-profile-arrow-drp">
                                <svg
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g>
                                        <path
                                            d="M11.375 5.25L7 9.625L2.625 5.25"
                                            stroke="currentColor"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath>
                                            <rect
                                                width="14"
                                                height="14"
                                                fill="currentColor"
                                            />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                        </a>
                        <div
                            className="dropdown__body"
                            data-role="dropdown"
                        ></div>
                    </div>
                </div>
                <div className="table-group flex flex-col">
                    <div className="table-filter-actions">
                        <a
                            href=""
                            className="btn full-radius btn--xs btn-tab-custom active-tab"
                        >
                            <span>Service History</span>
                        </a>
                        <a
                            href=""
                            className="btn full-radius btn--xs btn-tab-custom"
                        >
                            <span>Upcoming Appointments</span>
                        </a>
                        <a
                            href=""
                            className="btn full-radius btn--xs btn-tab-custom "
                        >
                            <span>Feedback & Ratings</span>
                        </a>
                    </div>
                    <div className="calendar-header">
                        <div className="flex items-center gap-20">
                            <div className="c-h-item">
                                <div className="calendar-title">
                                    <div id="title">Mar, 2024</div>
                                    <span className="ico">
                                        <svg
                                            width="18"
                                            height="18"
                                            viewBox="0 0 18 18"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M6.50008 0.833252V2.49992H11.5001V0.833252H13.1667V2.49992H16.5001C16.7211 2.49992 16.9331 2.58772 17.0893 2.744C17.2456 2.90028 17.3334 3.11224 17.3334 3.33325V16.6666C17.3334 16.8876 17.2456 17.0996 17.0893 17.2558C16.9331 17.4121 16.7211 17.4999 16.5001 17.4999H1.50008C1.27907 17.4999 1.06711 17.4121 0.910826 17.2558C0.754545 17.0996 0.666748 16.8876 0.666748 16.6666V3.33325C0.666748 3.11224 0.754545 2.90028 0.910826 2.744C1.06711 2.58772 1.27907 2.49992 1.50008 2.49992H4.83341V0.833252H6.50008ZM15.6667 9.16658H2.33341V15.8333H15.6667V9.16658ZM4.83341 4.16659H2.33341V7.49992H15.6667V4.16659H13.1667V5.83325H11.5001V4.16659H6.50008V5.83325H4.83341V4.16659Z"
                                                fill="currentColor"
                                            ></path>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <div className="flex items-center gap-8">
                                <a
                                    className="btn btn--navigation btn--square btn--xs rounded-full"
                                    href="#"
                                >
                                    <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M4.16602 9.99992H15.8327"
                                            stroke="#7D8491"
                                            stroke-width="1.2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        ></path>
                                        <path
                                            d="M8.33268 5.83325L4.16602 9.99992"
                                            stroke="#7D8491"
                                            stroke-width="1.2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        ></path>
                                        <path
                                            d="M8.33268 14.1667L4.16602 10"
                                            stroke="#7D8491"
                                            stroke-width="1.2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        ></path>
                                    </svg>
                                </a>
                                <a
                                    className="btn btn--navigation btn--square btn--xs rounded-full"
                                    href="#"
                                >
                                    <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M15.8327 9.99992H4.16602"
                                            stroke="#7D8491"
                                            stroke-width="1.2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        ></path>
                                        <path
                                            d="M11.666 14.1667L15.8327 10"
                                            stroke="#7D8491"
                                            stroke-width="1.2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        ></path>
                                        <path
                                            d="M11.666 5.83325L15.8327 9.99992"
                                            stroke="#7D8491"
                                            stroke-width="1.2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        ></path>
                                    </svg>
                                </a>
                            </div>
                        </div>
                        <div className="c-h-item">
                            <div className="calendar-mode" id="mode-days">
                                <label className="calendar-mode-item">
                                    <input
                                        type="radio"
                                        name="mode-view-3"
                                        checked
                                        hidden
                                        id="resourceTimelineWeek"
                                    />
                                    <div className="calendar-mode-btn btn">
                                        Week{' '}
                                    </div>
                                </label>
                                <label className="calendar-mode-item">
                                    <input
                                        type="radio"
                                        name="mode-view-3"
                                        hidden
                                        id="resourceTimelineMonth"
                                    />
                                    <div className="calendar-mode-btn btn">
                                        Month
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="table-body table-responsive p-0 max-h-40vh">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Service</th>
                                    <th style={{ width: 250 }}>Address</th>
                                    <th>Technician</th>
                                    <th>Rate</th>
                                    <th style={{ width: 10 }}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Mar 10, 2024</td>
                                    <td>Installation of some thing </td>
                                    <td className="text--12 text-color-1 text-wrap">
                                        2118 Thornridge Cir. Syracuse,
                                        Connecticut 35624
                                    </td>
                                    <td>
                                        <div className="flex items-center gap-8">
                                            <div className="user-28x28">
                                                <img
                                                    src="/src/assets/img/user-1.png"
                                                    alt="user"
                                                />
                                            </div>
                                            <span className="text--xs fw-500">
                                                Robert Fox
                                            </span>
                                        </div>
                                    </td>

                                    <td>
                                        <div className="flex items-center gap-6">
                                            <div className="flex">
                                                <img
                                                    src="/src/assets/img/star-12x12.svg"
                                                    alt="star"
                                                />
                                            </div>
                                            <span>4,5</span>
                                        </div>
                                    </td>

                                    <td className="text-right sticky-end">
                                        <div
                                            className="dropdown flex flex-col"
                                            data-position="top-end"
                                            data-dropdown="dropdown"
                                        >
                                            <a
                                                href="#"
                                                className="btn dropdown-btn"
                                                data-role="button"
                                            >
                                                <span className="ico">
                                                    <img
                                                        src="/src/assets/img/dots.svg"
                                                        alt="dots"
                                                    />
                                                </span>
                                            </a>
                                            <div
                                                className="dropdown__body dropdown-md"
                                                data-role="dropdown"
                                            >
                                                <ul className="dropdown-list">
                                                    <li>
                                                        <a
                                                            href="#"
                                                            className="btn size--sm btn-link-drp"
                                                        >
                                                            <span>
                                                                Show Item
                                                            </span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default CustomerPage
