import mapboxgl from 'mapbox-gl'
import React, { useEffect, useRef } from 'react'
import 'mapbox-gl/dist/mapbox-gl.css'

import markerIcon from '../../assets/img/bxs-map.png'
mapboxgl.accessToken =
    'pk.eyJ1IjoibWF4aW1va29semluIiwiYSI6ImNsdGU5OW1lMzBlOTQyaXF1OHQ3a3FyZzQifQ.iHTE8f0GCRZQyZiAX8rfBw'

interface Marker {
    lngLat: [number, number]
    title: string
    description: string
}

const MapBox = () => {
    const mapContainerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (!mapContainerRef.current) return
        const map = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: 'mapbox://styles/maximokolzin/clxd74ktr02dc01pnb0ra8sd0',
            center: [-0.15591514, 51.51830379],
            zoom: 15.5,
        })

        const baseLng = -0.15591514
        const baseLat = 51.51830379

        function getRandomCoordinate(base: number, range: number) {
            return base + (Math.random() - 0.5) * range
        }

        const markers: Marker[] = Array.from({ length: 10 }, (_, i) => ({
            lngLat: [
                getRandomCoordinate(baseLng, 0.001),
                getRandomCoordinate(baseLat, 0.001),
            ],
            title: `Marker ${i + 1}`,
            description: `Description ${i + 1}`,
        }))

        markers.forEach((markerData) => {
            const el = document.createElement('div')
            el.innerHTML = `<img src="${markerIcon}" alt="">`
            new mapboxgl.Marker(el)
                .setLngLat(markerData.lngLat)
                .setPopup(
                    new mapboxgl.Popup({ offset: 25 }).setHTML(
                        `<h3>${markerData.title}</h3><p>${markerData.description}</p>`,
                    ),
                )
                .addTo(map)
        })

        return () => map.remove()
    }, [])

    return (
        <div ref={mapContainerRef} style={{ width: '100%', height: '100vh' }} />
    )
}

export default MapBox
