import React from 'react'
import { Provider as ReduxStoreProvider } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { HistoryRouter } from 'redux-first-history/rr6'

import AuthLayout from './layouts/AuthLayout'
import DispatcherLayout from './layouts/DispatcherLayout'
import ExecutivesLayout from './layouts/ExecutivesLayout'
import { TenantAdminLayout } from './layouts/TenantAdminLayout'
import ChatPage from './pages/ChatPage'
import { CustomerManagementPage } from './pages/Dispatcher/CustomerManagementPage'
import CustomerPage from './pages/Dispatcher/CustomerPage'
import DashboardPage from './pages/Dispatcher/DashboardPage'
import NotificationManagerPage from './pages/Dispatcher/NotificationManagerPage'
import ReportsPage from './pages/Dispatcher/ReportsPage'
import ResourceSchedulingPage from './pages/Dispatcher/ResourceSchedulingPage'
import { Settings } from './pages/Dispatcher/Settings'
import TechnicianManagementPage from './pages/Dispatcher/TechnicianManagementPage'
import TechnicianPage from './pages/Dispatcher/TechnicianPage'
import ForgotPasswordPage from './pages/ForgotPasswordPage'
import LoginPage from './pages/LoginPage'
import { AdminDashboard } from './pages/TenantAdmin/components/AdminDashboard'
import { AdminServiceManagement } from './pages/TenantAdmin/components/AdminServiceManagement'
import { AdminTeamManagement } from './pages/TenantAdmin/components/AdminTeamMenagment'
import { AdminUserManagement } from './pages/TenantAdmin/components/AdminUserManagement'
import { AdminWorkGroupPage } from './pages/TenantAdmin/components/AdminWorkGroup/AdminWorkGroupPage'
import { AdminWorkGroupManagement } from './pages/TenantAdmin/components/AdminWorkGroupManagement'
import { history, store } from './store'

import './assets/css/custom.css'

import 'react-toastify/dist/ReactToastify.css'
import ExecutivesDashboardPage from './pages/Executives/ExecutivesDashboardPage'
import ExecutivesPerformanceReportsPage from './pages/Executives/ExecutivesPerformanceReportsPage'
import ExecutivesRevenueAnalysisPage from './pages/Executives/ExecutivesRevenueAnalysisPage/ExecutivesRevenueAnalysisPage'
import ExecutivesWorkOrderKpisPage from './pages/Executives/ExecutivesWorkOrderKpisPage'
import ExecutivesTechnicianPerformancePage from './pages/Executives/ExecutivesTechnicianPerformancePage'
import ExecutivesCustomerFeedbackPage from './pages/Executives/ExecutivesCustomerFeedbackPage'

const App: React.FC = () => {
    return (
        <ReduxStoreProvider store={store}>
            <HistoryRouter history={history}>
                <Routes>
                    <Route element={<AuthLayout />}>
                        <Route path="/login" element={<LoginPage />} />
                        <Route
                            path="/forgot-password"
                            element={<ForgotPasswordPage />}
                        />
                    </Route>

                    <Route element={<DispatcherLayout />}>
                        <Route path="/" element={<DashboardPage />} />
                        <Route
                            path="/dispatcher-resource-sheduling"
                            element={<ResourceSchedulingPage />}
                        />
                        <Route path="/dispatcher-technician-management">
                            <Route
                                index
                                element={<TechnicianManagementPage />}
                            />
                            <Route path=":id" element={<TechnicianPage />} />
                        </Route>
                        <Route path="/dispatcher-customer-management">
                            <Route index element={<CustomerManagementPage />} />
                            <Route path=":id" element={<CustomerPage />} />
                        </Route>
                        <Route path="/dispatcher-chat" element={<ChatPage />} />
                        <Route
                            path="/dispatcher-chat/:id"
                            element={<ChatPage />}
                        />
                        <Route
                            path="/dispatcher-reports"
                            element={<ReportsPage />}
                        />
                        <Route
                            path="/dispatcher-notification-management"
                            element={<NotificationManagerPage />}
                        />
                        <Route path="/settings" element={<Settings />} />
                    </Route>

                    <Route element={<TenantAdminLayout />}>
                        <Route
                            path="/tenant-admin-dashboard"
                            element={<AdminDashboard />}
                        />
                        <Route
                            path="/tenant-admin-service-management"
                            element={<AdminServiceManagement />}
                        />
                        <Route
                            path="/tenant-admin-user-management"
                            element={<AdminUserManagement />}
                        />
                        <Route
                            path="/tenant-admin-team-management"
                            element={<AdminTeamManagement />}
                        />
                        <Route
                            path="/tenant-admin-workgroup-management"
                            element={<AdminWorkGroupManagement />}
                        />
                        <Route
                            path="/tenant-admin-workgroup-page"
                            element={<AdminWorkGroupPage />}
                        />
                        <Route
                            path="/tenant-admin-chat"
                            element={<ChatPage />}
                        />
                        <Route
                            path="/tenant-admin-chat/:id"
                            element={<ChatPage />}
                        />
                        <Route
                            path="/tenant-admin-settings"
                            element={<Settings />}
                        />
                    </Route>

                    <Route element={<ExecutivesLayout />}>
                        <Route
                            path="/executives-dashboard"
                            element={<ExecutivesDashboardPage />}
                        />
                        <Route
                            path="/executives-performance-reports"
                            element={<ExecutivesPerformanceReportsPage />}
                        />
                        <Route
                            path="/executives-revenue-analysis"
                            element={<ExecutivesRevenueAnalysisPage />}
                        />
                        <Route
                            path="/executives-customer-feedback"
                            element={<ExecutivesCustomerFeedbackPage />}
                        />
                        <Route
                            path="/executives-work-order-kpis"
                            element={<ExecutivesWorkOrderKpisPage />}
                        />
                        <Route
                            path="/executives-technician-performance"
                            element={<ExecutivesTechnicianPerformancePage />}
                        />
                        <Route path="/executives-chat" element={<ChatPage />} />
                        <Route
                            path="/executives-chat/:id"
                            element={<ChatPage />}
                        />
                        <Route
                            path="/executives-settings"
                            element={<Settings />}
                        />
                    </Route>

                    <Route path="*" element={<h1>Not found</h1>} />
                </Routes>
            </HistoryRouter>
            <ToastContainer />
        </ReduxStoreProvider>
    )
}

export default App
