import User from '../../../../assets/img/user-photo-3.png'
import Avatar from '../../../../assets/img/user-1.png'
import DotsSvg from '../../../../assets/img/dots.svg'
import { useState } from 'react'
import { WorkingSchedule } from './WorkingSchedule'

export const AdminWorkGroupPage = () => {
    const tabs = [
        {
            name: 'Working Schedule',
            id: 1,
        },
        {
            name: 'Task List',
            id: 2,
        },
        {
            name: 'Team List',
            id: 3,
        },
        {
            name: 'Statistics',
            id: 4,
        },
    ]

    const [activeTab, setActiveTab] = useState(tabs[0].id)

    return (
        <main className="content flex flex-col">
            <div className="px-container flex flex-col flex-auto">
                <div className="card-user-info mb-18">
                    <div className="card-user-info__item flex items-center gap-18">
                        <div className="user-photo-60x60">
                            <img src={User} alt={''} />
                        </div>

                        <div className="flex flex-col">
                            <span className="text--xs text-color-1">
                                Team Lead
                            </span>
                            <h5 className="mb-4px">Annette Black</h5>
                        </div>
                    </div>
                    <div className="user-list-group">
                        <ul className="user-list">
                            <li>
                                <div className="user-28x28">
                                    <img src={Avatar} alt={''} />
                                </div>
                            </li>
                            <li>
                                <div className="user-28x28">
                                    <img src={Avatar} alt={''} />
                                </div>
                            </li>
                            <li>
                                <div className="user-28x28">
                                    <img src={Avatar} alt={''} />
                                </div>
                            </li>
                            <li>
                                <div className="user-28x28">
                                    <img src={Avatar} alt={''} />
                                </div>
                            </li>
                            <li>
                                <div className="user-28x28">
                                    <img src={Avatar} alt={''} />
                                </div>
                            </li>
                        </ul>
                        <span className="user-cunter">+3</span>
                    </div>

                    <div className="dropdown ml-auto" data-dropdown="dropdown">
                        <a
                            href="#"
                            data-role="button"
                            className="btn btn--sm btn--outline-primary fullRadius gap-6"
                        >
                            <span className="text--xs">Actions</span>
                            <div className="ico ico-profile-arrow-drp">
                                <svg
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g>
                                        <path
                                            d="M11.375 5.25L7 9.625L2.625 5.25"
                                            stroke="currentColor"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        ></path>
                                    </g>
                                    <defs>
                                        <clipPath>
                                            <rect
                                                width="14"
                                                height="14"
                                                fill="currentColor"
                                            ></rect>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                        </a>
                        <div
                            className="dropdown__body"
                            data-role="dropdown"
                        ></div>
                    </div>
                </div>
                <div className="table-group tabs flex flex-col flex-auto">
                    <div className="table-filter-actions tabs-links-list modal__tabs__body">
                        {tabs &&
                            tabs.map((el) => (
                                <button
                                    data-tab-link=""
                                    onClick={() => setActiveTab(el.id)}
                                    className={`btn full-radius btn--xs btn-tab-custom ${activeTab === el.id ? 'active-tab' : ''}`}
                                >
                                    <span>{el.name}</span>
                                </button>
                            ))}
                    </div>

                    <div className="tabs-body flex-auto">
                        <WorkingSchedule />
                        {/*<div className="tab flex-auto" data-id="task-list">*/}
                        {/*    <div className="calendar-header">*/}
                        {/*        <div className="flex items-center gap-20">*/}
                        {/*            <div className="c-h-item">*/}
                        {/*                <div className="calendar-title">*/}
                        {/*                    <div>Mar, 2024</div>*/}
                        {/*                    <span className="ico">*/}
                        {/*                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                        {/*                        <path d="M6.50008 0.833252V2.49992H11.5001V0.833252H13.1667V2.49992H16.5001C16.7211 2.49992 16.9331 2.58772 17.0893 2.744C17.2456 2.90028 17.3334 3.11224 17.3334 3.33325V16.6666C17.3334 16.8876 17.2456 17.0996 17.0893 17.2558C16.9331 17.4121 16.7211 17.4999 16.5001 17.4999H1.50008C1.27907 17.4999 1.06711 17.4121 0.910826 17.2558C0.754545 17.0996 0.666748 16.8876 0.666748 16.6666V3.33325C0.666748 3.11224 0.754545 2.90028 0.910826 2.744C1.06711 2.58772 1.27907 2.49992 1.50008 2.49992H4.83341V0.833252H6.50008ZM15.6667 9.16658H2.33341V15.8333H15.6667V9.16658ZM4.83341 4.16659H2.33341V7.49992H15.6667V4.16659H13.1667V5.83325H11.5001V4.16659H6.50008V5.83325H4.83341V4.16659Z" fill="currentColor"></path>*/}
                        {/*                    </svg>*/}
                        {/*                </span>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <div className="flex items-center gap-8">*/}
                        {/*                <a className="btn btn--navigation btn--square btn--xs rounded-full" href="#">*/}
                        {/*                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                        {/*                        <path d="M4.16602 9.99992H15.8327" stroke="#7D8491" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path>*/}
                        {/*                        <path d="M8.33268 5.83325L4.16602 9.99992" stroke="#7D8491" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path>*/}
                        {/*                        <path d="M8.33268 14.1667L4.16602 10" stroke="#7D8491" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path>*/}
                        {/*                    </svg>*/}
                        {/*                </a>*/}
                        {/*                <a className="btn btn--navigation btn--square btn--xs rounded-full" href="#">*/}
                        {/*                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                        {/*                        <path d="M15.8327 9.99992H4.16602" stroke="#7D8491" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path>*/}
                        {/*                        <path d="M11.666 14.1667L15.8327 10" stroke="#7D8491" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path>*/}
                        {/*                        <path d="M11.666 5.83325L15.8327 9.99992" stroke="#7D8491" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path>*/}
                        {/*                    </svg>*/}
                        {/*                </a>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*        <div className="c-h-item">*/}
                        {/*            <label className="calendar-mode" id="mode-days">*/}
                        {/*                <label className="calendar-mode-item">*/}
                        {/*                    <input type="radio" name="mode-week" checked="" hidden="">*/}
                        {/*                        <div className="calendar-mode-btn btn">Day</div>*/}
                        {/*                </label>*/}
                        {/*                <label className="calendar-mode-item">*/}
                        {/*                    <input type="radio" name="mode-week" hidden="">*/}
                        {/*                        <div className="calendar-mode-btn btn">Month</div>*/}
                        {/*                </label>*/}

                        {/*            </label>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div className="table-body table-responsive p-0 min-h-300">*/}
                        {/*        <table className="table">*/}
                        {/*            <thead>*/}
                        {/*            <tr>*/}
                        {/*                <th>Date</th>*/}
                        {/*                <th>Time</th>*/}
                        {/*                <th>Task</th>*/}
                        {/*                <th style={{width: '250px'}}>Address</th>*/}
                        {/*                <th>Client</th>*/}
                        {/*                <th>Status</th>*/}
                        {/*                <th style={{width: '10px'}}>Actions</th>*/}
                        {/*            </tr>*/}
                        {/*            </thead>*/}
                        {/*            <tbody>*/}
                        {/*            <tr>*/}
                        {/*                <td>Mar 10, 2024</td>*/}
                        {/*                <td>12:00 AM</td>*/}
                        {/*                <td>Installation of some thing </td>*/}
                        {/*                <td className="text--12 text-color-1 text-wrap">2118 Thornridge Cir. Syracuse, Connecticut 35624</td>*/}
                        {/*                <td>*/}
                        {/*                    <div className="flex items-center gap-8">*/}
                        {/*                        <div className="user-28x28">*/}
                        {/*                            <img src={User} alt={''} />*/}
                        {/*                        </div>*/}
                        {/*                        <span className="text--xs fw-500">Robert Fox</span>*/}
                        {/*                    </div>*/}
                        {/*                </td>*/}

                        {/*                <td>*/}
                        {/*                    <span className="pill pill-2 pill-warning">Pending</span>*/}
                        {/*                </td>*/}

                        {/*                <td className="text-right sticky-end">*/}
                        {/*                    <div className="dropdown flex flex-col" data-position="top-end" data-dropdown="dropdown">*/}
                        {/*                        <a href="#" className="btn dropdown-btn" data-role="button">*/}
                        {/*                                <span className="ico">*/}
                        {/*                                    <img src="img/dots.svg">*/}
                        {/*                                </span>*/}
                        {/*                        </a>*/}
                        {/*                        <div className="dropdown__body dropdown-md" data-role="dropdown">*/}
                        {/*                            <ul className="dropdown-list">*/}
                        {/*                                <li>*/}
                        {/*                                    <a href="#" className="btn size--sm btn-link-drp">*/}
                        {/*                                        <span>Show Item</span>*/}
                        {/*                                    </a>*/}
                        {/*                                </li>*/}
                        {/*                            </ul>*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                </td>*/}
                        {/*            </tr>*/}
                        {/*            <tr>*/}
                        {/*                <td>Mar 10, 2024</td>*/}
                        {/*                <td>12:00 AM</td>*/}
                        {/*                <td>Installation of some thing </td>*/}
                        {/*                <td className="text--12 text-color-1 text-wrap">2118 Thornridge Cir. Syracuse, Connecticut 35624</td>*/}
                        {/*                <td>*/}
                        {/*                    <div className="flex items-center gap-8">*/}
                        {/*                        <div className="user-28x28">*/}
                        {/*                            <img src="img/user-1.png">*/}
                        {/*                        </div>*/}
                        {/*                        <span className="text--xs fw-500">Robert Fox</span>*/}
                        {/*                    </div>*/}
                        {/*                </td>*/}

                        {/*                <td>*/}
                        {/*                    <span className="pill pill-2 pill-warning">Pending</span>*/}
                        {/*                </td>*/}

                        {/*                <td className="text-right sticky-end">*/}
                        {/*                    <div className="dropdown flex flex-col" data-position="top-end" data-dropdown="dropdown">*/}
                        {/*                        <a href="#" className="btn dropdown-btn" data-role="button">*/}
                        {/*                                <span className="ico">*/}
                        {/*                                    <img src="img/dots.svg">*/}
                        {/*                                </span>*/}
                        {/*                        </a>*/}
                        {/*                        <div className="dropdown__body dropdown-md" data-role="dropdown">*/}
                        {/*                            <ul className="dropdown-list">*/}
                        {/*                                <li>*/}
                        {/*                                    <a href="#" className="btn size--sm btn-link-drp">*/}
                        {/*                                        <span>Show Item</span>*/}
                        {/*                                    </a>*/}
                        {/*                                </li>*/}
                        {/*                            </ul>*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                </td>*/}
                        {/*            </tr>*/}
                        {/*            <tr>*/}
                        {/*                <td>Mar 10, 2024</td>*/}
                        {/*                <td>12:00 AM</td>*/}
                        {/*                <td>Installation of some thing </td>*/}
                        {/*                <td className="text--12 text-color-1 text-wrap">2118 Thornridge Cir. Syracuse, Connecticut 35624</td>*/}
                        {/*                <td>*/}
                        {/*                    <div className="flex items-center gap-8">*/}
                        {/*                        <div className="user-28x28">*/}
                        {/*                            <img src="img/user-1.png">*/}
                        {/*                        </div>*/}
                        {/*                        <span className="text--xs fw-500">Robert Fox</span>*/}
                        {/*                    </div>*/}
                        {/*                </td>*/}

                        {/*                <td>*/}
                        {/*                    <span className="pill pill-2 pill-warning">Pending</span>*/}
                        {/*                </td>*/}

                        {/*                <td className="text-right sticky-end">*/}
                        {/*                    <div className="dropdown flex flex-col" data-position="top-end" data-dropdown="dropdown">*/}
                        {/*                        <a href="#" className="btn dropdown-btn" data-role="button">*/}
                        {/*                                <span className="ico">*/}
                        {/*                                    <img src="img/dots.svg">*/}
                        {/*                                </span>*/}
                        {/*                        </a>*/}
                        {/*                        <div className="dropdown__body dropdown-md" data-role="dropdown">*/}
                        {/*                            <ul className="dropdown-list">*/}
                        {/*                                <li>*/}
                        {/*                                    <a href="#" className="btn size--sm btn-link-drp">*/}
                        {/*                                        <span>Show Item</span>*/}
                        {/*                                    </a>*/}
                        {/*                                </li>*/}
                        {/*                            </ul>*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                </td>*/}
                        {/*            </tr>*/}
                        {/*            <tr>*/}
                        {/*                <td>Mar 10, 2024</td>*/}
                        {/*                <td>12:00 AM</td>*/}
                        {/*                <td><span className="fw-500">Installation of some thing</span></td>*/}
                        {/*                <td className="text--12 text-color-1 text-wrap">2118 Thornridge Cir. Syracuse, Connecticut 35624</td>*/}
                        {/*                <td>*/}
                        {/*                    <div className="flex items-center gap-8">*/}
                        {/*                        <div className="user-28x28">*/}
                        {/*                            <img src="img/user-1.png">*/}
                        {/*                        </div>*/}
                        {/*                        <span className="text--xs fw-500">Robert Fox</span>*/}
                        {/*                    </div>*/}
                        {/*                </td>*/}

                        {/*                <td>*/}
                        {/*                    <span className="pill pill-2 pill-success">Completed</span>*/}
                        {/*                </td>*/}

                        {/*                <td className="text-right sticky-end">*/}
                        {/*                    <div className="dropdown flex flex-col" data-position="top-end" data-dropdown="dropdown">*/}
                        {/*                        <a href="#" className="btn dropdown-btn" data-role="button">*/}
                        {/*                            <span className="ico">*/}
                        {/*                                <img src="img/dots.svg">*/}
                        {/*                            </span>*/}
                        {/*                        </a>*/}
                        {/*                        <div className="dropdown__body dropdown-md" data-role="dropdown">*/}
                        {/*                            <ul className="dropdown-list">*/}
                        {/*                                <li>*/}
                        {/*                                    <a href="#" className="btn size--sm btn-link-drp">*/}
                        {/*                                        <span>Show Item</span>*/}
                        {/*                                    </a>*/}
                        {/*                                </li>*/}
                        {/*                            </ul>*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                </td>*/}
                        {/*            </tr>*/}
                        {/*            </tbody>*/}
                        {/*        </table>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="tab flex-auto" data-id="team-list">*/}
                        {/*    <div className="table-body table-responsive p-0 min-h-300">*/}
                        {/*        <table className="table">*/}
                        {/*            <thead>*/}
                        {/*            <tr>*/}
                        {/*                <th>Team</th>*/}
                        {/*                <th>Team Lead</th>*/}
                        {/*                <th>Projects</th>*/}
                        {/*                <th>Members</th>*/}
                        {/*                <th>Status</th>*/}
                        {/*                <th style="width: 10px;">Actions</th>*/}
                        {/*            </tr>*/}
                        {/*            </thead>*/}
                        {/*            <tbody>*/}
                        {/*            <tr>*/}
                        {/*                <td>WorkGroup Name 1</td>*/}
                        {/*                <td>*/}
                        {/*                    <div className="flex items-center gap-8">*/}
                        {/*                        <div className="user-28x28">*/}
                        {/*                            <img src="img/user-1.png">*/}
                        {/*                        </div>*/}
                        {/*                        <div className="flex flex-col">*/}
                        {/*                            <span className="text--xs fw-500">Floyd Miles</span>*/}
                        {/*                            <span className="text--12 text-color-1">tanya.hill@example.com</span>*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                </td>*/}
                        {/*                <td>*/}
                        {/*                    <div className="user-list-group">*/}
                        {/*                        <ul className="user-list">*/}
                        {/*                            <li>*/}
                        {/*                                <div className="user-28x28">*/}
                        {/*                                    <img src="img/user-1.png">*/}
                        {/*                                </div>*/}
                        {/*                            </li>*/}
                        {/*                            <li>*/}
                        {/*                                <div className="user-28x28">*/}
                        {/*                                    <img src="img/user-1.png">*/}
                        {/*                                </div>*/}
                        {/*                            </li>*/}
                        {/*                            <li>*/}
                        {/*                                <div className="user-28x28">*/}
                        {/*                                    <img src="img/user-1.png">*/}
                        {/*                                </div>*/}
                        {/*                            </li>*/}
                        {/*                            <li>*/}
                        {/*                                <div className="user-28x28">*/}
                        {/*                                    <img src="img/user-1.png">*/}
                        {/*                                </div>*/}
                        {/*                            </li>*/}
                        {/*                            <li>*/}
                        {/*                                <div className="user-28x28">*/}
                        {/*                                    <img src="img/user-1.png">*/}
                        {/*                                </div>*/}
                        {/*                            </li>*/}
                        {/*                        </ul>*/}
                        {/*                        <span className="user-cunter">+3</span>*/}
                        {/*                    </div>*/}
                        {/*                </td>*/}
                        {/*                <td>10 projects</td>*/}
                        {/*                <td>*/}
                        {/*                    <span className="pill pill-2 pill-warning">Pending</span>*/}
                        {/*                </td>*/}

                        {/*                <td className="text-right sticky-end">*/}
                        {/*                    <div className="dropdown flex flex-col" data-position="top-end" data-dropdown="dropdown">*/}
                        {/*                        <a href="#" className="btn dropdown-btn" data-role="button">*/}
                        {/*                                <span className="ico">*/}
                        {/*                                    <img src="img/dots.svg">*/}
                        {/*                                </span>*/}
                        {/*                        </a>*/}
                        {/*                        <div className="dropdown__body dropdown-md" data-role="dropdown">*/}
                        {/*                            <ul className="dropdown-list">*/}
                        {/*                                <li>*/}
                        {/*                                    <a href="#" className="btn size--sm btn-link-drp">*/}
                        {/*                                        <span>Show Item</span>*/}
                        {/*                                    </a>*/}
                        {/*                                </li>*/}
                        {/*                            </ul>*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                </td>*/}
                        {/*            </tr>*/}
                        {/*            <tr>*/}
                        {/*                <td>WorkGroup Name 1</td>*/}
                        {/*                <td>*/}
                        {/*                    <div className="flex items-center gap-8">*/}
                        {/*                        <div className="user-28x28">*/}
                        {/*                            <img src="img/user-1.png">*/}
                        {/*                        </div>*/}
                        {/*                        <div className="flex flex-col">*/}
                        {/*                            <span className="text--xs fw-500">Floyd Miles</span>*/}
                        {/*                            <span className="text--12 text-color-1">tanya.hill@example.com</span>*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                </td>*/}
                        {/*                <td>*/}
                        {/*                    <div className="user-list-group">*/}
                        {/*                        <ul className="user-list">*/}
                        {/*                            <li>*/}
                        {/*                                <div className="user-28x28">*/}
                        {/*                                    <img src="img/user-1.png">*/}
                        {/*                                </div>*/}
                        {/*                            </li>*/}
                        {/*                            <li>*/}
                        {/*                                <div className="user-28x28">*/}
                        {/*                                    <img src="img/user-1.png">*/}
                        {/*                                </div>*/}
                        {/*                            </li>*/}
                        {/*                            <li>*/}
                        {/*                                <div className="user-28x28">*/}
                        {/*                                    <img src="img/user-1.png">*/}
                        {/*                                </div>*/}
                        {/*                            </li>*/}
                        {/*                            <li>*/}
                        {/*                                <div className="user-28x28">*/}
                        {/*                                    <img src="img/user-1.png">*/}
                        {/*                                </div>*/}
                        {/*                            </li>*/}
                        {/*                            <li>*/}
                        {/*                                <div className="user-28x28">*/}
                        {/*                                    <img src="img/user-1.png">*/}
                        {/*                                </div>*/}
                        {/*                            </li>*/}
                        {/*                        </ul>*/}
                        {/*                        <span className="user-cunter">+3</span>*/}
                        {/*                    </div>*/}
                        {/*                </td>*/}
                        {/*                <td>10 projects</td>*/}
                        {/*                <td>*/}
                        {/*                    <span className="pill pill-2 pill-warning">Pending</span>*/}
                        {/*                </td>*/}

                        {/*                <td className="text-right sticky-end">*/}
                        {/*                    <div className="dropdown flex flex-col" data-position="top-end" data-dropdown="dropdown">*/}
                        {/*                        <a href="#" className="btn dropdown-btn" data-role="button">*/}
                        {/*                                <span className="ico">*/}
                        {/*                                    <img src="img/dots.svg">*/}
                        {/*                                </span>*/}
                        {/*                        </a>*/}
                        {/*                        <div className="dropdown__body dropdown-md" data-role="dropdown">*/}
                        {/*                            <ul className="dropdown-list">*/}
                        {/*                                <li>*/}
                        {/*                                    <a href="#" className="btn size--sm btn-link-drp">*/}
                        {/*                                        <span>Show Item</span>*/}
                        {/*                                    </a>*/}
                        {/*                                </li>*/}
                        {/*                            </ul>*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                </td>*/}
                        {/*            </tr>*/}
                        {/*            <tr>*/}
                        {/*                <td>WorkGroup Name 1</td>*/}
                        {/*                <td>*/}
                        {/*                    <div className="flex items-center gap-8">*/}
                        {/*                        <div className="user-28x28">*/}
                        {/*                            <img src="img/user-1.png">*/}
                        {/*                        </div>*/}
                        {/*                        <div className="flex flex-col">*/}
                        {/*                            <span className="text--xs fw-500">Floyd Miles</span>*/}
                        {/*                            <span className="text--12 text-color-1">tanya.hill@example.com</span>*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                </td>*/}
                        {/*                <td>*/}
                        {/*                    <div className="user-list-group">*/}
                        {/*                        <ul className="user-list">*/}
                        {/*                            <li>*/}
                        {/*                                <div className="user-28x28">*/}
                        {/*                                    <img src="img/user-1.png">*/}
                        {/*                                </div>*/}
                        {/*                            </li>*/}
                        {/*                            <li>*/}
                        {/*                                <div className="user-28x28">*/}
                        {/*                                    <img src="img/user-1.png">*/}
                        {/*                                </div>*/}
                        {/*                            </li>*/}
                        {/*                            <li>*/}
                        {/*                                <div className="user-28x28">*/}
                        {/*                                    <img src="img/user-1.png">*/}
                        {/*                                </div>*/}
                        {/*                            </li>*/}
                        {/*                            <li>*/}
                        {/*                                <div className="user-28x28">*/}
                        {/*                                    <img src="img/user-1.png">*/}
                        {/*                                </div>*/}
                        {/*                            </li>*/}
                        {/*                            <li>*/}
                        {/*                                <div className="user-28x28">*/}
                        {/*                                    <img src="img/user-1.png">*/}
                        {/*                                </div>*/}
                        {/*                            </li>*/}
                        {/*                        </ul>*/}
                        {/*                        <span className="user-cunter">+3</span>*/}
                        {/*                    </div>*/}
                        {/*                </td>*/}
                        {/*                <td>10 projects</td>*/}
                        {/*                <td>*/}
                        {/*                    <span className="pill pill-2 pill-warning">Pending</span>*/}
                        {/*                </td>*/}

                        {/*                <td className="text-right sticky-end">*/}
                        {/*                    <div className="dropdown flex flex-col" data-position="top-end" data-dropdown="dropdown">*/}
                        {/*                        <a href="#" className="btn dropdown-btn" data-role="button">*/}
                        {/*                                <span className="ico">*/}
                        {/*                                    <img src="img/dots.svg">*/}
                        {/*                                </span>*/}
                        {/*                        </a>*/}
                        {/*                        <div className="dropdown__body dropdown-md" data-role="dropdown">*/}
                        {/*                            <ul className="dropdown-list">*/}
                        {/*                                <li>*/}
                        {/*                                    <a href="#" className="btn size--sm btn-link-drp">*/}
                        {/*                                        <span>Show Item</span>*/}
                        {/*                                    </a>*/}
                        {/*                                </li>*/}
                        {/*                            </ul>*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                </td>*/}
                        {/*            </tr>*/}

                        {/*            </tbody>*/}
                        {/*        </table>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="tab flex-auto" data-id="statistics">*/}
                        {/*    <div className="p-20 border-top">*/}
                        {/*        <div className="row gy-20 gx-16">*/}
                        {/*            <div className="col-12">*/}
                        {/*                <div className="row gy-20 gx-16">*/}
                        {/*                    <div className="col-lg-3 col-md-6 col-12">*/}
                        {/*                        <div className="card card-shadow card-seo">*/}
                        {/*                            <div className="card-body">*/}
                        {/*                                <div className="card-seo-info">*/}
                        {/*                                    <div className="seo-title">*/}
                        {/*                                    <span className="ico">*/}
                        {/*                                        <img src="img/ico-jobs.svg">*/}
                        {/*                                    </span>*/}
                        {/*                                        <span className="name">Jobs</span>*/}
                        {/*                                    </div>*/}
                        {/*                                    <h1 className="seo-stat-title">218</h1>*/}
                        {/*                                </div>*/}
                        {/*                                <div className="card-seo-chart">*/}
                        {/*                                    <div className="fake-chart-canva --up">*/}
                        {/*                                        <svg width="102" height="62" viewBox="0 0 102 62" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                        {/*                                            <path d="M1 57L4 51.5L9.5 43.5L12 47L15 46L17.5 49L20.5 56L23 57L28.5 53.5L31.5 60L34.5 53.5L37 53L40 42.5H42.5L45 44.5L48.5 45L53.5 37.5L56.5 30.5L59.5 26.5L62 28L67.5 20.5L70.5 28.5L73.5 28L78.5 16.5L81.5 17.5L84.5 10.5H87L90 5.5L95 13.5L98 1L101 10.5V60.5H1V57Z" fill="url(#paint0_linear_180_5093)" fill-opacity="0.3"></path>*/}
                        {/*                                            <path d="M1 57.92L3.77778 52.6L6.55556 48.92L9.33333 43.61L12.1111 47.89L14.8889 46.12L17.6667 49.73L20.4444 56.41L23.2222 57.66L26 55.6L28.7778 53.76L31.5556 61L34.3333 53.75L37.1111 53.62L39.8889 43.02L42.6667 43.2L45.4444 45.56L48.2222 45.74L51 41.48L53.7778 37.63L56.5556 30.85L59.3333 26.88L62.1111 28.86L64.8889 24.59L67.6667 20.63L70.4444 29.04L73.2222 28.65L76 23.02L78.7778 16.78L81.5556 18.01L84.3333 10.95L87.1111 11.28L89.8889 5.76L92.6667 10.75L95.4444 14.47L98.2222 1L101 10.83" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>*/}
                        {/*                                            <defs>*/}
                        {/*                                                <linearGradient id="paint0_linear_180_5093" x1="51" y1="1" x2="51" y2="60.5" gradientUnits="userSpaceOnUse">*/}
                        {/*                                                    <stop stop-color="currentColor"></stop>*/}
                        {/*                                                    <stop offset="1" stop-color="currentColor" stop-opacity="0"></stop>*/}
                        {/*                                                </linearGradient>*/}
                        {/*                                            </defs>*/}
                        {/*                                        </svg>*/}
                        {/*                                    </div>*/}
                        {/*                                </div>*/}
                        {/*                            </div>*/}
                        {/*                            <div className="card-footer">*/}
                        {/*                                <div className="seo-rating-row">*/}
                        {/*                                    <div className="seo-rating --up">*/}
                        {/*                                    <span className="ico">*/}
                        {/*                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                        {/*                                            <g>*/}
                        {/*                                                <path d="M10.875 2.625L6.375 7.125L4.5 5.25L1.125 8.625" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>*/}
                        {/*                                                <path d="M10.875 5.625V2.625H7.875" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>*/}
                        {/*                                            </g>*/}
                        {/*                                            <defs>*/}
                        {/*                                                <clipPath>*/}
                        {/*                                                    <rect width="12" height="12" fill="white"></rect>*/}
                        {/*                                                </clipPath>*/}
                        {/*                                            </defs>*/}
                        {/*                                        </svg>*/}
                        {/*                                    </span> <span>+12%</span>*/}
                        {/*                                    </div>*/}
                        {/*                                    <p className="seo-rating-desk"><span>vs</span> yesterday</p>*/}
                        {/*                                </div>*/}
                        {/*                            </div>*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}

                        {/*                    <div className="col-lg-3 col-md-6 col-12">*/}
                        {/*                        <div className="card card-shadow card-seo">*/}
                        {/*                            <div className="card-body">*/}
                        {/*                                <div className="card-seo-info">*/}
                        {/*                                    <div className="seo-title">*/}
                        {/*                                    <span className="ico">*/}
                        {/*                                        <img src="img/ico-open-jobs.svg">*/}
                        {/*                                    </span>*/}
                        {/*                                        <span className="name">Open Jobs</span>*/}
                        {/*                                    </div>*/}
                        {/*                                    <h1 className="seo-stat-title">12</h1>*/}
                        {/*                                </div>*/}
                        {/*                                <div className="card-seo-chart">*/}
                        {/*                                    <div className="fake-chart-canva --up">*/}
                        {/*                                        <svg width="102" height="62" viewBox="0 0 102 62" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                        {/*                                            <path d="M1 57L4 51.5L9.5 43.5L12 47L15 46L17.5 49L20.5 56L23 57L28.5 53.5L31.5 60L34.5 53.5L37 53L40 42.5H42.5L45 44.5L48.5 45L53.5 37.5L56.5 30.5L59.5 26.5L62 28L67.5 20.5L70.5 28.5L73.5 28L78.5 16.5L81.5 17.5L84.5 10.5H87L90 5.5L95 13.5L98 1L101 10.5V60.5H1V57Z" fill="url(#paint0_linear_180_5093)" fill-opacity="0.3"></path>*/}
                        {/*                                            <path d="M1 57.92L3.77778 52.6L6.55556 48.92L9.33333 43.61L12.1111 47.89L14.8889 46.12L17.6667 49.73L20.4444 56.41L23.2222 57.66L26 55.6L28.7778 53.76L31.5556 61L34.3333 53.75L37.1111 53.62L39.8889 43.02L42.6667 43.2L45.4444 45.56L48.2222 45.74L51 41.48L53.7778 37.63L56.5556 30.85L59.3333 26.88L62.1111 28.86L64.8889 24.59L67.6667 20.63L70.4444 29.04L73.2222 28.65L76 23.02L78.7778 16.78L81.5556 18.01L84.3333 10.95L87.1111 11.28L89.8889 5.76L92.6667 10.75L95.4444 14.47L98.2222 1L101 10.83" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>*/}
                        {/*                                            <defs>*/}
                        {/*                                                <linearGradient id="paint0_linear_180_5093" x1="51" y1="1" x2="51" y2="60.5" gradientUnits="userSpaceOnUse">*/}
                        {/*                                                    <stop stop-color="currentColor"></stop>*/}
                        {/*                                                    <stop offset="1" stop-color="currentColor" stop-opacity="0"></stop>*/}
                        {/*                                                </linearGradient>*/}
                        {/*                                            </defs>*/}
                        {/*                                        </svg>*/}
                        {/*                                    </div>*/}
                        {/*                                </div>*/}
                        {/*                            </div>*/}
                        {/*                            <div className="card-footer">*/}
                        {/*                                <div className="seo-rating-row">*/}
                        {/*                                    <div className="seo-rating --up">*/}
                        {/*                                    <span className="ico">*/}
                        {/*                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                        {/*                                            <g>*/}
                        {/*                                                <path d="M10.875 2.625L6.375 7.125L4.5 5.25L1.125 8.625" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>*/}
                        {/*                                                <path d="M10.875 5.625V2.625H7.875" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>*/}
                        {/*                                            </g>*/}
                        {/*                                            <defs>*/}
                        {/*                                                <clipPath>*/}
                        {/*                                                    <rect width="12" height="12" fill="white"></rect>*/}
                        {/*                                                </clipPath>*/}
                        {/*                                            </defs>*/}
                        {/*                                        </svg>*/}
                        {/*                                    </span> <span>+4%</span>*/}
                        {/*                                    </div>*/}
                        {/*                                    <p className="seo-rating-desk"><span>vs</span> yesterday</p>*/}
                        {/*                                </div>*/}
                        {/*                            </div>*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}

                        {/*                    <div className="col-lg-3 col-md-6 col-12">*/}
                        {/*                        <div className="card card-shadow card-seo">*/}
                        {/*                            <div className="card-body">*/}
                        {/*                                <div className="card-seo-info">*/}
                        {/*                                    <div className="seo-title">*/}
                        {/*                                    <span className="ico">*/}
                        {/*                                        <img src="img/ico-city-job.svg">*/}
                        {/*                                    </span>*/}
                        {/*                                        <span className="name">KIV Jobs</span>*/}
                        {/*                                    </div>*/}
                        {/*                                    <h1 className="seo-stat-title">3</h1>*/}
                        {/*                                </div>*/}
                        {/*                                <div className="card-seo-chart">*/}
                        {/*                                    <div className="fake-chart-canva --down">*/}
                        {/*                                        <svg width="102" height="62" viewBox="0 0 102 62" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                        {/*                                            <path d="M1 2.66L3.77778 12.24L6.55556 11.95L9.33333 1L12.1111 5.8L14.8889 9.17L17.6667 3.89L20.4444 8.68L23.2222 13.56L26 12.02L28.7778 17.53L31.5556 20.06L34.3333 24.79L37.1111 35.33L39.8889 35.19L42.6667 37.7L45.4444 40.3L48.2222 30.96L51 38.38L53.7778 45.58L56.5556 49.9L59.3333 44.4L62.1111 48.98L64.8889 49.52L67.6667 55.35L70.4444 61L73.2222 49.97L76 47.45L78.7778 41.49L81.5556 36.79L84.3333 42.26L87.1111 56.64L89.8889 55.33L92.6667 54.19L95.4444 49.55L98.2222 44.28L101 58.93" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>*/}
                        {/*                                            <path opacity="0.3" d="M1 2.5L3.5 11.5H6.5L9.5 1L12 5L15 8.5L18 3.5L23.5 13L26 11.5L29 17L32 20L34.5 24L37 34.5H40L45.5 39.5L48.5 31L53.5 44.5L56.5 49L59.5 44.5L62 48L65 49L70.5 59.5L73 49.5L76 47L81.5 36.5L84 41.5L87 55.5L92.5 54L98 44.5L99.5 51.5L101 60H1V2.5Z" fill="url(#paint0_linear_180_5132)"></path>*/}
                        {/*                                            <defs>*/}
                        {/*                                                <linearGradient id="paint0_linear_180_5132" x1="51" y1="0.5" x2="51" y2="60" gradientUnits="userSpaceOnUse">*/}
                        {/*                                                    <stop stop-color="currentColor"></stop>*/}
                        {/*                                                    <stop offset="1" stop-color="currentColor" stop-opacity="0"></stop>*/}
                        {/*                                                </linearGradient>*/}
                        {/*                                            </defs>*/}
                        {/*                                        </svg>*/}
                        {/*                                    </div>*/}
                        {/*                                </div>*/}
                        {/*                            </div>*/}
                        {/*                            <div className="card-footer">*/}
                        {/*                                <div className="seo-rating-row">*/}
                        {/*                                    <div className="seo-rating --down">*/}
                        {/*                                    <span className="ico">*/}
                        {/*                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                        {/*                                            <g>*/}
                        {/*                                                <path d="M10.875 9L6.375 4.5L4.5 6.375L1.125 3" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>*/}
                        {/*                                                <path d="M10.875 6V9H7.875" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>*/}
                        {/*                                            </g>*/}
                        {/*                                            <defs>*/}
                        {/*                                                <clipPath>*/}
                        {/*                                                    <rect width="12" height="12" fill="white"></rect>*/}
                        {/*                                                </clipPath>*/}
                        {/*                                            </defs>*/}
                        {/*                                        </svg>*/}
                        {/*                                    </span> <span>-0.89%</span>*/}
                        {/*                                    </div>*/}
                        {/*                                    <p className="seo-rating-desk"><span>vs</span> yesterday</p>*/}
                        {/*                                </div>*/}
                        {/*                            </div>*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}

                        {/*                    <div className="col-lg-3 col-md-6 col-12">*/}
                        {/*                        <div className="card card-shadow card-seo">*/}
                        {/*                            <div className="card-body">*/}
                        {/*                                <div className="card-seo-info">*/}
                        {/*                                    <div className="seo-title">*/}
                        {/*                                    <span className="ico">*/}
                        {/*                                        <img src="img/ico-closed-job.svg">*/}
                        {/*                                    </span>*/}
                        {/*                                        <span className="name">Closed Jobs</span>*/}
                        {/*                                    </div>*/}
                        {/*                                    <h1 className="seo-stat-title">213</h1>*/}
                        {/*                                </div>*/}
                        {/*                                <div className="card-seo-chart">*/}
                        {/*                                    <div className="fake-chart-canva --up">*/}
                        {/*                                        <svg width="102" height="62" viewBox="0 0 102 62" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                        {/*                                            <path d="M1 57L4 51.5L9.5 43.5L12 47L15 46L17.5 49L20.5 56L23 57L28.5 53.5L31.5 60L34.5 53.5L37 53L40 42.5H42.5L45 44.5L48.5 45L53.5 37.5L56.5 30.5L59.5 26.5L62 28L67.5 20.5L70.5 28.5L73.5 28L78.5 16.5L81.5 17.5L84.5 10.5H87L90 5.5L95 13.5L98 1L101 10.5V60.5H1V57Z" fill="url(#paint0_linear_180_5093)" fill-opacity="0.3"></path>*/}
                        {/*                                            <path d="M1 57.92L3.77778 52.6L6.55556 48.92L9.33333 43.61L12.1111 47.89L14.8889 46.12L17.6667 49.73L20.4444 56.41L23.2222 57.66L26 55.6L28.7778 53.76L31.5556 61L34.3333 53.75L37.1111 53.62L39.8889 43.02L42.6667 43.2L45.4444 45.56L48.2222 45.74L51 41.48L53.7778 37.63L56.5556 30.85L59.3333 26.88L62.1111 28.86L64.8889 24.59L67.6667 20.63L70.4444 29.04L73.2222 28.65L76 23.02L78.7778 16.78L81.5556 18.01L84.3333 10.95L87.1111 11.28L89.8889 5.76L92.6667 10.75L95.4444 14.47L98.2222 1L101 10.83" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>*/}
                        {/*                                            <defs>*/}
                        {/*                                                <linearGradient id="paint0_linear_180_5093" x1="51" y1="1" x2="51" y2="60.5" gradientUnits="userSpaceOnUse">*/}
                        {/*                                                    <stop stop-color="currentColor"></stop>*/}
                        {/*                                                    <stop offset="1" stop-color="currentColor" stop-opacity="0"></stop>*/}
                        {/*                                                </linearGradient>*/}
                        {/*                                            </defs>*/}
                        {/*                                        </svg>*/}
                        {/*                                    </div>*/}
                        {/*                                </div>*/}
                        {/*                            </div>*/}
                        {/*                            <div className="card-footer">*/}
                        {/*                                <div className="seo-rating-row">*/}
                        {/*                                    <div className="seo-rating --up">*/}
                        {/*                                    <span className="ico">*/}
                        {/*                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                        {/*                                            <g>*/}
                        {/*                                                <path d="M10.875 2.625L6.375 7.125L4.5 5.25L1.125 8.625" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>*/}
                        {/*                                                <path d="M10.875 5.625V2.625H7.875" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>*/}
                        {/*                                            </g>*/}
                        {/*                                            <defs>*/}
                        {/*                                                <clipPath>*/}
                        {/*                                                    <rect width="12" height="12" fill="white"></rect>*/}
                        {/*                                                </clipPath>*/}
                        {/*                                            </defs>*/}
                        {/*                                        </svg>*/}
                        {/*                                    </span> <span>+2%</span>*/}
                        {/*                                    </div>*/}
                        {/*                                    <p className="seo-rating-desk"><span>vs</span> yesterday</p>*/}
                        {/*                                </div>*/}
                        {/*                            </div>*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <div className="col-12">*/}
                        {/*                <div className="row gy-20 gx-16">*/}
                        {/*                    <div className="col-lg-4">*/}
                        {/*                        <div className="card card-shadow card-chart h-full">*/}
                        {/*                            <div className="card-header">*/}
                        {/*                                <div className="card-chart-title text--xl">Feedback</div>*/}
                        {/*                                <div className="form-group select--transparent input--xs">*/}
                        {/*                                    <select className="select-default select2-hidden-accessible" data-minimum-results-for-search="Infinity" data-select2-id="select2-data-7-6uku" tabindex="-1" aria-hidden="true">*/}
                        {/*                                        <option data-select2-id="select2-data-9-zbqw">for 1 week</option>*/}
                        {/*                                        <option>for 1 month</option>*/}
                        {/*                                        <option>for 1 year</option>*/}
                        {/*                                    </select><span className="select2 select2-container select2-container--default" dir="ltr" data-select2-id="select2-data-1-dxz9" style="width: 1px;"><span className="selection"><span className="select2-selection select2-selection--single" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="-1" aria-disabled="false" aria-labelledby="select2-o1qi-container" aria-controls="select2-o1qi-container"><span className="select2-selection__rendered" id="select2-o1qi-container" role="textbox" aria-readonly="true" title="for 1 week">for 1 week</span><span className="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span className="dropdown-wrapper" aria-hidden="true"></span></span><span className="select2 select2-container select2-container--default" dir="ltr" data-select2-id="select2-data-8-q17r" style="width: 68px;"><span className="selection"><span className="select2-selection select2-selection--single" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-disabled="false" aria-labelledby="select2-z9dx-container" aria-controls="select2-z9dx-container"><span className="select2-selection__rendered" id="select2-z9dx-container" role="textbox" aria-readonly="true" title="for 1 week">for 1 week</span><span className="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span className="dropdown-wrapper" aria-hidden="true"></span></span>*/}
                        {/*                                </div>*/}
                        {/*                            </div>*/}
                        {/*                            <div className="card-body">*/}
                        {/*                                <div className="pie-chatr-wrapper">*/}
                        {/*                                    <div className="pie-chatr-body">*/}
                        {/*                                        <img src="img/pie-chart-small.png" alt="">*/}
                        {/*                                    </div>*/}
                        {/*                                    <ul className="legend-list legend-list--column">*/}
                        {/*                                        <li className="legend-list-item">*/}
                        {/*                                            <span className="legend-point" style="background-color: #21B872;"></span>*/}
                        {/*                                            <span className="legend-list-name">*/}
                        {/*                                            Positive*/}
                        {/*                                        </span>*/}
                        {/*                                            <span className="legend-list-value">60%</span>*/}
                        {/*                                        </li>*/}
                        {/*                                        <li className="legend-list-item">*/}
                        {/*                                            <span className="legend-point" style="background-color: #F64CA7;"></span>*/}
                        {/*                                            <span className="legend-list-name">*/}
                        {/*                                            Neutral*/}
                        {/*                                        </span>*/}
                        {/*                                            <span className="legend-list-value">35%</span>*/}
                        {/*                                        </li>*/}
                        {/*                                        <li className="legend-list-item">*/}
                        {/*                                            <span className="legend-point" style="background-color: #F64CA7;"></span>*/}
                        {/*                                            <span className="legend-list-name">*/}
                        {/*                                            Negative*/}
                        {/*                                        </span>*/}
                        {/*                                            <span className="legend-list-value">5%</span>*/}
                        {/*                                        </li>*/}
                        {/*                                    </ul>*/}
                        {/*                                </div>*/}
                        {/*                            </div>*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                    <div className="col-lg-4">*/}
                        {/*                        <div className="card card-shadow card-chart h-full">*/}
                        {/*                            <div className="card-header">*/}
                        {/*                                <div className="card-chart-title text--xl">Jobs by Zone</div>*/}
                        {/*                                <div className="form-group select--transparent input--xs">*/}
                        {/*                                    <select className="select-default select2-hidden-accessible" data-minimum-results-for-search="Infinity" data-select2-id="select2-data-10-vpou" tabindex="-1" aria-hidden="true">*/}
                        {/*                                        <option data-select2-id="select2-data-12-dzl7">for 1 week</option>*/}
                        {/*                                        <option>for 1 month</option>*/}
                        {/*                                        <option>for 1 year</option>*/}
                        {/*                                    </select><span className="select2 select2-container select2-container--default" dir="ltr" data-select2-id="select2-data-2-gvi1" style="width: 1px;"><span className="selection"><span className="select2-selection select2-selection--single" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="-1" aria-disabled="false" aria-labelledby="select2-ycnj-container" aria-controls="select2-ycnj-container"><span className="select2-selection__rendered" id="select2-ycnj-container" role="textbox" aria-readonly="true" title="for 1 week">for 1 week</span><span className="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span className="dropdown-wrapper" aria-hidden="true"></span></span><span className="select2 select2-container select2-container--default" dir="ltr" data-select2-id="select2-data-11-55fr" style="width: 68px;"><span className="selection"><span className="select2-selection select2-selection--single" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-disabled="false" aria-labelledby="select2-jgq6-container" aria-controls="select2-jgq6-container"><span className="select2-selection__rendered" id="select2-jgq6-container" role="textbox" aria-readonly="true" title="for 1 week">for 1 week</span><span className="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span className="dropdown-wrapper" aria-hidden="true"></span></span>*/}
                        {/*                                </div>*/}
                        {/*                            </div>*/}
                        {/*                            <div className="card-body">*/}
                        {/*                                <div className="bar-chart-horizontal">*/}
                        {/*                                    <img src="img/bar-chart-horizontal.png" alt="">*/}
                        {/*                                </div>*/}
                        {/*                            </div>*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                    <div className="col-lg-4">*/}
                        {/*                        <div className="card card-shadow card-chart h-full">*/}
                        {/*                            <div className="card-header">*/}
                        {/*                                <div className="card-chart-title text--xl">Total Transactions</div>*/}
                        {/*                                <div className="form-group select--transparent input--xs" data-select2-id="select2-data-35-hyhq">*/}
                        {/*                                    <select className="select-default select2-hidden-accessible" data-minimum-results-for-search="Infinity" data-select2-id="select2-data-13-duqx" tabindex="-1" aria-hidden="true">*/}
                        {/*                                        <option data-select2-id="select2-data-15-yhnm">Export</option>*/}
                        {/*                                    </select><span className="select2 select2-container select2-container--default" dir="ltr" data-select2-id="select2-data-3-usgk" style="width: 1px;"><span className="selection"><span className="select2-selection select2-selection--single" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="-1" aria-disabled="false" aria-labelledby="select2-wmbw-container" aria-controls="select2-wmbw-container"><span className="select2-selection__rendered" id="select2-wmbw-container" role="textbox" aria-readonly="true" title="Export">Export</span><span className="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span className="dropdown-wrapper" aria-hidden="true"></span></span><span className="select2 select2-container select2-container--default select2-container--below" dir="ltr" data-select2-id="select2-data-14-uobv" style="width: 39px;"><span className="selection"><span className="select2-selection select2-selection--single" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-disabled="false" aria-labelledby="select2-zdlf-container" aria-controls="select2-zdlf-container"><span className="select2-selection__rendered" id="select2-zdlf-container" role="textbox" aria-readonly="true" title="Export">Export</span><span className="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span className="dropdown-wrapper" aria-hidden="true"></span></span>*/}
                        {/*                                </div>*/}
                        {/*                            </div>*/}
                        {/*                            <div className="card-body">*/}
                        {/*                                <ul className="legend-list mb-4 legend-list--small">*/}
                        {/*                                    <li className="legend-list-item">*/}
                        {/*                                        <span className="legend-point" style="background-color: #21B872;"></span>*/}
                        {/*                                        <span className="legend-list-name">*/}
                        {/*                                        This Week*/}
                        {/*                                    </span>*/}
                        {/*                                    </li>*/}
                        {/*                                    <li className="legend-list-item">*/}
                        {/*                                        <span className="legend-point" style="background-color: #F64CA7;"></span>*/}
                        {/*                                        <span className="legend-list-name">*/}
                        {/*                                        Last Week*/}
                        {/*                                    </span>*/}
                        {/*                                    </li>*/}
                        {/*                                    <li className="legend-list-item">*/}
                        {/*                                        <span className="legend-point" style="background-color: #F64CA7;"></span>*/}
                        {/*                                        <span className="legend-list-name">*/}
                        {/*                                        Cost*/}
                        {/*                                    </span>*/}
                        {/*                                    </li>*/}
                        {/*                                </ul>*/}
                        {/*                                <div className="bar-chart-tree">*/}
                        {/*                                    <img src="img/bar-chart-tree.png" alt="">*/}
                        {/*                                </div>*/}
                        {/*                            </div>*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </main>
    )
}
