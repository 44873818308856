import { Outlet } from 'react-router-dom'

import AnalysisIcon from '../assets/img/ico-analysis.svg?react'
import ChatIcon from '../assets/img/ico-chat.svg?react'
import FeedbackIcon from '../assets/img/ico-feedback.svg?react'
import GridsIcon from '../assets/img/ico-grids.svg?react'
import OrderIcon from '../assets/img/ico-order.svg?react'
import ReportIcon from '../assets/img/ico-report.svg?react'
import ToolsIcon from '../assets/img/ico-tools.svg?react'
import Header from '../compoments/layout/Header'
import Sidebar from '../compoments/layout/Sidebar'

const ExecutivesLayout = () => {
    const pages = [
        {
            icon: <GridsIcon />,
            title: 'Dashboard',
            url: 'executives-dashboard',
        },
        {
            icon: <ReportIcon />,
            title: 'Performance Reports',
            url: 'executives-performance-reports',
        },
        {
            icon: <AnalysisIcon />,
            title: 'Revenue Analysis',
            url: 'executives-revenue-analysis',
        },
        {
            icon: <OrderIcon />,
            title: 'Work Order KPIs',
            url: 'executives-work-order-kpis',
        },
        {
            icon: <ToolsIcon />,
            title: 'Technician Performance',
            url: 'executives-technician-performance',
        },
        {
            icon: <FeedbackIcon />,
            title: 'Customer Feedback',
            url: 'executives-customer-feedback',
        },
        {
            icon: <ChatIcon />,
            title: 'Chat',
            url: 'executives-chat',
        },
    ]

    return (
        <>
            <Header />
            <Sidebar pages={pages} />
            <div className="wrapper header-fixed sidebar-indentation">
                <Outlet />
            </div>
        </>
    )
}

export default ExecutivesLayout
