/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import Pagination from '../../../compoments/common/Pagination'
import SearchForm from '../../../compoments/common/SearchForm'
import SelectBox from '../../../compoments/common/SelectBox'
import AddNotificationModal from '../../../compoments/modals/AddNotificationModal'
import useModal from '../../../hooks/useModal'

const NotificationManagerPage = () => {
    const { isOpen, closeModal, openModal } = useModal()
    const options = [
        {
            label: 'for 1 week',
            value: 'for 1 week',
        },
        {
            label: 'for 1 month',
            value: 'for 1 month',
        },
        {
            label: 'for 1 year',
            value: 'for 1 year',
        },
    ]
    return (
        <>
            <main className="content flex flex-col">
                <div className="px-container flex flex-col flex-auto">
                    <div className="table-group">
                        <div className="table-header flex items-center justify-between flex-wrap">
                            <div className="table-tools">
                                <SelectBox
                                    options={options}
                                    placeholder="Select Event"
                                />
                            </div>
                            <div className="table-tools">
                                <button
                                    onClick={() =>
                                        openModal('add-notification')
                                    }
                                    className="btn btn--primary-gradient btn--sm fullRadius whitespace-nowrap"
                                >
                                    <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M15.8327 9.16675H10.8327V4.16675H9.16602V9.16675H4.16602V10.8334H9.16602V15.8334H10.8327V10.8334H15.8327V9.16675Z"
                                            fill="white"
                                        ></path>
                                    </svg>
                                    <span className="text--xs">
                                        Add Notification
                                    </span>
                                </button>
                                <SearchForm />
                                {/* <a href="#" className="btn">
                                <svg
                                    width="22"
                                    height="22"
                                    viewBox="0 0 22 22"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M10.083 1.83337C14.637 1.83337 18.333 5.52937 18.333 10.0834C18.333 14.6374 14.637 18.3334 10.083 18.3334C5.52901 18.3334 1.83301 14.6374 1.83301 10.0834C1.83301 5.52937 5.52901 1.83337 10.083 1.83337ZM10.083 16.5C13.6278 16.5 16.4997 13.6281 16.4997 10.0834C16.4997 6.53862 13.6278 3.66671 10.083 3.66671C6.53826 3.66671 3.66634 6.53862 3.66634 10.0834C3.66634 13.6281 6.53826 16.5 10.083 16.5ZM17.8609 16.5651L20.4542 19.1575L19.1571 20.4545L16.5648 17.8613L17.8609 16.5651Z"
                                        fill="#7D8491"
                                    ></path>
                                </svg>
                            </a> */}
                            </div>
                        </div>

                        <div className="table-body table-responsive p-0">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Code</th>
                                        <th>Event</th>
                                        <th>Scheduled</th>
                                        <th className="text-center">Roles</th>
                                        <th
                                            className="text-center"
                                            style={{ width: 10 }}
                                        >
                                            Status
                                        </th>
                                        <th style={{ width: 10 }}>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>#123</td>
                                        <td>System Under Maintenance</td>
                                        <td>
                                            <span className="text-color-1">
                                                At the time of the events
                                            </span>
                                        </td>
                                        <td className="text-center">
                                            All Users
                                        </td>
                                        <td>
                                            <span className="pill pill-success">
                                                Active
                                            </span>
                                        </td>
                                        <td className="text-right sticky-end">
                                            <div
                                                className="dropdown flex flex-col"
                                                data-position="top-end"
                                                data-dropdown="dropdown"
                                            >
                                                <a
                                                    href="#"
                                                    className="btn dropdown-btn"
                                                    data-role="button"
                                                >
                                                    <span className="ico">
                                                        <img
                                                            src="/src/assets/img/dots.svg"
                                                            alt="dots"
                                                        />
                                                    </span>
                                                </a>
                                                <div
                                                    className="dropdown__body dropdown-md"
                                                    data-role="dropdown"
                                                >
                                                    <ul className="dropdown-list">
                                                        <li>
                                                            <a
                                                                href="#"
                                                                className="btn size--sm btn-link-drp"
                                                            >
                                                                <span>
                                                                    Show Item
                                                                </span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <Pagination
                        currentPage={1}
                        totalPages={10}
                        onPageChange={() => {}}
                    />
                </div>
            </main>
            {
                <AddNotificationModal
                    isOpen={isOpen('add-notification')}
                    onClose={() => closeModal('add-notification')}
                />
            }
        </>
    )
}

export default NotificationManagerPage
