import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { createBrowserHistory } from 'history'
import { combineReducers } from 'redux'
import { createReduxHistoryContext } from 'redux-first-history'

import authReducer from './features/auth/authSlice'
import { authApi } from './services/auth'

// Setup redux-first-history
const { createReduxHistory, routerMiddleware, routerReducer } =
    createReduxHistoryContext({ history: createBrowserHistory() })
export const store = configureStore({
    devTools: process.env.NODE_ENV === 'development',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat([authApi.middleware, routerMiddleware]),
    reducer: combineReducers({
        router: routerReducer,
        auth: authReducer,
        [authApi.reducerPath]: authApi.reducer,
    }),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

setupListeners(store.dispatch)
export const history = createReduxHistory(store)
