import imagePlaceholder from '../assets/img/placeholder.png'

export const returnOnlyNumbers = (value: string) => {
  return value.replace(/\D/g, '')
}

export const getFullUrl = (path: string): string => {
  const baseUrl = process.env.NEXT_PUBLIC_API_URL
  return `${baseUrl}${path}`
}

export const getMediaUrl = (
  path: string | null | undefined,
  placeholder?: string,
): string => {
  const baseUrl = import.meta.env.VITE_API_URL

  if (!path) {
    return placeholder || imagePlaceholder
  }

  return `${baseUrl}${path}`
}
