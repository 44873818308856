import type { ChartOptions } from 'chart.js'
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js'
import type { ChartProps } from 'react-chartjs-2'
import { Bar } from 'react-chartjs-2'

import { COLORS, FONT } from '../../utils/charts'

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
)

interface HorizontalBarChartProps
    extends Omit<ChartProps<'bar'>, 'data' | 'type'> {
    labels: string[]
    values: number[]
    units?: string
}
const HorizontalBarChart = ({
    labels,
    values,
    ...rest
}: HorizontalBarChartProps) => {
    const options: ChartOptions<'bar'> = {
        indexAxis: 'y',
        scales: {
            x: {
                min: 0,
                max: 1500,
                grid: {
                    display: false,
                    drawOnChartArea: false,
                    drawTicks: false,
                },
                border: {
                    display: false,
                    color: 'A6A6A6',
                },
                ticks: {
                    stepSize: 300,
                    font: FONT,
                    color: '#A6A6A6',
                    padding: 10,
                },
            },
            y: {
                grid: {
                    display: false,
                },
                border: {
                    display: false,
                },
                ticks: {
                    font: FONT,
                    color: '#494949',
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
        elements: {
            point: {
                radius: 0,
            },
        },
        maintainAspectRatio: false,
    }

    const data = {
        labels,
        datasets: [
            {
                label: 'Dataset 1',
                data: values,
                backgroundColor: COLORS,
                borderColor: COLORS,
                borderWidth: 1,
                borderRadius: 6,
            },
        ],
    }
    return <Bar options={options} data={data} {...rest} />
}

export default HorizontalBarChart
