import { type InferType } from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useUpdateMeMutation } from '../../../services/auth'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import Input from '../../../compoments/common/Input'

const schema = yup.object().shape({
    oldPassword: yup.string().required('Old password is required'),
    password: yup.string().required('Password is required'),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref('password')], 'Passwords must match')
        .required('Confirm password is required'),
})

type FormType = InferType<typeof schema>

export const SecuritySettings = () => {
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<FormType>({
        resolver: yupResolver(schema),
    })

    const [updateMe] = useUpdateMeMutation()

    const onSubmit = async (data: FormType) => {
        const formData = new FormData()

        formData.append('password', data.password)
        formData.append('oldPassword', data.oldPassword)

        try {
            const res = await updateMe(formData)

            console.log(res, 'res')

            if (res?.data?.success) {
                toast.success('Updated successfully!')
            } else {
                toast.error(res?.data?.message || 'Something went wrong')
            }
        } catch (error) {
            toast.error('Something went wrong')
            console.error('rejected', error)
        }
    }

    return (
        <div className="settings-body">
            <div className="settings__header ">
                <h4 className="mb-22">Security</h4>
                <button
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                    className="btn btn--primary-gradient btn--lg fullRadius mb-22"
                >
                    <span className="text--md">Save Changes</span>
                </button>
            </div>

            <div className="row gy-42">
                <div className="col-lg-5">
                    <h5 className="mb-13">General Info</h5>
                    <p className="text--xs color-text-color-1">
                        Edit information about your personal data
                    </p>
                </div>
                <div className="col-lg-7">
                    <div className="form-group--row input--lg mb-22">
                        <Input
                            togglePasswordShow
                            label="Password"
                            className="input input--outline  w-full"
                            type="password"
                            placeholder=""
                            error={errors.oldPassword?.message}
                            {...register('oldPassword')}
                        />
                    </div>
                    <div className="form-group--row input--lg mb-22">
                        <Input
                            togglePasswordShow
                            label="New Password"
                            className="input input--outline  w-full"
                            type="password"
                            placeholder=""
                            error={errors.password?.message}
                            {...register('password')}
                        />
                    </div>
                    <div className="form-group--row input--lg">
                        <Input
                            togglePasswordShow
                            label="Confirm Password"
                            className="input input--outline  w-full"
                            type="password"
                            placeholder=""
                            error={errors.confirmPassword?.message}
                            {...register('confirmPassword')}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
