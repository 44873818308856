import React from 'react'
import { Outlet } from 'react-router-dom'

import CalendarIcon from '../assets/img/ico-calendar.svg?react'
import ChartIcon from '../assets/img/ico-chart.svg?react'
import ChatIcon from '../assets/img/ico-chat.svg?react'
import CustomersIcon from '../assets/img/ico-customers.svg?react'
import GridsIcon from '../assets/img/ico-grids.svg?react'
import NotificationIcon from '../assets/img/ico-notification.svg?react'
import ToolsIcon from '../assets/img/ico-tools.svg?react'
import Header from '../compoments/layout/Header'
import Sidebar from '../compoments/layout/Sidebar'

const DispatcherLayout = () => {
    const pages = [
        {
            icon: <GridsIcon />,
            title: 'Dashboard',
            url: '/',
        },
        {
            icon: <CalendarIcon />,
            title: 'Resource Scheduling',
            url: '/dispatcher-resource-sheduling',
        },
        {
            icon: <ToolsIcon />,
            title: 'Technician Management',
            url: '/dispatcher-technician-management',
        },
        {
            icon: <CustomersIcon />,
            title: 'Customer Management',
            url: '/dispatcher-customer-management',
        },
        {
            icon: <ChartIcon />,
            title: 'Reports',
            url: '/dispatcher-reports',
        },
        {
            icon: <NotificationIcon />,
            title: 'Notification Manager',
            url: '/dispatcher-notification-management',
        },
        {
            icon: <ChatIcon />,
            title: 'Chat',
            url: '/dispatcher-chat',
        },
    ]

    return (
        <>
            <Header />
            <Sidebar pages={pages} />
            <div className="wrapper header-fixed sidebar-indentation">
                <Outlet />
            </div>
        </>
    )
}

export default DispatcherLayout
