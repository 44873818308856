import FullCalendar from '@fullcalendar/react'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import { useState } from 'react'
import DatePicker from 'react-datepicker'

import Calendar2Icon from '../../../../assets/img/ico-calendar-2.svg?react'
import ChevronNextIcon from '../../../../assets/img/ico-chevron-next-2.svg?react'
import ChevronPrevIcon from '../../../../assets/img/ico-chevron-prev-2.svg?react'

import 'react-datepicker/dist/react-datepicker.css'

type FullCalendarApi = {
    prev: () => void
    next: () => void
    changeView: (viewName: string) => void
    getDate: () => Date
    gotoDate: (date: Date) => void
}

interface CalendarProps {
    title?: string
    events?: any
    resources?: any
}

export default function WorkingSchedule({
    title = 'Calendar',
    events = [],
    resources = [],
}: CalendarProps) {
    const [view, setView] = useState('resourceTimelineDay')
    const [calendarApi, setCalendarApi] = useState<FullCalendarApi>()
    const [selectedDate, setSelectedDate] = useState<Date | null>(null)

    const handlePrev = () => {
        if (!calendarApi) return
        calendarApi?.prev()
        setSelectedDate(calendarApi.getDate())
    }

    const handleNext = () => {
        if (!calendarApi) return
        calendarApi?.next()
        setSelectedDate(calendarApi.getDate())
    }

    const handleViewChange = (newView: string) => {
        if (!calendarApi) return
        setView(newView)
        calendarApi?.changeView(newView)
        setSelectedDate(calendarApi.getDate())
    }

    return (
        <div className="col-12">
            <div className="calendar calendar-working-schedule">
                <div className="flex justify-between calendar-header">
                    <div className="c-h-item">
                        <div className="a-h-item--sm">
                            <div className="calendar-info">{title}</div>
                        </div>
                        <div className="group-button-calendar">
                            <button
                                type="button"
                                className="btn btn--square btn--xs full-radius btn--secondary-2"
                                onClick={handlePrev}
                            >
                                <span className="ico">
                                    <ChevronPrevIcon />
                                </span>
                            </button>
                            <button
                                type="button"
                                className="btn btn--square btn--xs full-radius btn--secondary-2"
                                onClick={handleNext}
                            >
                                <span className="ico">
                                    <ChevronNextIcon />
                                </span>
                            </button>
                        </div>
                        <div className="calendar-title">
                            <div className="flex items-center">
                                <div style={{ width: 116, height: 24 }}>
                                    <DatePicker
                                        selected={selectedDate || new Date()}
                                        className="calendar-title"
                                        onChange={(date: Date | null) => {
                                            setSelectedDate(date)
                                            if (calendarApi && date) {
                                                calendarApi.gotoDate(date)
                                            }
                                        }}
                                        dateFormat="MMM d, yyyy"
                                    />
                                </div>
                                <span className="ico absolute right-4">
                                    <Calendar2Icon />
                                </span>
                            </div>
                        </div>
                    </div>
                    {calendarApi && (
                        <div className="c-h-item">
                            <label className="calendar-mode" id="mode-days">
                                {[
                                    'resourceTimelineDay',
                                    'resourceTimelineWeek',
                                ].map((item, index) => (
                                    <label
                                        key={index}
                                        className="calendar-mode-item"
                                    >
                                        <input
                                            type="radio"
                                            checked={view === item}
                                            hidden
                                            onChange={() =>
                                                handleViewChange(item)
                                            }
                                        />
                                        <div className="calendar-mode-btn btn">
                                            {item.replace(
                                                'resourceTimeline',
                                                '',
                                            )}
                                        </div>
                                    </label>
                                ))}
                            </label>
                        </div>
                    )}
                </div>
                <div className="calendar-body">
                    <div className="full-calendar full-calendar__users">
                        <FullCalendar
                            plugins={[resourceTimelinePlugin]}
                            initialView={view}
                            resources={resources}
                            resourceAreaWidth={!resources?.length ? 0 : 200}
                            events={events}
                            resourceLabelContent={({ resource }) => {
                                return {
                                    html: `<div class="user-calendar-resource" id="${resource.id}">
                                    <div class="ico-avatar btn btn--square user-calendar-ava">
                                        <img src="${resource.extendedProps.ava}" alt="user-photo"/>
                                    </div>
                                    <div class="user-calendar-info">
                                        <div class="user-calendar-name">
                                            <p>${resource.extendedProps.name}</p>
                                        </div>
                                        <div class="user-calendar-desk">
                                            <span class="user-calendar-percent">${resource.extendedProps.prc}:</span>
                                            <span class="user-calendar-desk-percent">
                                                ${resource.extendedProps.booked_time} booked
                                            </span>
                                        </div>
                                    </div>
                                </div>`,
                                }
                            }}
                            eventContent={({ event }) => {
                                const { status, title, desk } =
                                    event.extendedProps
                                return {
                                    html: `
                                    <div class="event-calendar-resource ${status}">
                                        <div class="event-calendar-status"></div>
                                        <div class="event-calendar-content">
                                            <p class="event-calendar-title">${title}</p>
                                            <p class="event-calendar-desk">${desk}</p>
                                        </div>
                                    </div>
                                `,
                                }
                            }}
                            schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
                            ref={(calendarRef) =>
                                setCalendarApi(
                                    calendarRef?.getApi() as FullCalendarApi,
                                )
                            }
                            headerToolbar={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
