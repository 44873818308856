import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import type { User } from '../types'

import type {
    ResponseType,
    LoginRequestType,
    LoginResponseType,
    RegisterResponseType,
} from './types'

const authBase = 'api/auth'

export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: fetchBaseQuery({
        baseUrl: import.meta.env.VITE_API_URL,
        prepareHeaders: (headers) => {
            const token = window.localStorage.getItem('authToken')
            headers.set('Authorization', `Bearer ${token}`)
            return headers
        },
    }),
    tagTypes: ['me'],
    endpoints: (builder) => ({
        login: builder.mutation<LoginResponseType, LoginRequestType>({
            query: (body) => ({
                url: `${authBase}/signin`,
                method: 'POST',
                body,
            }),
        }),
        logout: builder.query<void, void>({
            query: () => `${authBase}/logout`,
        }),
        forgotPassword: builder.mutation<
            ResponseType<undefined>,
            { email: string }
        >({
            query: (body) => ({
                url: `${authBase}/forgot-password`,
                method: 'POST',
                body,
            }),
        }),
        testForgotCode: builder.mutation<
            ResponseType<undefined>,
            { email: string; code: number }
        >({
            query: (body) => ({
                url: `${authBase}/test-forgot-code`,
                method: 'POST',
                body,
            }),
        }),
        restorePassword: builder.mutation<
            RegisterResponseType,
            { email: string; code: number; password: string }
        >({
            query: (body) => ({
                url: `${authBase}/restore-password`,
                method: 'POST',
                body,
            }),
        }),

        getMe: builder.query<ResponseType<User>, void>({
            query: () => `api/users/me`,
            providesTags: ['me'],
        }),
        updateMe: builder.mutation<ResponseType<User>, FormData>({
            query: (body) => ({
                url: `api/users/me`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['me'],
        }),
    }),
})

export const {
    useLoginMutation,
    useGetMeQuery,
    useUpdateMeMutation,
    useLazyLogoutQuery,
    useForgotPasswordMutation,
    useTestForgotCodeMutation,
    useRestorePasswordMutation,
} = authApi
