import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import type { InferType } from 'yup'
import { object, string } from 'yup'

import type { StepsProps } from '..'
import Button from '../../../compoments/common/Button'
import Input from '../../../compoments/common/Input'
import { useForgotPasswordMutation } from '../../../services/auth'

import FormHeader from './FormHeader'

const schema = object({
    email: string().email('Invalid email format').required('Email is required'),
})
type FormType = InferType<typeof schema>

const Step1 = ({ setStep, setFormData }: StepsProps) => {
    const navigate = useNavigate()
    const [resetPassword, { data: response, isLoading }] =
        useForgotPasswordMutation()
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormType>({
        resolver: yupResolver(schema),
    })

    const onSubmit = async (data: FormType) => {
        try {
            const responce = await resetPassword(data).unwrap()
            if (responce?.success) {
                setFormData('email', data.email)
                toast.success(
                    response?.message ||
                        'The password recovery code has been sent by email!',
                )
                setStep(2)
            } else {
                toast.error('Something went wrong')
            }
        } catch (error) {
            console.error('rejected', error)
        }
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)} className="form">
            <FormHeader onBack={() => navigate('/login')}>
                <h2 className="fs-28 text-left mb-22">Forgot Password</h2>
                <p className="text-description text-left text--md mb-42">
                    Enter yor email address that associated with your account
                </p>
            </FormHeader>
            <div className="form-body ">
                <div className="row mb-22">
                    <div className="col-12 mb-22">
                        <Input
                            label="Email address"
                            className="input input--outline  w-full"
                            type="text"
                            placeholder=""
                            error={errors.email?.message}
                            {...register('email')}
                        />
                    </div>
                </div>
            </div>
            <div className="form-footer form-footer-col">
                <div className="items-12 flex flex-col">
                    <Button
                        loading={isLoading}
                        type="submit"
                        className="w-full"
                    >
                        <span>Send Code</span>
                    </Button>
                </div>
            </div>
        </form>
    )
}

export default Step1
