import Accordion from '../../../compoments/common/Accordion'
import Calendar from '../../../compoments/features/Calendar'
import MapBox from '../../../compoments/features/MapBox'
import events2 from '../../../data/data-events-2.json'
import events1 from '../../../data/data-events.json'
import resources1 from '../../../data/data-labels.json'

const ResourceSchedulingPage = () => {
    return (
        <main className="content">
            <div className="px-container">
                <div className="row gutters-16">
                    <div className="col-12">
                        <Accordion
                            header={<div className="accordeon-info">Map</div>}
                        >
                            <MapBox />
                        </Accordion>
                    </div>
                    <div className="col-12">
                        <Calendar events={events1} resources={resources1} />
                    </div>

                    <div className="col-12">
                        <Calendar events={events2} title="Unassigned Jobs">
                            <div className="table-body table-responsive p-0 bordered-split">
                                <table className="table table-custom">
                                    <thead>
                                        <tr>
                                            <th>Job ID</th>
                                            <th className="text-center">
                                                Job Type
                                            </th>
                                            <th>Job Location</th>
                                            <th>Customer</th>
                                            <th>Phone No.</th>
                                            <th className="text-center">
                                                Job Creation Date
                                            </th>
                                            <th className="text-center">
                                                Appointment Date
                                            </th>
                                            <th className="text-center">
                                                Status
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <p className="font-600">
                                                    # JOB 001
                                                </p>
                                            </td>
                                            <td className="text-center">OPC</td>
                                            <td>
                                                <p className="text-color-1">
                                                    8502 Preston Rd. Inglewood,
                                                    Maine 98380
                                                </p>
                                            </td>
                                            <td>Esther Howard</td>
                                            <td>(702) 555-0122</td>
                                            <td className="text-center">
                                                <p className="text-color-1">
                                                    Mar 24, 2024
                                                </p>
                                            </td>
                                            <td className="text-center">
                                                <p className="text-color-1">
                                                    Apr 12, 2024
                                                </p>
                                            </td>
                                            <td className="text-center">
                                                <div className="flex items-center justify-center">
                                                    <span className="pill pill-2 pill-success">
                                                        New Order
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p className="font-600">
                                                    # JOB 002
                                                </p>
                                            </td>
                                            <td className="text-center">OPC</td>
                                            <td>
                                                <p className="text-color-1">
                                                    2715 Ash Dr. San Jose, South
                                                    Dakota 83475
                                                </p>
                                            </td>
                                            <td>Wade Warren</td>
                                            <td>(671) 555-0110</td>
                                            <td className="text-center">
                                                <p className="text-color-1">
                                                    Mar 24, 2024
                                                </p>
                                            </td>
                                            <td className="text-center">
                                                <p className="text-color-1">
                                                    Apr 12, 2024
                                                </p>
                                            </td>
                                            <td className="text-center">
                                                <div className="flex items-center justify-center">
                                                    <span className="pill pill-2 pill-warning">
                                                        New Order
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p className="font-600">
                                                    # JOB 003
                                                </p>
                                            </td>
                                            <td className="text-center">OPC</td>
                                            <td>
                                                <p className="text-color-1">
                                                    1901 Thornridge Cir. Shiloh,
                                                    Hawaii 81063
                                                </p>
                                            </td>
                                            <td>Ronald Richards</td>
                                            <td>(209) 555-0104</td>
                                            <td className="text-center">
                                                <p className="text-color-1">
                                                    Mar 24, 2024
                                                </p>
                                            </td>
                                            <td className="text-center">
                                                <p className="text-color-1">
                                                    Apr 12, 2024
                                                </p>
                                            </td>
                                            <td className="text-center">
                                                <div className="flex items-center justify-center">
                                                    <span className="pill pill-2 pill-info">
                                                        New Order
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Calendar>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default ResourceSchedulingPage
