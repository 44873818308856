import type { ChartOptions } from 'chart.js'
import {
    CategoryScale,
    Chart as ChartJS,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import type { ChartProps } from 'react-chartjs-2/dist/types'

import { getGradient } from '../../utils/charts'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
)

interface TrendChartProps extends Omit<ChartProps<'line'>, 'data' | 'type'> {
    trend: 'up' | 'down'
    labels?: string[]
    values?: number[]
    units?: string
}

const TrendChart = ({ trend = 'up', ...rest }: TrendChartProps) => {
    const isRise = trend === 'up'
    const options: ChartOptions<'line'> = {
        scales: {
            x: {
                grid: {
                    display: false,
                    drawOnChartArea: false,
                    drawTicks: false,
                },
                border: {
                    display: false,
                },
                ticks: {
                    display: false,
                },
            },
            y: {
                min: 0,
                max: 500,
                grid: {
                    display: false,
                    drawTicks: false,
                },
                border: {
                    display: false,
                },
                ticks: {
                    display: false,
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
        responsive: true,
        layout: {
            padding: 0,
        },
        elements: {
            point: {
                radius: 0,
            },
        },
        maintainAspectRatio: false,
    }

    const labels = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
    ]

    const data = {
        labels,
        datasets: [
            {
                label: 'trend',
                data: [100, 200, 300, 150, 200, 100, 150],
                fill: true,
                borderColor: isRise ? '#0BAA69' : 'rgba(239, 35, 60, 1)',
                borderWidth: 1,
                backgroundColor: (context: any): string | CanvasGradient => {
                    const chart = context.chart
                    const { ctx, chartArea } = chart

                    if (!chartArea) {
                        return 'transparent'
                    }

                    return isRise
                        ? getGradient(ctx, chartArea, [
                              'rgba(11, 170, 105, 0.3)',
                              'rgba(11, 170, 105, 0)',
                          ])
                        : getGradient(ctx, chartArea, [
                              'rgba(239, 35, 60, 1)',
                              'rgba(239, 35, 60, 0)',
                          ])
                },
            },
        ],
    }
    return <Line options={options} data={data} {...rest} />
}

export default TrendChart
