import { useState, useCallback } from 'react'

const useModal = () => {
    const [modals, setModals] = useState<{ [key: string]: boolean }>({})

    const openModal = useCallback((id: string) => {
        setModals((prev) => ({ ...prev, [id]: true }))
    }, [])

    const closeModal = useCallback((id: string) => {
        setModals((prev) => ({ ...prev, [id]: false }))
    }, [])

    const isOpen = useCallback((id: string) => !!modals[id], [modals])

    return {
        isOpen,
        openModal,
        closeModal,
    }
}

export default useModal
