/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'react-router-dom'

import Avatar from '../../../assets/img/user-1.png'
import Pagination from '../../../compoments/common/Pagination'
import SelectBox from '../../../compoments/common/SelectBox'

export const CustomerManagementPage = () => {
    const options = [
        {
            label: 'for 1 week',
            value: 'for 1 week',
        },
        {
            label: 'for 1 month',
            value: 'for 1 month',
        },
        {
            label: 'for 1 year',
            value: 'for 1 year',
        },
    ]
    return (
        <main className="content">
            <div className="px-container">
                <div className="table-group">
                    <div className="table-header flex items-center justify-between">
                        <div className="table-tools">
                            <SelectBox
                                options={options}
                                placeholder={'Status'}
                            />
                            <SelectBox options={options} placeholder={'Role'} />
                        </div>

                        <a
                            href="#"
                            className="btn btn--primary-gradient btn--sm fullRadius"
                        >
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M15.8327 9.16675H10.8327V4.16675H9.16602V9.16675H4.16602V10.8334H9.16602V15.8334H10.8327V10.8334H15.8327V9.16675Z"
                                    fill="white"
                                ></path>
                            </svg>
                            <span className="text--xs">Add Customer</span>
                        </a>
                    </div>

                    <div className="table-body table-responsive p-0">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Full Name</th>
                                    <th>Phone</th>
                                    <th style={{ width: '100%' }}>Role</th>
                                    <th style={{ width: '80px' }}>Status</th>
                                    <th
                                        style={{ width: '10px' }}
                                        className="text-right"
                                    >
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <Link to={'1'}>
                                            <div className="flex items-center gap-8">
                                                <div className="user-28x28">
                                                    <img src={Avatar} alt="" />
                                                </div>
                                                <div className="flex flex-col">
                                                    <span className="text--xs fw-500">
                                                        Floyd Miles
                                                    </span>
                                                    <span className="text--12 text-color-1">
                                                        tanya.hill@example.com
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </td>
                                    <td>(302) 555-0107</td>
                                    <td>Executive</td>
                                    <td>
                                        <span className="pill pill-success">
                                            Active
                                        </span>
                                    </td>
                                    <td className="text-right sticky-end">
                                        <div
                                            className="dropdown flex flex-col"
                                            data-position="top-end"
                                            data-dropdown="dropdown"
                                        >
                                            <a
                                                href="#"
                                                className="btn dropdown-btn justify-end btn-table-menu"
                                                data-role="button"
                                            >
                                                <span className="ico">
                                                    <svg
                                                        width="22"
                                                        height="22"
                                                        viewBox="0 0 22 22"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M4.125 9.625C3.36875 9.625 2.75 10.2438 2.75 11C2.75 11.7563 3.36875 12.375 4.125 12.375C4.88125 12.375 5.5 11.7563 5.5 11C5.5 10.2438 4.88125 9.625 4.125 9.625ZM17.875 9.625C17.1188 9.625 16.5 10.2438 16.5 11C16.5 11.7563 17.1188 12.375 17.875 12.375C18.6313 12.375 19.25 11.7563 19.25 11C19.25 10.2438 18.6313 9.625 17.875 9.625ZM11 9.625C10.2438 9.625 9.625 10.2438 9.625 11C9.625 11.7563 10.2438 12.375 11 12.375C11.7563 12.375 12.375 11.7563 12.375 11C12.375 10.2438 11.7563 9.625 11 9.625Z"
                                                            fill="currentColor"
                                                        ></path>
                                                    </svg>
                                                </span>
                                            </a>

                                            <div
                                                className="dropdown__body dropdown-profile-body"
                                                data-role="dropdown"
                                            >
                                                <ul className="dropdown-list-links">
                                                    <li className="dropdown-list-link">
                                                        <a
                                                            href="#"
                                                            className="btn btn--md 
                                                        text-left btn-drp-link justify-start"
                                                        >
                                                            <span className="ico">
                                                                <svg
                                                                    width="12"
                                                                    height="12"
                                                                    viewBox="0 0 12 12"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <g>
                                                                        <path
                                                                            fillRule="evenodd"
                                                                            clipRule="evenodd"
                                                                            d="M7.74039 1.74C7.74039 2.20148 7.55707 2.64405 7.23075 2.97037C6.90444 3.29668 6.46186 3.48 6.00039 3.48C5.53891 3.48 5.09633 3.29668 4.77002 2.97037C4.44371 2.64405 4.26039 2.20148 4.26039 1.74C4.26039 1.27852 4.44371 0.835948 4.77002 0.509634C5.09633 0.183321 5.53891 0 6.00039 0C6.46186 0 6.90444 0.183321 7.23075 0.509634C7.55707 0.835948 7.74039 1.27852 7.74039 1.74ZM8.15439 5.05971C8.72516 5.63091 9.04579 6.40536 9.04582 7.21286V8.09143C9.04582 8.20509 9.00066 8.3141 8.92029 8.39447C8.83992 8.47485 8.73091 8.52 8.61724 8.52H7.7421L7.35382 11.6254C7.34087 11.7291 7.29052 11.8244 7.21223 11.8935C7.13395 11.9627 7.03311 12.0008 6.92867 12.0009H5.07296C4.96852 12.0008 4.86768 11.9627 4.7894 11.8935C4.71111 11.8244 4.66077 11.7291 4.64782 11.6254L4.26039 8.51914H3.38267C3.26901 8.51914 3.16 8.47399 3.07963 8.39362C2.99925 8.31324 2.9541 8.20424 2.9541 8.09057V7.21286C2.95429 6.61045 3.13308 6.02162 3.46788 5.52081C3.80268 5.02 4.27844 4.62969 4.83503 4.39924C5.39162 4.16879 6.00404 4.10854 6.59487 4.2261C7.1857 4.34366 7.72841 4.63375 8.15439 5.05971Z"
                                                                            fill="currentColor"
                                                                        ></path>
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath>
                                                                            <rect
                                                                                width="12"
                                                                                height="12"
                                                                                fill="white"
                                                                            ></rect>
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>
                                                            </span>
                                                            <span>
                                                                View Profile
                                                            </span>
                                                        </a>
                                                    </li>
                                                    <li className="dropdown-list-link">
                                                        <a
                                                            href="#"
                                                            className="btn btn--md 
                                                        text-left btn-drp-link justify-start"
                                                        >
                                                            <span className="ico">
                                                                <svg
                                                                    width="12"
                                                                    height="12"
                                                                    viewBox="0 0 12 12"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <g>
                                                                        <path
                                                                            d="M9.18429 -0.000976562C9.01303 -0.000976562 8.84349 0.0332431 8.6856 0.0996731C8.52873 0.16569 8.38653 0.262196 8.26725 0.383583L1.20627 7.41028C1.15385 7.46245 1.11582 7.52729 1.09588 7.5985L0.0158828 11.4557C-0.0258645 11.6047 0.0160535 11.7647 0.125535 11.8743C0.235018 11.9837 0.39504 12.0257 0.544137 11.9839L4.40128 10.9039C4.47249 10.8839 4.53734 10.8459 4.5895 10.7935L11.6161 3.73263L11.6173 3.73147C11.7372 3.61219 11.8324 3.4704 11.8975 3.31423C11.9628 3.15756 11.9964 2.98951 11.9964 2.81977C11.9964 2.65004 11.9628 2.48199 11.8975 2.32531C11.8324 2.16914 11.7372 2.02735 11.6173 1.90807L11.6161 1.90692L10.1022 0.384478C9.9828 0.26267 9.84026 0.165852 9.68297 0.0996731C9.52509 0.0332431 9.35554 -0.000976562 9.18429 -0.000976562Z"
                                                                            fill="currentColor"
                                                                        ></path>
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath>
                                                                            <rect
                                                                                width="12"
                                                                                height="12"
                                                                                fill="white"
                                                                            ></rect>
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>
                                                            </span>
                                                            <span>Edit</span>
                                                        </a>
                                                    </li>
                                                    <li className="dropdown-list-link">
                                                        <a
                                                            href="#"
                                                            className="btn btn--md 
                                                        text-left btn-drp-link justify-start"
                                                        >
                                                            <span className="ico">
                                                                <svg
                                                                    width="12"
                                                                    height="12"
                                                                    viewBox="0 0 12 12"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <g>
                                                                        <path
                                                                            fillRule="evenodd"
                                                                            clipRule="evenodd"
                                                                            d="M6.84003 3.26998C6.84003 4.66172 5.71186 5.78998 4.32012 5.78998C2.92837 5.78998 1.80012 4.66172 1.80012 3.26998C1.80012 1.87823 2.92837 0.750061 4.32012 0.750061C5.71186 0.750061 6.84003 1.87823 6.84003 3.26998ZM4.32012 6.62998C2.00052 6.62998 0.120117 8.51038 0.120117 10.8299C0.120117 11.0618 0.308174 11.2499 0.540117 11.2499H8.10003C8.33206 11.2499 8.52003 11.0618 8.52003 10.8299C8.52003 8.51038 6.63972 6.62998 4.32012 6.62998ZM11.4601 11.2499H9.50926C9.54886 11.1169 9.57012 10.9759 9.57012 10.8299C9.57012 9.11543 8.74829 7.59289 7.47706 6.63478C7.54435 6.6316 7.61206 6.62998 7.68012 6.62998C9.99972 6.62998 11.8801 8.51038 11.8801 10.8299C11.8801 11.0618 11.6921 11.2499 11.4601 11.2499ZM7.68012 5.78998C7.42666 5.78998 7.18195 5.7526 6.9512 5.68292C7.53423 5.04752 7.89012 4.20032 7.89012 3.26998C7.89012 2.33963 7.53423 1.49243 6.9512 0.857032C7.18195 0.787432 7.42666 0.750061 7.68012 0.750061C9.07186 0.750061 10.2001 1.87823 10.2001 3.26998C10.2001 4.66172 9.07186 5.78998 7.68012 5.78998Z"
                                                                            fill="currentColor"
                                                                        ></path>
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath>
                                                                            <rect
                                                                                width="12"
                                                                                height="12"
                                                                                fill="white"
                                                                            ></rect>
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>
                                                            </span>
                                                            <span>
                                                                Add to Team
                                                            </span>
                                                        </a>
                                                    </li>
                                                    <li className="dropdown-list-link">
                                                        <a
                                                            href="#"
                                                            className="btn btn--md 
                                                        text-left btn-drp-link justify-start"
                                                        >
                                                            <span className="ico">
                                                                <svg
                                                                    width="12"
                                                                    height="12"
                                                                    viewBox="0 0 12 12"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <g>
                                                                        <path
                                                                            d="M7.06115 9.32884L3.83399 7.56874C3.52421 7.87624 3.13034 8.08514 2.70201 8.1691C2.27368 8.25307 1.83009 8.20834 1.42714 8.04057C1.0242 7.87279 0.679949 7.58947 0.43779 7.22633C0.195631 6.86319 0.0664062 6.43648 0.0664062 6C0.0664062 5.56352 0.195631 5.13682 0.43779 4.77367C0.679949 4.41053 1.0242 4.12721 1.42714 3.95943C1.83009 3.79166 2.27368 3.74693 2.70201 3.8309C3.13034 3.91486 3.52421 4.12376 3.83399 4.43126L7.06115 2.67116C6.95047 2.15187 7.03041 1.61008 7.28636 1.14489C7.5423 0.679691 7.95714 0.322167 8.45502 0.137696C8.9529 -0.0467754 9.50055 -0.0458669 9.99782 0.140255C10.4951 0.326378 10.9087 0.685276 11.1631 1.15132C11.4175 1.61736 11.4957 2.15941 11.3833 2.67833C11.2709 3.19725 10.9754 3.65837 10.551 3.97737C10.1265 4.29637 9.60145 4.45192 9.07173 4.41559C8.54202 4.37926 8.04309 4.15347 7.66617 3.77951L4.439 5.53961C4.50329 5.84296 4.50329 6.15641 4.439 6.45976L7.66617 8.22049C8.04309 7.84653 8.54202 7.62074 9.07173 7.58441C9.60145 7.54808 10.1265 7.70363 10.551 8.02263C10.9754 8.34163 11.2709 8.80275 11.3833 9.32167C11.4957 9.8406 11.4175 10.3826 11.1631 10.8487C10.9087 11.3147 10.4951 11.6736 9.99782 11.8597C9.50055 12.0459 8.9529 12.0468 8.45502 11.8623C7.95714 11.6778 7.5423 11.3203 7.28636 10.8551C7.03041 10.3899 6.95047 9.84814 7.06115 9.32884Z"
                                                                            fill="currentColor"
                                                                        ></path>
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath>
                                                                            <rect
                                                                                width="12"
                                                                                height="12"
                                                                                fill="white"
                                                                            ></rect>
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>
                                                            </span>
                                                            <span>
                                                                Add to WorkGroup
                                                            </span>
                                                        </a>
                                                    </li>
                                                    <li className="dropdown-list-link">
                                                        <a
                                                            href="#"
                                                            className="btn btn--md 
                                                        text-left btn-drp-link justify-start 
                                                        btn-drp-link-danger"
                                                        >
                                                            <span className="ico">
                                                                <svg
                                                                    width="12"
                                                                    height="12"
                                                                    viewBox="0 0 12 12"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        fillRule="evenodd"
                                                                        clipRule="evenodd"
                                                                        d="M4.93892 1.72505C5.22022 1.44375 5.60176 1.28571 5.99958 1.28571C6.39741 1.28571 6.77894 1.44375 7.06024 1.72505C7.23798 1.9028 7.36651 2.12055 7.43705 2.35714H4.56211C4.63265 2.12055 4.76118 1.9028 4.93892 1.72505ZM3.24702 2.35714C3.33734 1.77704 3.60946 1.23623 4.02978 0.815917C4.55221 0.293494 5.26077 0 5.99958 0C6.7384 0 7.44695 0.293494 7.96938 0.815917C8.3897 1.23623 8.66187 1.77704 8.75212 2.35714H11.1424C11.4975 2.35714 11.7853 2.64496 11.7853 3C11.7853 3.35505 11.4975 3.64286 11.1424 3.64286H10.2853V10.7143C10.2853 11.0553 10.1499 11.3823 9.90875 11.6235C9.66764 11.8646 9.34055 12 8.99958 12H2.99958C2.65858 12 2.33156 11.8646 2.09044 11.6235C1.84933 11.3823 1.71387 11.0553 1.71387 10.7143V3.64286H0.856724C0.501684 3.64286 0.213867 3.35505 0.213867 3C0.213867 2.64496 0.501684 2.35714 0.856724 2.35714H3.24702ZM4.2853 5.03697C4.58116 5.03697 4.82101 5.27682 4.82101 5.57268V9.00257C4.82101 9.29837 4.58116 9.53829 4.2853 9.53829C3.98943 9.53829 3.74958 9.29837 3.74958 9.00257V5.57268C3.74958 5.27682 3.98943 5.03697 4.2853 5.03697ZM8.24958 5.57268C8.24958 5.27682 8.00974 5.03697 7.71387 5.03697C7.418 5.03697 7.17815 5.27682 7.17815 5.57268V9.00257C7.17815 9.29837 7.418 9.53829 7.71387 9.53829C8.00974 9.53829 8.24958 9.29837 8.24958 9.00257V5.57268Z"
                                                                        fill="currentColor"
                                                                    ></path>
                                                                </svg>
                                                            </span>
                                                            <span>Delete</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div
                                                className="dropdown__body dropdown-md"
                                                data-role="dropdown"
                                            >
                                                <ul className="dropdown-list">
                                                    <li>
                                                        <a
                                                            href="#"
                                                            className="btn size--sm btn-link-drp"
                                                        >
                                                            <span>
                                                                Show Item
                                                            </span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <Pagination
                    currentPage={1}
                    totalPages={10}
                    onPageChange={() => {}}
                />
            </div>
        </main>
    )
}
