/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

const TaskList = () => {
    return (
        <>
            <div className="calendar-header">
                <div className="flex items-center gap-20">
                    <div className="c-h-item">
                        <div className="calendar-title">
                            <div>Mar, 2024</div>
                            <span className="ico">
                                <svg
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M6.50008 0.833252V2.49992H11.5001V0.833252H13.1667V2.49992H16.5001C16.7211 2.49992 16.9331 2.58772 17.0893 2.744C17.2456 2.90028 17.3334 3.11224 17.3334 3.33325V16.6666C17.3334 16.8876 17.2456 17.0996 17.0893 17.2558C16.9331 17.4121 16.7211 17.4999 16.5001 17.4999H1.50008C1.27907 17.4999 1.06711 17.4121 0.910826 17.2558C0.754545 17.0996 0.666748 16.8876 0.666748 16.6666V3.33325C0.666748 3.11224 0.754545 2.90028 0.910826 2.744C1.06711 2.58772 1.27907 2.49992 1.50008 2.49992H4.83341V0.833252H6.50008ZM15.6667 9.16658H2.33341V15.8333H15.6667V9.16658ZM4.83341 4.16659H2.33341V7.49992H15.6667V4.16659H13.1667V5.83325H11.5001V4.16659H6.50008V5.83325H4.83341V4.16659Z"
                                        fill="currentColor"
                                    ></path>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div className="flex items-center gap-8">
                        <a
                            className="btn btn--navigation btn--square btn--xs rounded-full"
                            href="#"
                        >
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M4.16602 9.99992H15.8327"
                                    stroke="#7D8491"
                                    stroke-width="1.2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                ></path>
                                <path
                                    d="M8.33268 5.83325L4.16602 9.99992"
                                    stroke="#7D8491"
                                    stroke-width="1.2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                ></path>
                                <path
                                    d="M8.33268 14.1667L4.16602 10"
                                    stroke="#7D8491"
                                    stroke-width="1.2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                ></path>
                            </svg>
                        </a>
                        <a
                            className="btn btn--navigation btn--square btn--xs rounded-full"
                            href="#"
                        >
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M15.8327 9.99992H4.16602"
                                    stroke="#7D8491"
                                    stroke-width="1.2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                ></path>
                                <path
                                    d="M11.666 14.1667L15.8327 10"
                                    stroke="#7D8491"
                                    stroke-width="1.2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                ></path>
                                <path
                                    d="M11.666 5.83325L15.8327 9.99992"
                                    stroke="#7D8491"
                                    stroke-width="1.2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                ></path>
                            </svg>
                        </a>
                    </div>
                </div>
                <div className="c-h-item">
                    <label className="calendar-mode">
                        <label className="calendar-mode-item">
                            <input
                                type="radio"
                                name="mode-week"
                                checked
                                hidden
                            />
                            <div className="calendar-mode-btn btn">Day</div>
                        </label>
                        <label className="calendar-mode-item">
                            <input type="radio" name="mode-week" hidden />
                            <div className="calendar-mode-btn btn">Week</div>
                        </label>
                    </label>
                </div>
            </div>
            <div className="table-body table-responsive p-0 min-h-300">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Task</th>
                            <th style={{ width: 250 }}>Address</th>
                            <th>Client</th>
                            <th>Status</th>
                            <th style={{ width: 10 }}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Mar 10, 2024</td>
                            <td>12:00 AM</td>
                            <td>Installation of some thing </td>
                            <td className="text--12 text-color-1 text-wrap">
                                2118 Thornridge Cir. Syracuse, Connecticut 35624
                            </td>
                            <td>
                                <div className="flex items-center gap-8">
                                    <div className="user-28x28">
                                        <img
                                            src="/src/assets/img/user-1.png"
                                            alt="user"
                                        />
                                    </div>
                                    <span className="text--xs fw-500">
                                        Robert Fox
                                    </span>
                                </div>
                            </td>

                            <td>
                                <span className="pill pill-2 pill-warning">
                                    Pending
                                </span>
                            </td>

                            <td className="text-right sticky-end">
                                <div
                                    className="dropdown flex flex-col"
                                    data-position="top-end"
                                    data-dropdown="dropdown"
                                >
                                    <a
                                        href="#"
                                        className="btn dropdown-btn"
                                        data-role="button"
                                    >
                                        <span className="ico">
                                            <img
                                                src="/src/assets/img/dots.svg"
                                                alt="dots"
                                            />
                                        </span>
                                    </a>
                                    <div
                                        className="dropdown__body dropdown-profile-body"
                                        data-role="dropdown"
                                    >
                                        <ul className="dropdown-list-links">
                                            <li className="dropdown-list-link">
                                                <a
                                                    href="#"
                                                    className="btn btn--md 
                                text-left btn-drp-link justify-start"
                                                >
                                                    <span className="ico">
                                                        {/* <?php include "img/ico-view-profile.svg"; ?> */}
                                                    </span>
                                                    <span>View Profile</span>
                                                </a>
                                            </li>
                                            <li className="dropdown-list-link">
                                                <a
                                                    href="#"
                                                    className="btn btn--md 
                                text-left btn-drp-link justify-start"
                                                >
                                                    <span className="ico">
                                                        {/* <?php include "img/ico-edit.svg"; ?> */}
                                                    </span>
                                                    <span>Edit</span>
                                                </a>
                                            </li>
                                            <li className="dropdown-list-link">
                                                <a
                                                    href="#"
                                                    className="btn btn--md 
                                text-left btn-drp-link justify-start"
                                                >
                                                    <span className="ico">
                                                        {/* <?php include "img/ico-add-to-team.svg"; ?> */}
                                                    </span>
                                                    <span>Add to Team</span>
                                                </a>
                                            </li>
                                            <li className="dropdown-list-link">
                                                <a
                                                    href="#"
                                                    className="btn btn--md 
                                text-left btn-drp-link justify-start"
                                                >
                                                    <span className="ico">
                                                        {/* <?php include "img/ico-social-menu.svg"; ?> */}
                                                    </span>
                                                    <span>
                                                        Add to WorkGroup
                                                    </span>
                                                </a>
                                            </li>
                                            <li className="dropdown-list-link">
                                                <a
                                                    href="#"
                                                    className="btn btn--md 
                                text-left btn-drp-link justify-start 
                                btn-drp-link-danger"
                                                >
                                                    <span className="ico">
                                                        {/* <?php include "img/ico-delete.svg"; ?> */}
                                                    </span>
                                                    <span>Delete</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Mar 10, 2024</td>
                            <td>12:00 AM</td>
                            <td>
                                <span className="fw-500">
                                    Installation of some thing
                                </span>
                            </td>
                            <td className="text--12 text-color-1 text-wrap">
                                2118 Thornridge Cir. Syracuse, Connecticut 35624
                            </td>
                            <td>
                                <div className="flex items-center gap-8">
                                    <div className="user-28x28">
                                        <img
                                            src="/src/assets/img/user-1.png"
                                            alt="user"
                                        />
                                    </div>
                                    <span className="text--xs fw-500">
                                        Robert Fox
                                    </span>
                                </div>
                            </td>

                            <td>
                                <span className="pill pill-2 pill-success">
                                    Completed
                                </span>
                            </td>

                            <td className="text-right sticky-end">
                                <div
                                    className="dropdown flex flex-col"
                                    data-position="top-end"
                                    data-dropdown="dropdown"
                                >
                                    <a
                                        href="#"
                                        className="btn dropdown-btn"
                                        data-role="button"
                                    >
                                        <span className="ico">
                                            <img
                                                src="/src/assets/img/dots.svg"
                                                alt="dots"
                                            />
                                        </span>
                                    </a>
                                    <div
                                        className="dropdown__body dropdown-md"
                                        data-role="dropdown"
                                    >
                                        <ul className="dropdown-list">
                                            <li>
                                                <a
                                                    href="#"
                                                    className="btn size--sm btn-link-drp"
                                                >
                                                    <span>Show Item</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default TaskList
