/* eslint-disable jsx-a11y/label-has-associated-control */
import clsx from 'clsx'
import React, { useState } from 'react'

import CloseIcon from '../../assets/img/ico-close.svg?react'
import LoupeIcon from '../../assets/img/ico-loupe.svg?react'
import MagnifyIcon from '../../assets/img/ico-magnify.svg?react'

const SearchForm = () => {
    const [isSearchOpen, setIsSearhOpen] = useState(false)
    const handleSearchToggle = () => {
        setIsSearhOpen(!isSearchOpen)
    }
    return (
        <form
            className={clsx('search-form', {
                '--show-search': isSearchOpen,
            })}
        >
            <label className="input-wrapper input--xl input--shadow search-form-row full-radius">
                <label className="search-form-toggler input-wrapper search-form-row input--shadow input--xl full-radius">
                    <button
                        onClick={handleSearchToggle}
                        type="submit"
                        className="btn text-color-1 search-btn"
                    >
                        <span className="ico">
                            <MagnifyIcon />
                        </span>
                    </button>
                    <input
                        className="input font-2"
                        type="text"
                        placeholder="Search information..."
                    />
                </label>
                <button
                    type="button"
                    className="btn btn-tool search-btn toggle-btn-search"
                    onClick={handleSearchToggle}
                >
                    <span className="ico --show">
                        <LoupeIcon />
                    </span>
                    <span className="ico --hide">
                        <CloseIcon />
                    </span>
                </button>
            </label>
        </form>
    )
}

export default SearchForm
