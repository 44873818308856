import { createSlice } from '@reduxjs/toolkit'

import { authApi } from '../../services/auth'

interface AuthState {}
const initialState = {} satisfies AuthState as AuthState

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
            authApi.endpoints.login.matchFulfilled,
            (_, { payload }) => {
                if (payload.data) {
                    window.localStorage.setItem(
                        'authToken',
                        payload?.data?.token,
                    )
                }
            },
        )
        builder.addMatcher(authApi.endpoints.getMe.matchRejected, () => {
            window.localStorage.removeItem('authToken')
        })
        builder.addMatcher(
            authApi.endpoints.updateMe.matchFulfilled,
            (_, { payload }) => {
                if (payload?.token) {
                    window.localStorage.setItem('authToken', payload?.token)
                }
            },
        )
    },
})

export default authSlice.reducer
