import clsx from 'clsx'
import type { ReactNode } from 'react'
import React from 'react'

import RatingDownIcon from '../../../assets/img/ico-rate-down.svg?react'
import RatingUpIcon from '../../../assets/img/ico-rate-up.svg?react'
import LineChart from '../../../compoments/charts/LineChart'
import TrendChart from '../../../compoments/charts/TrendChart'

interface JobStatistics {
    label: string
    icon: ReactNode
    trend: 'up' | 'down'
    value: number
    percent: number
}

const ExecutivesWorkOrderKpisPage = () => {
    const jobStatistics: JobStatistics[] = [
        {
            label: 'Technicians',
            icon: (
                <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clip-path="url(#clip0_434_7330)">
                        <path
                            d="M1.5242 0.33374V10.4072H11.5976V11.6664H0.265015V0.33374H1.5242ZM10.9303 2.18474L12.265 3.51947L8.4497 7.33605L6.56092 5.44728L4.08034 7.92786L2.7456 6.59187L6.56092 2.77655L8.4497 4.66532L10.9303 2.18474Z"
                            fill="#7D8491"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_434_7330">
                            <rect width="12" height="12" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            ),
            trend: 'down',
            value: 500000,
            percent: 12,
        },
        {
            label: 'Order Volume',
            icon: (
                <svg
                    width="13"
                    height="12"
                    viewBox="0 0 13 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clip-path="url(#clip0_434_7349)">
                        <path
                            d="M11.9 4.2V11.3958C11.9006 11.4746 11.8856 11.5527 11.8559 11.6257C11.8263 11.6987 11.7826 11.7652 11.7272 11.8213C11.6719 11.8774 11.6061 11.9221 11.5335 11.9527C11.4609 11.9834 11.383 11.9995 11.3042 12H1.69581C1.53789 12 1.38644 11.9373 1.27472 11.8257C1.16301 11.7141 1.10017 11.5627 1.10001 11.4048V0.5952C1.10001 0.273 1.36821 0 1.69881 0H7.70001V3.6C7.70001 3.75913 7.76322 3.91174 7.87574 4.02426C7.98826 4.13679 8.14088 4.2 8.30001 4.2H11.9ZM11.9 3H8.90001V0.00180001L11.9 3ZM4.10001 3V4.2H5.90001V3H4.10001ZM4.10001 5.4V6.6H8.90001V5.4H4.10001ZM4.10001 7.8V9H8.90001V7.8H4.10001Z"
                            fill="#1C77FF"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_434_7349">
                            <rect
                                width="12"
                                height="12"
                                fill="white"
                                transform="translate(0.5)"
                            />
                        </clipPath>
                    </defs>
                </svg>
            ),
            trend: 'up',
            value: 112,
            percent: 12,
        },
        {
            label: 'Sales Revenue',
            icon: (
                <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clip-path="url(#clip0_434_7368)">
                        <path
                            d="M6 12C2.6862 12 0 9.3138 0 6C0 2.6862 2.6862 0 6 0C9.3138 0 12 2.6862 12 6C12 9.3138 9.3138 12 6 12ZM5.4018 8.4L9.6438 4.1574L8.796 3.309L5.4018 6.7032L3.7044 5.0058L2.856 5.8542L5.4018 8.4Z"
                            fill="#0BAA69"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_434_7368">
                            <rect width="12" height="12" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            ),
            trend: 'down',
            value: 112,
            percent: 12,
        },
    ]
    return (
        <main className="content flex flex-col">
            <div className="px-container flex flex-col flex-auto">
                <div className="row gutters-28">
                    <div className="col-12">
                        <div className="row gutters-18">
                            {jobStatistics.map((item, index) => (
                                <div
                                    key={index}
                                    className="col-xl-4 col-md-6 col-12"
                                >
                                    <div className="card card-shadow card-seo">
                                        <div className="card-body">
                                            <div className="card-seo-info flex-shrink-0">
                                                <div className="seo-title">
                                                    <span className="ico">
                                                        {item.icon}
                                                    </span>
                                                    <span className="name">
                                                        {item.label}
                                                    </span>
                                                </div>
                                                <h1 className="seo-stat-title">
                                                    {item.value}
                                                </h1>
                                            </div>
                                            <div
                                                className="card-seo-chart"
                                                style={{
                                                    height: 60,
                                                    width: 100,
                                                }}
                                            >
                                                <TrendChart
                                                    trend={item.trend}
                                                />
                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            <div className="seo-rating-row">
                                                <div
                                                    className={clsx(
                                                        'seo-rating',
                                                        `--${item.trend}`,
                                                    )}
                                                >
                                                    <span className="ico">
                                                        {item.trend === 'up' ? (
                                                            <RatingUpIcon />
                                                        ) : (
                                                            <RatingDownIcon />
                                                        )}
                                                    </span>{' '}
                                                    <span>
                                                        {item.trend === 'up'
                                                            ? '+'
                                                            : '-'}
                                                        {item.percent}%
                                                    </span>
                                                </div>
                                                <p className="seo-rating-desk">
                                                    <span>vs</span> yesterday
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="card card-shadow card-chart h-full">
                            <div className="card-header">
                                <div className="c-h-item">
                                    <div className="calendar-title">
                                        <div id="title">
                                            Mar 10, 2023 – Mar 10, 2024
                                        </div>
                                        <span className="ico">
                                            <svg
                                                width="18"
                                                height="18"
                                                viewBox="0 0 18 18"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M6.50008 0.833252V2.49992H11.5001V0.833252H13.1667V2.49992H16.5001C16.7211 2.49992 16.9331 2.58772 17.0893 2.744C17.2456 2.90028 17.3334 3.11224 17.3334 3.33325V16.6666C17.3334 16.8876 17.2456 17.0996 17.0893 17.2558C16.9331 17.4121 16.7211 17.4999 16.5001 17.4999H1.50008C1.27907 17.4999 1.06711 17.4121 0.910826 17.2558C0.754545 17.0996 0.666748 16.8876 0.666748 16.6666V3.33325C0.666748 3.11224 0.754545 2.90028 0.910826 2.744C1.06711 2.58772 1.27907 2.49992 1.50008 2.49992H4.83341V0.833252H6.50008ZM15.6667 9.16658H2.33341V15.8333H15.6667V9.16658ZM4.83341 4.16659H2.33341V7.49992H15.6667V4.16659H13.1667V5.83325H11.5001V4.16659H6.50008V5.83325H4.83341V4.16659Z"
                                                    fill="currentColor"
                                                ></path>
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                                <button className="btn">
                                    <span className="ico me-3">
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M9.1665 13.3334H10.8332V5.83335H13.3332L9.99984 1.66669L6.6665 5.83335H9.1665V13.3334Z"
                                                fill="#232ED1"
                                            ></path>
                                            <path
                                                d="M4.16667 18.3333H15.8333C16.7525 18.3333 17.5 17.5858 17.5 16.6667V9.16667C17.5 8.2475 16.7525 7.5 15.8333 7.5H12.5V9.16667H15.8333V16.6667H4.16667V9.16667H7.5V7.5H4.16667C3.2475 7.5 2.5 8.2475 2.5 9.16667V16.6667C2.5 17.5858 3.2475 18.3333 4.16667 18.3333Z"
                                                fill="#232ED1"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span className="color-primary">
                                        Export
                                    </span>
                                </button>
                            </div>
                            <div className="card-body">
                                <div
                                    className="canvas-wrapper flex-auto"
                                    style={{ height: 215 }}
                                >
                                    <LineChart
                                        values={Array.from({
                                            length: 60,
                                        }).map(
                                            (_) =>
                                                Math.random() *
                                                (6000 - 100 * Math.random()),
                                        )}
                                        labels={Array.from({
                                            length: 60,
                                        }).map((_, index) =>
                                            new Date(
                                                new Date().setDate(
                                                    new Date().getDay() + index,
                                                ),
                                            ).toLocaleDateString('en-EU', {
                                                day: 'numeric',
                                                month: 'short',
                                            }),
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="table-group">
                            <div className="table-body table-responsive p-0">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Period</th>
                                            <th>Selected Revenue</th>
                                            <th>Previous Revenue</th>
                                            <th>Selected Margin</th>
                                            <th>Previous Margin</th>
                                            <th>Revenue Discrepancy</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <span className="text-color-1">
                                                    Q1
                                                </span>
                                            </td>
                                            <td>$500,000</td>
                                            <td>$500,000</td>
                                            <td>$500,000</td>
                                            <td>$500,000</td>
                                            <td>$500,000</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="text-color-1">
                                                    Q2
                                                </span>
                                            </td>
                                            <td>$500,000</td>
                                            <td>$500,000</td>
                                            <td>$500,000</td>
                                            <td>$500,000</td>
                                            <td>$500,000</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="text-color-1">
                                                    Q3
                                                </span>
                                            </td>
                                            <td>$500,000</td>
                                            <td>$500,000</td>
                                            <td>$500,000</td>
                                            <td>$500,000</td>
                                            <td>$500,000</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="text-color-1">
                                                    Q4
                                                </span>
                                            </td>
                                            <td>$500,000</td>
                                            <td>$500,000</td>
                                            <td>$500,000</td>
                                            <td>$500,000</td>
                                            <td className="color-success fw-600">
                                                $500,000
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default ExecutivesWorkOrderKpisPage
