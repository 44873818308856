import React from 'react'
import { Outlet } from 'react-router-dom'

import GridsIcon from '../assets/img/ico-grids.svg?react'
import ServiceIco from '../assets/img/service-management.svg?react'
import UserManagementIco from '../assets/img/ico-user-management.svg?react'
import TeamManagementIco from '../assets/img/ico-team-management.svg?react'
import WorkGroupIco from '../assets/img/ico-work-group-management.svg?react'
import ChatIcon from '../assets/img/ico-chat.svg?react'
import Header from '../compoments/layout/Header'
import Sidebar from '../compoments/layout/Sidebar'

export const TenantAdminLayout = () => {
    const pages = [
        {
            icon: <GridsIcon />,
            title: 'Dashboard',
            url: '/tenant-admin-dashboard',
        },
        {
            icon: <ServiceIco />,
            title: 'Service Management',
            url: '/tenant-admin-service-management',
        },
        {
            icon: <UserManagementIco />,
            title: 'User Management',
            url: '/tenant-admin-user-management',
        },
        {
            icon: <TeamManagementIco />,
            title: 'Team Management',
            url: '/tenant-admin-team-management',
        },
        {
            icon: <WorkGroupIco />,
            title: 'WorkGroup Management',
            url: '/tenant-admin-workgroup-management',
        },
        {
            icon: <ChatIcon />,
            title: 'Chat',
            url: '/tenant-admin-chat',
        },
    ]
    return (
        <>
            <>
                <Header />
                <Sidebar pages={pages} />
                <div className="wrapper header-fixed sidebar-indentation">
                    <Outlet />
                </div>
            </>
        </>
    )
}
