import Avatar from '../../../assets/img/user-1.png'
import DotsSvg from '../../../assets/img/dots.svg'

export const AdminTeamManagement = () => {
    return (
        <main className="content">
            <div className="px-container">
                <div className="table-group">
                    <div className="table-header flex items-center justify-between">
                        <div className="table-tools">
                            <div className="form-group--row select--outline input--sm min-w-120">
                                <select
                                    className="select-default select2-hidden-accessible"
                                    data-select2-id="select2-data-1-w722"
                                    aria-hidden="true"
                                >
                                    <option
                                        value=""
                                        data-select2-id="select2-data-3-jno9"
                                    >
                                        Status
                                    </option>
                                    <option>option 1</option>
                                    <option>option 2</option>
                                    <option>option 3</option>
                                </select>
                                <span
                                    className="select2 select2-container select2-container--default"
                                    dir="ltr"
                                    data-select2-id="select2-data-2-9bi0"
                                    style={{ width: '48px' }}
                                >
                                    <span className="selection">
                                        <span
                                            className="select2-selection select2-selection--single"
                                            role="combobox"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            tabIndex={0}
                                            aria-disabled="false"
                                            aria-labelledby="select2-4maw-container"
                                            aria-controls="select2-4maw-container"
                                        >
                                            <span
                                                className="select2-selection__rendered"
                                                id="select2-4maw-container"
                                                role="textbox"
                                                aria-readonly="true"
                                                title="Status"
                                            >
                                                Status
                                            </span>
                                            <span
                                                className="select2-selection__arrow"
                                                role="presentation"
                                            >
                                                <b role="presentation"></b>
                                            </span>
                                        </span>
                                    </span>
                                    <span
                                        className="dropdown-wrapper"
                                        aria-hidden="true"
                                    ></span>
                                </span>
                            </div>
                        </div>

                        <a
                            href="#"
                            className="btn btn--primary-gradient btn--sm fullRadius"
                        >
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M15.8327 9.16675H10.8327V4.16675H9.16602V9.16675H4.16602V10.8334H9.16602V15.8334H10.8327V10.8334H15.8327V9.16675Z"
                                    fill="white"
                                ></path>
                            </svg>
                            <span className="text--xs">Create Team</span>
                        </a>
                    </div>

                    <div className="table-body table-responsive p-0">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Team</th>
                                    <th>Team Lead</th>
                                    <th style={{ width: '100%' }}>Users</th>
                                    <th>Status</th>
                                    <th style={{ width: '10px' }}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Team Name 1</td>
                                    <td>
                                        <div className="flex items-center gap-8">
                                            <div className="user-28x28">
                                                <img src={Avatar} alt="" />
                                            </div>
                                            <div className="flex flex-col">
                                                <span className="text--xs fw-500">
                                                    Eleanor Pena
                                                </span>
                                                <span className="text--12 text-color-1">
                                                    tanya.hill@example.com
                                                </span>
                                            </div>
                                        </div>
                                    </td>

                                    <td>
                                        <div className="user-list-group">
                                            <ul className="user-list">
                                                <li>
                                                    <div className="user-28x28">
                                                        <img
                                                            src={Avatar}
                                                            alt=""
                                                        />
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="user-28x28">
                                                        <img
                                                            src={Avatar}
                                                            alt=""
                                                        />
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="user-28x28">
                                                        <img
                                                            src={Avatar}
                                                            alt=""
                                                        />
                                                    </div>
                                                </li>
                                            </ul>
                                            <span className="user-cunter">
                                                +3
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <span className="pill pill-success">
                                            Active
                                        </span>
                                    </td>
                                    <td className="text-right sticky-end">
                                        <div
                                            className="dropdown flex flex-col"
                                            data-position="top-end"
                                            data-dropdown="dropdown"
                                        >
                                            <a
                                                href="#"
                                                className="btn dropdown-btn"
                                                data-role="button"
                                            >
                                                <span className="ico">
                                                    <img src={DotsSvg} alt="" />
                                                </span>
                                            </a>
                                            <div
                                                className="dropdown__body dropdown-md"
                                                data-role="dropdown"
                                            >
                                                <ul className="dropdown-list">
                                                    <li>
                                                        <a
                                                            href="#"
                                                            className="btn size--sm btn-link-drp"
                                                        >
                                                            <span>
                                                                Show Item
                                                            </span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="table-footer">
                    <span className="page-cunter">Page 1 of 25</span>
                    <ul className="pagination">
                        <li className="page-item active">
                            <a className="page-link" href="#">
                                1
                            </a>
                        </li>
                        <li className="page-item">
                            <a className="page-link" href="#">
                                2
                            </a>
                        </li>
                        <li className="page-item">
                            <a className="page-link" href="#">
                                3
                            </a>
                        </li>
                        <li className="page-item-dots">...</li>
                        <li className="page-item">
                            <a className="page-link" href="#">
                                8
                            </a>
                        </li>
                        <li className="page-item">
                            <a className="page-link" href="#">
                                9
                            </a>
                        </li>
                    </ul>

                    <div className="flex items-center gap-8">
                        <a
                            className="btn btn--navigation btn--square btn--xs rounded-full"
                            href="#"
                        >
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M4.16602 9.99992H15.8327"
                                    stroke="#7D8491"
                                    strokeWidth="1.2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                ></path>
                                <path
                                    d="M8.33268 5.83325L4.16602 9.99992"
                                    stroke="#7D8491"
                                    strokeWidth="1.2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                ></path>
                                <path
                                    d="M8.33268 14.1667L4.16602 10"
                                    stroke="#7D8491"
                                    strokeWidth="1.2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                ></path>
                            </svg>
                        </a>
                        <a
                            className="btn btn--navigation btn--square btn--xs rounded-full"
                            href="#"
                        >
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M15.8327 9.99992H4.16602"
                                    stroke="#7D8491"
                                    strokeWidth="1.2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                ></path>
                                <path
                                    d="M11.666 14.1667L15.8327 10"
                                    stroke="#7D8491"
                                    strokeWidth="1.2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                ></path>
                                <path
                                    d="M11.666 5.83325L15.8327 9.99992"
                                    stroke="#7D8491"
                                    strokeWidth="1.2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                ></path>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </main>
    )
}
