import clsx from 'clsx'
import type { ReactNode } from 'react'

import RatingDownIcon from '../../../assets/img/ico-rate-down.svg?react'
import RatingUpIcon from '../../../assets/img/ico-rate-up.svg?react'
import BarChart from '../../../compoments/charts/BarChart'
import CircleProgressBar from '../../../compoments/charts/CircleProgressBar'
import DoughnutChart from '../../../compoments/charts/DoughnutChart'
import HorizontalBarChart from '../../../compoments/charts/HorizontalBarChart'
import TrendChart from '../../../compoments/charts/TrendChart'
import SelectBox from '../../../compoments/common/SelectBox'

interface JobStatistics {
    label: string
    icon: ReactNode
    trend: 'up' | 'down'
    value: number
    percent: number
}

const ExecutivesDashboardPage = () => {
    const jobStatistics: JobStatistics[] = [
        {
            label: 'Revenue Generated',
            icon: (
                <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clip-path="url(#clip0_434_7330)">
                        <path
                            d="M1.5242 0.33374V10.4072H11.5976V11.6664H0.265015V0.33374H1.5242ZM10.9303 2.18474L12.265 3.51947L8.4497 7.33605L6.56092 5.44728L4.08034 7.92786L2.7456 6.59187L6.56092 2.77655L8.4497 4.66532L10.9303 2.18474Z"
                            fill="#7D8491"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_434_7330">
                            <rect width="12" height="12" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            ),
            trend: 'down',
            value: 500000,
            percent: 12,
        },
        {
            label: 'Orders Created',
            icon: (
                <svg
                    width="13"
                    height="12"
                    viewBox="0 0 13 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clip-path="url(#clip0_434_7349)">
                        <path
                            d="M11.9 4.2V11.3958C11.9006 11.4746 11.8856 11.5527 11.8559 11.6257C11.8263 11.6987 11.7826 11.7652 11.7272 11.8213C11.6719 11.8774 11.6061 11.9221 11.5335 11.9527C11.4609 11.9834 11.383 11.9995 11.3042 12H1.69581C1.53789 12 1.38644 11.9373 1.27472 11.8257C1.16301 11.7141 1.10017 11.5627 1.10001 11.4048V0.5952C1.10001 0.273 1.36821 0 1.69881 0H7.70001V3.6C7.70001 3.75913 7.76322 3.91174 7.87574 4.02426C7.98826 4.13679 8.14088 4.2 8.30001 4.2H11.9ZM11.9 3H8.90001V0.00180001L11.9 3ZM4.10001 3V4.2H5.90001V3H4.10001ZM4.10001 5.4V6.6H8.90001V5.4H4.10001ZM4.10001 7.8V9H8.90001V7.8H4.10001Z"
                            fill="#1C77FF"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_434_7349">
                            <rect
                                width="12"
                                height="12"
                                fill="white"
                                transform="translate(0.5)"
                            />
                        </clipPath>
                    </defs>
                </svg>
            ),
            trend: 'up',
            value: 112,
            percent: 12,
        },
        {
            label: 'Completed',
            icon: (
                <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clip-path="url(#clip0_434_7368)">
                        <path
                            d="M6 12C2.6862 12 0 9.3138 0 6C0 2.6862 2.6862 0 6 0C9.3138 0 12 2.6862 12 6C12 9.3138 9.3138 12 6 12ZM5.4018 8.4L9.6438 4.1574L8.796 3.309L5.4018 6.7032L3.7044 5.0058L2.856 5.8542L5.4018 8.4Z"
                            fill="#0BAA69"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_434_7368">
                            <rect width="12" height="12" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            ),
            trend: 'down',
            value: 112,
            percent: 12,
        },
        {
            label: 'Satisfaction Score',
            icon: (
                <svg
                    width="13"
                    height="12"
                    viewBox="0 0 13 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clip-path="url(#clip0_434_7387)">
                        <path
                            d="M1.04545 4.40549H2.68182V10.9509H1.04545C0.900791 10.9509 0.762052 10.8935 0.65976 10.7912C0.557467 10.6889 0.5 10.5502 0.5 10.4055V4.95094C0.5 4.80628 0.557467 4.66754 0.65976 4.56525C0.762052 4.46296 0.900791 4.40549 1.04545 4.40549ZM3.93255 3.70022L7.42345 0.209308C7.46985 0.162777 7.53149 0.134562 7.59703 0.129852C7.66257 0.125142 7.72761 0.144253 7.78018 0.183671L8.24545 0.532762C8.37467 0.629769 8.47228 0.762864 8.52596 0.915265C8.57964 1.06767 8.58699 1.23255 8.54709 1.38913L7.91818 3.86003H11.4091C11.6984 3.86003 11.9759 3.97497 12.1805 4.17955C12.3851 4.38414 12.5 4.66162 12.5 4.95094V6.09858C12.5001 6.24114 12.4723 6.38235 12.4182 6.51422L10.73 10.6133C10.6888 10.7132 10.6189 10.7987 10.529 10.8588C10.4392 10.9189 10.3335 10.951 10.2255 10.9509H4.31818C4.17352 10.9509 4.03478 10.8935 3.93249 10.7912C3.83019 10.6889 3.77273 10.5502 3.77273 10.4055V4.08585C3.77276 3.9412 3.83025 3.80248 3.93255 3.70022Z"
                            fill="#FFAB07"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_434_7387">
                            <rect
                                width="12"
                                height="12"
                                fill="white"
                                transform="translate(0.5)"
                            />
                        </clipPath>
                    </defs>
                </svg>
            ),
            trend: 'up',
            value: 112,
            percent: 12,
        },
    ]

    const options = [
        {
            label: 'for 1 week',
            value: 'for 1 week',
        },
        {
            label: 'for 1 month',
            value: 'for 1 month',
        },
        {
            label: 'for 1 year',
            value: 'for 1 year',
        },
    ]
    return (
        <main className="content">
            <div className="px-container">
                <div className="row gutters-28">
                    <div className="col-12">
                        <div className="row gutters-18">
                            {jobStatistics.map((item, index) => (
                                <div
                                    key={index}
                                    className="col-xl-3 col-md-6 col-12"
                                >
                                    <div className="card card-shadow card-seo">
                                        <div className="card-body">
                                            <div className="card-seo-info flex-shrink-0">
                                                <div className="seo-title">
                                                    <span className="ico">
                                                        {item.icon}
                                                    </span>
                                                    <span className="name">
                                                        {item.label}
                                                    </span>
                                                </div>
                                                <h1 className="seo-stat-title">
                                                    {item.value}
                                                </h1>
                                            </div>
                                            <div
                                                className="card-seo-chart"
                                                style={{
                                                    height: 60,
                                                    width: 100,
                                                }}
                                            >
                                                <TrendChart
                                                    trend={item.trend}
                                                />
                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            <div className="seo-rating-row">
                                                <div
                                                    className={clsx(
                                                        'seo-rating',
                                                        `--${item.trend}`,
                                                    )}
                                                >
                                                    <span className="ico">
                                                        {item.trend === 'up' ? (
                                                            <RatingUpIcon />
                                                        ) : (
                                                            <RatingDownIcon />
                                                        )}
                                                    </span>{' '}
                                                    <span>
                                                        {item.trend === 'up'
                                                            ? '+'
                                                            : '-'}
                                                        {item.percent}%
                                                    </span>
                                                </div>
                                                <p className="seo-rating-desk">
                                                    <span>vs</span> yesterday
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="row gutters-18">
                            <div className="col-lg-6">
                                <div className="card card-shadow card-chart h-full">
                                    <div className="card-header">
                                        <div className="card-chart-title text--xl">
                                            Revenue Analysis
                                        </div>
                                        <SelectBox
                                            wrapperClassName="select--transparent"
                                            options={options}
                                        />
                                    </div>
                                    <div className="card-body">
                                        <div
                                            className="canvas-wrapper flex-auto"
                                            style={{ height: 215 }}
                                        >
                                            <BarChart
                                                labels={Array.from({
                                                    length: 4,
                                                }).map((_, index) =>
                                                    new Date(
                                                        new Date().setDate(
                                                            new Date().getDay() +
                                                                index,
                                                        ),
                                                    ).toLocaleDateString(
                                                        'en-EU',
                                                        {
                                                            day: 'numeric',
                                                            month: 'short',
                                                        },
                                                    ),
                                                )}
                                                values={[
                                                    [
                                                        1000, 2000, 3000, 4000,
                                                        5000, 6000, 7000,
                                                    ],
                                                    [
                                                        1500, 2500, 3500, 4500,
                                                        500500, 6500, 7500,
                                                    ],
                                                    [
                                                        2000, 3000, 4000, 5000,
                                                        6000, 7000, 8000,
                                                    ],
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="card card-shadow card-chart h-full">
                                    <div className="card-header">
                                        <div className="card-chart-title text--xl">
                                            Work Order Analysis
                                        </div>
                                        <SelectBox
                                            wrapperClassName="select--transparent"
                                            options={options}
                                        />
                                    </div>
                                    <div className="card-body">
                                        <DoughnutChart
                                            labels={[
                                                'Open 35%',
                                                'In Progress 15%',
                                                'Completed 50%',
                                            ]}
                                            values={[68, 40, 100]}
                                            width={210}
                                            height={210}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="row gutters-18">
                            <div className="col-xl-4 col-md-6">
                                <div className="card card-shadow card-chart h-full">
                                    <div className="card-header">
                                        <div className="card-chart-title text--xl">
                                            Feedback
                                        </div>
                                        <SelectBox
                                            wrapperClassName="select--transparent"
                                            options={options}
                                        />
                                    </div>
                                    <div className="card-body">
                                        <DoughnutChart
                                            labels={[
                                                'Positive',
                                                'Neutral',
                                                'Negative',
                                            ]}
                                            values={[600, 350, 50]}
                                            units="percents"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6">
                                <div className="card card-shadow card-chart h-full">
                                    <div className="card-header">
                                        <div className="card-chart-title text--xl">
                                            Jobs by Zone
                                        </div>
                                        <SelectBox
                                            wrapperClassName="select--transparent"
                                            options={options}
                                        />
                                    </div>
                                    <div className="card-body">
                                        <HorizontalBarChart
                                            labels={Array.from({
                                                length: 4,
                                            }).map((_, index) =>
                                                new Date(
                                                    new Date().setDate(
                                                        new Date().getDay() +
                                                            index,
                                                    ),
                                                ).toLocaleDateString('en-EU', {
                                                    day: 'numeric',
                                                    month: 'short',
                                                }),
                                            )}
                                            values={[1000, 1200, 800, 300, 900]}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6">
                                <div className="card card-shadow card-chart h-full">
                                    <div className="card-header">
                                        <div className="card-chart-title text--xl">
                                            Total Transactions
                                        </div>
                                        <SelectBox
                                            wrapperClassName="select--transparent"
                                            options={options}
                                        />
                                    </div>
                                    <div className="card-body flex items-center">
                                        <div className="pie-charts-group">
                                            <div className="pie-charts-group__item">
                                                <div className="pie-charts-group__chart">
                                                    <CircleProgressBar
                                                        progressColor={
                                                            '#AC75F4'
                                                        }
                                                        percentageComplete={91}
                                                    />
                                                </div>
                                                <div className="pie-charts-group__title">
                                                    Job Completion Rate
                                                </div>
                                            </div>
                                            <div className="pie-charts-group__item">
                                                <div className="pie-charts-group__chart">
                                                    <CircleProgressBar
                                                        progressColor={
                                                            '#F54BA6'
                                                        }
                                                        percentageComplete={50}
                                                    />
                                                </div>
                                                <div className="pie-charts-group__title">
                                                    First-Time Fix Rate
                                                </div>
                                            </div>
                                            <div className="pie-charts-group__item">
                                                <CircleProgressBar
                                                    progressColor={'#FF4433'}
                                                    percentageComplete={91}
                                                />
                                                <div className="pie-charts-group__title">
                                                    Average Job Duration
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default ExecutivesDashboardPage
