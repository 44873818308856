/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import DotsIcon from '../../../assets/img/dots.svg?react'
import avatar from '../../../assets/img/user-1.png'
import Pagination from '../../../compoments/common/Pagination'
import SelectBox from '../../../compoments/common/SelectBox'

const ExecutivesTechnicianPerformancePage = () => {
    return (
        <main className="content">
            <div className="px-container">
                <div className="table-group">
                    <div className="table-header flex items-center justify-between">
                        <div className="table-tools">
                            <SelectBox
                                wrapperClassName="select--outline input--sm"
                                placeholder="Skills"
                                options={[]}
                            />
                            <SelectBox
                                wrapperClassName="select--outline input--sm"
                                placeholder="Certificates"
                                options={[]}
                            />
                            <SelectBox
                                wrapperClassName="select--outline input--sm"
                                placeholder="Status"
                                options={[]}
                            />
                            <SelectBox
                                wrapperClassName="select--outline input--sm"
                                placeholder="Rating"
                                options={[]}
                            />
                            <SelectBox
                                wrapperClassName="select--outline input--sm"
                                placeholder="Jobs Completed"
                                options={[]}
                            />
                        </div>
                    </div>

                    <div className="table-body table-responsive p-0">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Full Name</th>
                                    <th>Phone</th>
                                    <th>Job & Company</th>
                                    <th style={{ minWidth: 125 }}>Skills</th>
                                    <th style={{ minWidth: 145 }}>
                                        Certificates
                                    </th>
                                    <th>Status</th>
                                    <th>Rating</th>
                                    <th>Jobs Completed</th>
                                    <th style={{ width: 10 }}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="flex items-center gap-8">
                                            <div className="user-28x28">
                                                <img src={avatar} alt="user" />
                                            </div>
                                            <div className="flex flex-col">
                                                <span className="text--xs fw-500">
                                                    Floyd Miles
                                                </span>
                                                <span className="text--12 text-color-1">
                                                    tanya.hill@example.com
                                                </span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <span className="text--xs">
                                            (302) 555-0107
                                        </span>
                                    </td>
                                    <td>
                                        <div className="flex flex-col">
                                            <span className="text--xs fw-500">
                                                Floyd Miles
                                            </span>
                                            <span className="text--12 text-color-1">
                                                tanya.hill@example.com
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <span className="text--xs text-color-1 text-wrap">
                                            Plumbing, Electrical Repair{' '}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="text--xs text-color-1 text-wrap">
                                            Certified Electrician, HVAC
                                            Technician
                                        </span>
                                    </td>
                                    <td>
                                        <span className="pill pill-on-leave">
                                            On Leave
                                        </span>
                                    </td>
                                    <td>
                                        <div className="flex flex-col">
                                            <span className="text--xs fw-500">
                                                639
                                            </span>
                                            <span className="text--12 text-color-1">
                                                Rank #7
                                            </span>
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        <span className="fw-600 text--xs color-primary">
                                            49
                                        </span>
                                    </td>

                                    <td className="text-right sticky-end">
                                        <div
                                            className="dropdown flex flex-col"
                                            data-position="top-end"
                                            data-dropdown="dropdown"
                                        >
                                            <a
                                                href="#"
                                                className="btn dropdown-btn"
                                                data-role="button"
                                            >
                                                <span className="ico">
                                                    <DotsIcon />
                                                </span>
                                            </a>
                                            <div
                                                className="dropdown__body dropdown-md"
                                                data-role="dropdown"
                                            >
                                                <ul className="dropdown-list">
                                                    <li>
                                                        <a
                                                            href="#"
                                                            className="btn size--sm btn-link-drp"
                                                        >
                                                            <span>
                                                                Show Item
                                                            </span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="flex items-center gap-8">
                                            <div className="user-28x28">
                                                <img src={avatar} alt="user" />
                                            </div>
                                            <div className="flex flex-col">
                                                <span className="text--xs fw-500">
                                                    Floyd Miles
                                                </span>
                                                <span className="text--12 text-color-1">
                                                    tanya.hill@example.com
                                                </span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <span className="text--xs">
                                            (302) 555-0107
                                        </span>
                                    </td>
                                    <td>
                                        <div className="flex flex-col">
                                            <span className="text--xs fw-500">
                                                Floyd Miles
                                            </span>
                                            <span className="text--12 text-color-1">
                                                tanya.hill@example.com
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <span className="text--xs text-color-1 text-wrap">
                                            Plumbing, Electrical Repair{' '}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="text--xs text-color-1 text-wrap">
                                            Certified Electrician, HVAC
                                            Technician
                                        </span>
                                    </td>
                                    <td>
                                        <span className="pill pill-busy">
                                            Busy
                                        </span>
                                    </td>
                                    <td>
                                        <div className="flex flex-col">
                                            <span className="text--xs fw-500">
                                                639
                                            </span>
                                            <span className="text--12 text-color-1">
                                                Rank #7
                                            </span>
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        <span className="fw-600 text--xs color-primary">
                                            49
                                        </span>
                                    </td>

                                    <td className="text-right sticky-end">
                                        <div
                                            className="dropdown flex flex-col"
                                            data-position="top-end"
                                            data-dropdown="dropdown"
                                        >
                                            <a
                                                href="#"
                                                className="btn dropdown-btn"
                                                data-role="button"
                                            >
                                                <span className="ico">
                                                    <DotsIcon />
                                                </span>
                                            </a>
                                            <div
                                                className="dropdown__body dropdown-md"
                                                data-role="dropdown"
                                            >
                                                <ul className="dropdown-list">
                                                    <li>
                                                        <a
                                                            href="#"
                                                            className="btn size--sm btn-link-drp"
                                                        >
                                                            <span>
                                                                Show Item
                                                            </span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="flex items-center gap-8">
                                            <div className="user-28x28">
                                                <img src={avatar} alt="user" />
                                            </div>
                                            <div className="flex flex-col">
                                                <span className="text--xs fw-500">
                                                    Floyd Miles
                                                </span>
                                                <span className="text--12 text-color-1">
                                                    tanya.hill@example.com
                                                </span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <span className="text--xs">
                                            (302) 555-0107
                                        </span>
                                    </td>
                                    <td>
                                        <div className="flex flex-col">
                                            <span className="text--xs fw-500">
                                                Floyd Miles
                                            </span>
                                            <span className="text--12 text-color-1">
                                                tanya.hill@example.com
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <span className="text--xs text-color-1 text-wrap">
                                            Plumbing, Electrical Repair{' '}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="text--xs text-color-1 text-wrap">
                                            Certified Electrician, HVAC
                                            Technician
                                        </span>
                                    </td>
                                    <td>
                                        <span className="pill pill-success">
                                            Active
                                        </span>
                                    </td>
                                    <td>
                                        <div className="flex flex-col">
                                            <span className="text--xs fw-500">
                                                639
                                            </span>
                                            <span className="text--12 text-color-1">
                                                Rank #7
                                            </span>
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        <span className="fw-600 text--xs color-primary">
                                            49
                                        </span>
                                    </td>

                                    <td className="text-right sticky-end">
                                        <div
                                            className="dropdown flex flex-col"
                                            data-position="top-end"
                                            data-dropdown="dropdown"
                                        >
                                            <a
                                                href="#"
                                                className="btn dropdown-btn"
                                                data-role="button"
                                            >
                                                <span className="ico">
                                                    <DotsIcon />
                                                </span>
                                            </a>
                                            <div
                                                className="dropdown__body dropdown-md"
                                                data-role="dropdown"
                                            >
                                                <ul className="dropdown-list">
                                                    <li>
                                                        <a
                                                            href="#"
                                                            className="btn size--sm btn-link-drp"
                                                        >
                                                            <span>
                                                                Show Item
                                                            </span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <Pagination
                    currentPage={1}
                    totalPages={10}
                    onPageChange={() => {}}
                />
            </div>
        </main>
    )
}

export default ExecutivesTechnicianPerformancePage
