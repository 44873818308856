/* eslint-disable jsx-a11y/label-has-associated-control */
import clsx from 'clsx'
import type { ReactNode } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'

import CaretSidebarIcon2 from '../../assets/img/caret-sidebar-2.svg?react'
import CaretSidebarIcon from '../../assets/img/caret-sidebar.svg?react'
import GearIcon from '../../assets/img/ico-gear.svg?react'
import LogoutIcon from '../../assets/img/ico-logout.svg?react'
import logo from '../../assets/img/logo.svg'
import { useLazyLogoutQuery } from '../../services/auth'

interface SidebarProps {
    pages: {
        icon: ReactNode
        title: string
        url: string
    }[]
}

const Sidebar = ({ pages }: SidebarProps) => {
    const navigate = useNavigate()
    const handleToggleSidebar = () => {
        document.querySelector('body')?.classList.toggle('--hide-sidebar')
    }
    const [logout] = useLazyLogoutQuery()
    const handleLogout = () => {
        logout()
        navigate('/login')
    }
    return (
        <div className="sidebar-wrapper">
            <div className="sidebar-wrapp-toggler">
                <button
                    onClick={handleToggleSidebar}
                    type="button"
                    className="btn btn-toggle-sidebar"
                >
                    <span className="ico">
                        <CaretSidebarIcon />
                    </span>
                </button>
            </div>

            <aside className="sidebar">
                <div className="sidebar-header sidebar-container--item">
                    <Link to={'/'} className="logo">
                        <img src={logo} alt="logo" />
                    </Link>
                </div>

                <button
                    onClick={handleToggleSidebar}
                    type="button"
                    className="btn btn--md btn-sidebar-link sidebar-container--item btn-toggle-sidebar"
                >
                    <span className="link-ico">
                        <span className="ico">
                            <CaretSidebarIcon2 />
                        </span>
                    </span>
                </button>

                <div className="sidebar-body">
                    <div className="sidebar-menus">
                        <nav className="sidebar-menu">
                            <label className="sidebar-title-menu sidebar-container--item">
                                <span>Menu</span>
                            </label>
                            <ul className="sidebar-list-menu">
                                {pages.map((page, index) => (
                                    <li key={index} className="s-l-m-item">
                                        <NavLink
                                            to={page.url}
                                            className={({ isActive }) =>
                                                clsx(
                                                    'btn btn--md btn-sidebar-link',
                                                    {
                                                        '--active-page':
                                                            isActive,
                                                    },
                                                )
                                            }
                                        >
                                            <span className="link-ico">
                                                <span className="ico">
                                                    {page.icon}
                                                </span>
                                            </span>
                                            <span className="link-name">
                                                {page.title}
                                            </span>
                                        </NavLink>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                        <nav className="sidebar-menu mt-auto">
                            <ul className="sidebar-list-menu">
                                <li className="s-l-m-item">
                                    <NavLink
                                        to={'/settings'}
                                        className={({ isActive }) =>
                                            clsx(
                                                'btn btn--md btn-sidebar-link sidebar-container--item',
                                                { '--active-page': isActive },
                                            )
                                        }
                                    >
                                        <span className="link-ico">
                                            <span className="ico">
                                                <GearIcon />
                                            </span>
                                        </span>
                                        <span className="link-name">
                                            Settings
                                        </span>
                                    </NavLink>
                                </li>
                                <li className="s-l-m-item">
                                    <button
                                        type="button"
                                        onClick={handleLogout}
                                        className="btn btn--md btn-sidebar-link sidebar-container--item"
                                    >
                                        <span className="link-ico">
                                            <span className="ico">
                                                <LogoutIcon />
                                            </span>
                                        </span>
                                        <span className="link-name">
                                            Log Out
                                        </span>
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </aside>
        </div>
    )
}

export default Sidebar
