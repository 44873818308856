/* eslint-disable jsx-a11y/anchor-is-valid */

import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import type { InferType } from 'yup'
import { object, string } from 'yup'

import Input from '../../compoments/common/Input'
import { useLoginMutation } from '../../services/auth'

const schema = object({
    password: string().required('Password is required'),
    email: string().email('Invalid email format').required('Email is required'),
})
type FormType = InferType<typeof schema>

const LoginPage = () => {
    const navigate = useNavigate()
    const [login] = useLoginMutation()
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormType>({
        resolver: yupResolver(schema),
    })

    const onSubmit = async (data: FormType) => {
        console.log('Form data submitted:', data)
        try {
            const res = await login(data)
            if (res) {
                toast.success('Login successfully!')
                navigate('/')
            } else {
                toast.error('Something went wrong')
            }
        } catch (error) {
            console.error('rejected', error)
        }
    }

    return (
        <form method="post" onSubmit={handleSubmit(onSubmit)} className="form">
            <div className="form-header form-header-col">
                <div className="heading-block text-center">
                    <h1 className="login-box-msg mb-22">Login</h1>
                    <p className="text-description text--md mb-42">
                        Enter your credentials to access your account.
                    </p>
                </div>
            </div>
            <div className="form-body ">
                <div className="row mb-22">
                    <div className="col-12 mb-22">
                        <Input
                            label="Email address"
                            className="input input--outline  w-full"
                            type="text"
                            placeholder=""
                            error={errors.email?.message}
                            {...register('email')}
                        />
                    </div>
                    <div className="col-12 mb-22">
                        <Input
                            label="Password"
                            afterLabel={
                                <Link
                                    to={'/forgot-password'}
                                    className="btn btn-link text--sm"
                                >
                                    <span className="">Forgot Password?</span>
                                </Link>
                            }
                            className="input input--outline  w-full"
                            type="password"
                            placeholder=""
                            error={errors.password?.message}
                            {...register('password')}
                        />
                    </div>
                    <div className="col-12 ">
                        <label className="custom-checkbox">
                            <input
                                type="checkbox"
                                className="custom-checkbox__input"
                            />
                            <span className="custom-checkbox__input-fake">
                                <span className="icon-ico-check"></span>
                            </span>
                            <span className="custom-checkbox__label">
                                Remember Me
                            </span>
                        </label>
                    </div>
                </div>
            </div>
            <div className="form-footer form-footer-col mb-24">
                <div className="items-12 flex flex-col">
                    <button
                        type="submit"
                        className="btn btn--primary-gradient btn--lg w-full fullRadius"
                    >
                        <span>Login</span>
                    </button>
                </div>
            </div>
            <p className="text-description text--xs gap-10 flex justify-center">
                Do not have an account?
                <a href="#" className="color-primary fw-500">
                    Create Account
                </a>
            </p>
        </form>
    )
}

export default LoginPage
