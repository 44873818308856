import clsx from 'clsx'

import cityJobsIcon from '../../../assets/img/ico-city-job.svg'
import closedJobsIcon from '../../../assets/img/ico-closed-job.svg'
import jobsIcon from '../../../assets/img/ico-jobs.svg'
import openJobsIcon from '../../../assets/img/ico-open-jobs.svg'
import RatingDownIcon from '../../../assets/img/ico-rate-down.svg?react'
import RatingUpIcon from '../../../assets/img/ico-rate-up.svg?react'
import DoughnutChart from '../../../compoments/charts/DoughnutChart'
import HorizontalBarChart from '../../../compoments/charts/HorizontalBarChart'
import LineChart from '../../../compoments/charts/LineChart'
import StackedBarChart from '../../../compoments/charts/StackedBarChart'
import TrendChart from '../../../compoments/charts/TrendChart'
import SelectBox from '../../../compoments/common/SelectBox'

interface JobStatistics {
    label: string
    icon: string
    trend: 'up' | 'down'
    value: number
    percent: number
}

const DashboardPage = () => {
    const jobStatistics: JobStatistics[] = [
        {
            label: 'Jobs',
            icon: jobsIcon,
            trend: 'down',
            value: 112,
            percent: 12,
        },
        {
            label: 'Open Jobs',
            icon: openJobsIcon,
            trend: 'up',
            value: 112,
            percent: 12,
        },
        {
            label: 'KIV Jobs',
            icon: cityJobsIcon,
            trend: 'down',
            value: 112,
            percent: 12,
        },
        {
            label: 'Closed Jobs',
            icon: closedJobsIcon,
            trend: 'up',
            value: 112,
            percent: 12,
        },
    ]

    const options = [
        {
            label: 'for 1 week',
            value: 'for 1 week',
        },
        {
            label: 'for 1 month',
            value: 'for 1 month',
        },
        {
            label: 'for 1 year',
            value: 'for 1 year',
        },
    ]
    return (
        <main className="content">
            <div className="px-container">
                <div className="row gutters-28">
                    <div className="col-12">
                        <div className="row gutters-18">
                            {jobStatistics.map((item, index) => (
                                <div
                                    key={index}
                                    className="col-xl-3 col-md-6 col-12"
                                >
                                    <div className="card card-shadow card-seo">
                                        <div className="card-body">
                                            <div className="card-seo-info flex-shrink-0">
                                                <div className="seo-title">
                                                    <span className="ico">
                                                        <img
                                                            src={item.icon}
                                                            alt={item.label}
                                                        />
                                                    </span>
                                                    <span className="name">
                                                        {item.label}
                                                    </span>
                                                </div>
                                                <h1 className="seo-stat-title">
                                                    {item.value}
                                                </h1>
                                            </div>
                                            <div
                                                className="card-seo-chart"
                                                style={{
                                                    height: 60,
                                                    width: 100,
                                                }}
                                            >
                                                <TrendChart
                                                    trend={item.trend}
                                                />
                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            <div className="seo-rating-row">
                                                <div
                                                    className={clsx(
                                                        'seo-rating',
                                                        `--${item.trend}`,
                                                    )}
                                                >
                                                    <span className="ico">
                                                        {item.trend === 'up' ? (
                                                            <RatingUpIcon />
                                                        ) : (
                                                            <RatingDownIcon />
                                                        )}
                                                    </span>{' '}
                                                    <span>
                                                        {item.trend === 'up'
                                                            ? '+'
                                                            : '-'}
                                                        {item.percent}%
                                                    </span>
                                                </div>
                                                <p className="seo-rating-desk">
                                                    <span>vs</span> yesterday
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="row gutters-18">
                            <div className="col-lg-6">
                                <div className="card card-shadow card-chart h-full">
                                    <div className="card-header">
                                        <div className="card-chart-title text--xl">
                                            Job Trend
                                        </div>
                                        <SelectBox
                                            wrapperClassName="select--transparent"
                                            options={options}
                                        />
                                    </div>
                                    <div className="card-body">
                                        <div
                                            className="canvas-wrapper flex-auto"
                                            style={{ height: 215 }}
                                        >
                                            <LineChart
                                                values={Array.from({
                                                    length: 60,
                                                }).map(
                                                    (_) =>
                                                        Math.random() *
                                                        (6000 -
                                                            100 *
                                                                Math.random()),
                                                )}
                                                labels={Array.from({
                                                    length: 60,
                                                }).map((_, index) =>
                                                    new Date(
                                                        new Date().setDate(
                                                            new Date().getDay() +
                                                                index,
                                                        ),
                                                    ).toLocaleDateString(
                                                        'en-EU',
                                                        {
                                                            day: 'numeric',
                                                            month: 'short',
                                                        },
                                                    ),
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="card card-shadow card-chart h-full">
                                    <div className="card-header">
                                        <div className="card-chart-title text--xl">
                                            Job by Job Type
                                        </div>
                                        <SelectBox
                                            wrapperClassName="select--transparent"
                                            options={options}
                                        />
                                    </div>
                                    <div className="card-body">
                                        <DoughnutChart
                                            labels={[
                                                'OPC 25%',
                                                'LPC 35%',
                                                'AMI 35%',
                                                'SEAL 23%',
                                                'MDCT 18%',
                                            ]}
                                            values={[
                                                1200, 1500, 1400, 1300, 1700,
                                            ]}
                                            width={210}
                                            height={210}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="row gutters-18">
                            <div className="col-xl-4 col-md-6">
                                <div className="card card-shadow card-chart h-full">
                                    <div className="card-header">
                                        <div className="card-chart-title text--xl">
                                            Feedback
                                        </div>
                                        <SelectBox
                                            wrapperClassName="select--transparent"
                                            options={options}
                                        />
                                    </div>
                                    <div className="card-body">
                                        <DoughnutChart
                                            labels={[
                                                'Positive',
                                                'Neutral',
                                                'Negative',
                                            ]}
                                            values={[600, 350, 50]}
                                            units="percents"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6">
                                <div className="card card-shadow card-chart h-full">
                                    <div className="card-header">
                                        <div className="card-chart-title text--xl">
                                            Jobs by Zone
                                        </div>
                                        <SelectBox
                                            wrapperClassName="select--transparent"
                                            options={options}
                                        />
                                    </div>
                                    <div className="card-body">
                                        <HorizontalBarChart
                                            labels={Array.from({
                                                length: 4,
                                            }).map((_, index) =>
                                                new Date(
                                                    new Date().setDate(
                                                        new Date().getDay() +
                                                            index,
                                                    ),
                                                ).toLocaleDateString('en-EU', {
                                                    day: 'numeric',
                                                    month: 'short',
                                                }),
                                            )}
                                            values={[1000, 1200, 800, 300, 900]}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6">
                                <div className="card card-shadow card-chart h-full">
                                    <div className="card-header">
                                        <div className="card-chart-title text--xl">
                                            Total Transactions
                                        </div>
                                        <SelectBox
                                            wrapperClassName="select--transparent"
                                            options={options}
                                        />
                                    </div>
                                    <div className="card-body">
                                        <StackedBarChart
                                            labels={Array.from({
                                                length: 4,
                                            }).map((_, index) =>
                                                new Date(
                                                    new Date().setDate(
                                                        new Date().getDay() +
                                                            index,
                                                    ),
                                                ).toLocaleDateString('en-EU', {
                                                    day: 'numeric',
                                                    month: 'short',
                                                }),
                                            )}
                                            values={[
                                                [10, 20, 30, 40, 50, 60, 70],
                                                [15, 25, 35, 45, 55, 65, 75],
                                                [20, 30, 40, 50, 60, 70, 80],
                                            ]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default DashboardPage
