import Avatar from '../../../assets/img/user-change-photo-2.png'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { type InferType, object, string } from 'yup'
import Input from '../../../compoments/common/Input'
import React, { useEffect, useState } from 'react'
import { useGetMeQuery, useUpdateMeMutation } from '../../../services/auth'
import { toast } from 'react-toastify'
import { getMediaUrl } from '../../../utils/helpers'

const schema = object({
    fullName: string().required('Full name is required'),
    email: string().email('Invalid email format').required('Email is required'),
    phone: string().required('Phone is required'),
})

type FormType = InferType<typeof schema>

export const AccountSettings = () => {
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<FormType>({
        resolver: yupResolver(schema),
    })

    const [updateMe] = useUpdateMeMutation()

    const { data } = useGetMeQuery()
    const user = data?.data

    useEffect(() => {
        if (!user) return

        setValue('fullName', user.fullName)
        setValue('email', user.email)
        setValue('phone', user.phone)
    }, [data])

    const [photo, setPhoto] = useState<File | null>(null)
    const [photoTouch, setPhotoTouch] = useState(false)

    const onSubmit = async (data: FormType) => {
        const formData = new FormData()

        Object.entries(data).forEach(([key, value]) => {
            formData.append(key, value)
        })

        if (photoTouch && photo) {
            formData.append('photo', photo, photo.name)
        }

        try {
            const res = await updateMe(formData)
            console.log(res, 'res')

            if (res?.data?.success) {
                toast.success('Updated successfully!')
            } else {
                toast.error(res?.data?.message || 'Something went wrong')
            }
        } catch (error) {
            toast.error('Something went wrong')
            console.error('rejected', error)
        }
    }

    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let newPhoneValue = e.target.value

        if (newPhoneValue.startsWith('+')) {
            newPhoneValue = '+' + newPhoneValue.slice(1).replace(/\D/g, '')
        } else {
            newPhoneValue = newPhoneValue.replace(/\D/g, '')
        }

        setValue('phone', newPhoneValue)
    }

    return (
        <div className="settings-body">
            <div className="settings__header ">
                <h4 className="mb-22">Account Information</h4>
                <button
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                    className="btn btn--primary-gradient btn--lg fullRadius mb-22"
                >
                    <span className="text--md">Save Changes</span>
                </button>
            </div>

            <div className="row gy-42">
                <div className="col-lg-5">
                    <h5 className="mb-13">Profile Picture</h5>
                    <p className="text--xs color-text-color-1">
                        Edit your profile picture
                    </p>
                </div>
                <div className="col-lg-7">
                    <ChangePhotoPart
                        onChange={(val) => {
                            setPhoto(val as File)
                            setPhotoTouch(true)
                        }}
                        userPhoto={user?.photo ? getMediaUrl(user.photo) : null}
                    />
                </div>
                <div className="col-lg-5">
                    <h5 className="mb-13">General Info</h5>
                    <p className="text--xs color-text-color-1">
                        Edit information about your personal data
                    </p>
                </div>
                <div className="col-lg-7">
                    <div className="form-group--row input--lg mb-22">
                        <Input
                            label="Name"
                            className="input input--outline  w-full"
                            type="text"
                            placeholder=""
                            error={errors.fullName?.message}
                            {...register('fullName')}
                        />
                    </div>
                    <div className="form-group--row input--lg mb-22">
                        <label className="label">Role</label>
                        <input
                            disabled
                            className="input input--outline  w-full"
                            type="text"
                            placeholder=""
                            value="Executive"
                        />
                    </div>
                    <div className="form-group--row input--lg mb-22">
                        <Input
                            label="Email"
                            className="input input--outline  w-full"
                            type="text"
                            placeholder=""
                            error={errors.email?.message}
                            {...register('email')}
                        />
                    </div>
                    <div className="form-group--row input--lg">
                        <Input
                            label="Phone Number"
                            className="input input--outline  w-full"
                            type="text"
                            placeholder=""
                            error={errors.phone?.message}
                            {...register('phone')}
                            onChange={handlePhoneChange}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

interface FuncProps {
    onChange?: (file: File) => void
    userPhoto?: string | null
}

const ChangePhotoPart: React.FC<FuncProps> = ({ onChange, userPhoto }) => {
    const [file, setFile] = useState<File | null>(null)

    const changePhoto = (selectedFile: File) => {
        setFile(selectedFile)
        if (onChange) {
            onChange(selectedFile)
        }
    }

    useEffect(() => {
        if (userPhoto) {
            fetch(userPhoto)
                .then((res) => res.blob())
                .then((blob) => {
                    const initialFile = new File([blob], 'user_photo.jpg', {
                        type: blob.type,
                    })
                    setFile(initialFile)
                })
                .catch((error) =>
                    console.error('Error fetching the image:', error),
                )
        }
    }, [userPhoto])

    useEffect(() => {
        return () => {
            if (file) {
                URL.revokeObjectURL(URL.createObjectURL(file))
            }
        }
    }, [file])

    return (
        <div className="upload-photo">
            <div className="photo-load">
                {file ? (
                    <img src={URL.createObjectURL(file)} alt="" />
                ) : (
                    <img src={Avatar} alt="" />
                )}
            </div>
            <label className="upload-control">
                <input
                    type="file"
                    hidden
                    onChange={(e) => {
                        const selectedFile = e.target.files && e.target.files[0]
                        if (selectedFile) {
                            changePhoto(selectedFile)
                        }
                    }}
                />
                <div className="btn btn--secondary btn--sm radius">
                    <span>Change Photo</span>
                </div>
            </label>
        </div>
    )
}
