import type { ChartOptions } from 'chart.js'
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js'
import type { ChartProps } from 'react-chartjs-2'
import { Bar } from 'react-chartjs-2'

import { COLORS, FONT } from '../../utils/charts'

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
)

interface ChartOptionsProps extends Omit<ChartProps<'bar'>, 'data' | 'type'> {
    labels: string[]
    values: number[][]
    units?: string
}
const BarChart = ({ labels, values, ...rest }: ChartOptionsProps) => {
    const options: ChartOptions<'bar'> = {
        scales: {
            x: {
                grid: {
                    display: false,
                    drawOnChartArea: false,
                    drawTicks: false,
                },
                border: {
                    display: false,
                    color: 'A6A6A6',
                    dash: [2, 6],
                },
                ticks: {
                    padding: 11,
                    font: FONT,
                    color: '#A6A6A6',
                    callback: function () {
                        return new Date().toLocaleDateString('en-Eu', {
                            day: 'numeric',
                            month: 'short',
                        })
                    },
                },
            },
            y: {
                min: 0,
                max: 6000,
                grid: {
                    color: '#A6A6A6',
                    lineWidth: 1,
                    drawTicks: false,
                },
                border: {
                    display: false,
                    dash: [2, 6],
                },
                ticks: {
                    stepSize: 1000,
                    padding: 15,
                    font: FONT,
                    callback: (value: string | number) => {
                        return +value > 0 ? +value / 1000 + 'k' : '0'
                    },
                },
            },
        },
        plugins: {
            legend: {
                position: 'top',
                align: 'start',
                labels: {
                    usePointStyle: true,
                    boxWidth: 6,
                    boxHeight: 6,
                    font: FONT,
                    color: '#100B00',
                },
            },
        },
        elements: {
            point: {
                radius: 0,
            },
        },
        maintainAspectRatio: false,
    }
    const data = {
        labels,
        datasets: [
            {
                label: 'Installation',
                data: values[0],
                tension: 0,
                fill: true,
                borderColor: '#232ED1',
                borderRadius: 20,
                backgroundColor: COLORS[0],
            },
            {
                label: 'Repair',
                data: values[1],
                tension: 0,
                fill: true,
                borderColor: '#232ED1',
                borderRadius: 20,
                backgroundColor: COLORS[1],
            },
            {
                label: 'Maintenance',
                data: values[2],
                tension: 0,
                fill: true,
                borderColor: '#232ED1',
                borderRadius: 20,
                backgroundColor: COLORS[2],
            },
        ],
    }
    return <Bar options={options} data={data} {...rest} />
}

export default BarChart
