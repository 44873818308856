import type { ChartOptions } from 'chart.js'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js'
import React from 'react'
import type { ChartProps } from 'react-chartjs-2'
import { Line } from 'react-chartjs-2'

import {
    FONT,
    getGradient,
    inRange,
    RESPONSIVE_COEFFICIENT,
} from '../../utils/charts'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
)

interface LineChartProps extends Omit<ChartProps<'line'>, 'data' | 'type'> {
    labels: string[]
    values: number[]
    units?: string
}

const LineChart = ({ labels, values, ...rest }: LineChartProps) => {
    const options: ChartOptions<'line'> = {
        scales: {
            x: {
                grid: {
                    display: false,
                    drawOnChartArea: false,
                    drawTicks: false,
                },
                border: {
                    display: false,
                    color: 'A6A6A6',
                    dash: [2, 6],
                },
                ticks: {
                    padding: 11,
                    font: FONT,
                    color: '#A6A6A6',
                    stepSize: 10,
                    // callback: (value, index) => {
                    //     if (index % 10 === 0) {
                    //         return new Date().toLocaleDateString('en-EU', {
                    //             day: 'numeric',
                    //             month: 'short',
                    //         })
                    //     }
                    //     return ''
                    // },
                },
            },
            y: {
                min: 0,
                max: 6000,
                grid: {
                    color: '#A6A6A6',
                    lineWidth: 1,
                    drawTicks: false,
                },
                border: {
                    display: false,
                    dash: [2, 6],
                },
                ticks: {
                    stepSize: 1000,
                    padding: 15,
                    font: FONT,
                    callback: (value: string | number) => {
                        return +value > 0 ? +value / 1000 + 'k' : '0'
                    },
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
        responsive: true,
        elements: {
            point: {
                radius: 0,
            },
        },
        maintainAspectRatio: false,
    }

    const data = {
        labels,
        datasets: [
            {
                label: 'Klienci',
                data: values,
                tension: 0,
                fill: true,
                borderColor: '#232ED1',
                borderWidth: inRange(1, 2, RESPONSIVE_COEFFICIENT * 3),
                backgroundColor: (context: any): string | CanvasGradient => {
                    const chart = context.chart
                    const { ctx, chartArea } = chart

                    if (!chartArea) {
                        return 'transparent'
                    }
                    return getGradient(ctx, chartArea)
                },
            },
        ],
    }
    return <Line options={options} data={data} {...rest} />
}

export default LineChart
