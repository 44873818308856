import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import type { InferType } from 'yup'
import { object, ref, string } from 'yup'

import type { StepsProps } from '..'
import Button from '../../../compoments/common/Button'
import Input from '../../../compoments/common/Input'
import { useRestorePasswordMutation } from '../../../services/auth'

import FormHeader from './FormHeader'

const schema = object().shape({
    password: string().required('Password is required'),
    confirmPassword: string()
        .oneOf([ref('password'), ''], 'Passwords must match')
        .required('Password is required'),
})
type FormType = InferType<typeof schema>

const Step3 = ({ setStep, formData }: StepsProps) => {
    const navigate = useNavigate()
    const [restorePassword, { isLoading }] = useRestorePasswordMutation()
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormType>({
        resolver: yupResolver(schema),
    })

    const onSubmit = async (data: FormType) => {
        try {
            if (!formData.email || !formData.code) return
            const responce = await restorePassword({
                password: data.password,
                email: formData.email,
                code: formData.code,
            }).unwrap()
            if (responce.success) {
                toast.success('Password changed successfully!')
                navigate('/login')
            } else {
                toast.error('Something went wrong')
            }
        } catch (error) {
            console.error('rejected', error)
        }
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)} className="form">
            <FormHeader onBack={() => setStep(2)}>
                <h2 className="fs-28 text-left mb-22">Create new password</h2>
                <p className="text-description text-left text--md mb-42">
                    Enter yor email address that associated with your account
                </p>
            </FormHeader>
            <div className="form-body ">
                <div className="row mb-42">
                    <div className="col-12 mb-22">
                        <Input
                            label="New Password"
                            className="input input--outline  w-full"
                            type="password"
                            placeholder=""
                            togglePasswordShow
                            error={errors.password?.message}
                            {...register('password')}
                        />
                    </div>
                    <div className="col-12">
                        <Input
                            label="Confirm Password"
                            className="input input--outline  w-full"
                            type="password"
                            placeholder=""
                            togglePasswordShow
                            error={errors.confirmPassword?.message}
                            {...register('confirmPassword')}
                        />
                    </div>
                </div>
            </div>
            <div className="form-footer form-footer-col">
                <div className="items-12 flex flex-col">
                    <Button
                        loading={isLoading}
                        type="submit"
                        variant="primary-gradient"
                        className="w-full"
                    >
                        <span>Save Password</span>
                    </Button>
                </div>
            </div>
        </form>
    )
}

export default Step3
